import React, { useState, useEffect } from "react";
import api from "../../utils/api";

const InternalSearchbar = ({ type, resultHandler, onReset }) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .get(`/search?q=${query}&type=${type}`)
      .then((response) => {
        resultHandler(response, query);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="form relative" onSubmit={handleSearch}>
      <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
        <svg
          width="17"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-labelledby="search"
          className="w-5 h-5 text-gray-7000"
        >
          <path
            d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
            stroke="currentColor"
            strokeWidth="1.333"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>
      <input
        className="input w-[400px] rounded-full px-8 py-3 border focus:outline-none focus:border-seafoam placeholder-gray-400 transition-all duration-300"
        placeholder="Search..."
        required=""
        type="text"
        onChange={(e) => setQuery(e.target.value)}
      />
      <button type="reset" className="absolute right-3 -translate-y-1/2 top-1/2 p-1" onClick={onReset}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </form>
  );
};

export default InternalSearchbar;
