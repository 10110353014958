import ChevronFilled from "../../assets/ChevronFilled.jsx";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../app/slices/globalUISlice.js";
import clsx from "clsx";

const Votes = ({ userVote, handleUpvote, handleDownvote, upvotes, isVertical = false, className, variant }) => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const upvote = (event) => {
    event.stopPropagation();
    if (!currentUser) {
      dispatch(openModal("loginPrompt"));
    } else {
      handleUpvote(event);
    }
  };

  const downvote = (event) => {
    event.stopPropagation();
    if (!currentUser) {
      dispatch(openModal("loginPrompt"));
    } else {
      handleDownvote(event);
    }
  };

  if (variant === "large") {
    return (
      <div
        className={clsx(
          "flex font-space text-base font-bold items-center gap-[6px]",
          { "flex-col": isVertical },
          className
        )}
      >
        <button className="rotate-180" onClick={upvote}>
          <ChevronFilled
            width="20"
            height="18"
            className={clsx("hover:fill-mainGreen", userVote === 1 ? "fill-mainGreen" : "fill-mainBlue")}
          />
        </button>
        {upvotes || 0}
        <button onClick={downvote}>
          <ChevronFilled
            width="20"
            height="18"
            className={clsx("hover:fill-red", userVote === -1 ? "fill-red" : "fill-mainBlue")}
          />
        </button>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "flex font-space text-sm font-bold items-center gap-[3px]",
        { "flex-col": isVertical },
        className
      )}
    >
      <button className="rotate-180" onClick={upvote}>
        <ChevronFilled className={clsx("hover:fill-mainGreen", userVote === 1 ? "fill-mainGreen" : "fill-mainBlue")} />
      </button>
      {upvotes || 0}
      <button onClick={downvote}>
        <ChevronFilled className={clsx("hover:fill-red", userVote === -1 ? "fill-red" : "fill-mainBlue")} />
      </button>
    </div>
  );
};

export default Votes;
