import React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../app/slices/globalUISlice";
import UserCard from "../cards/UserCard";
import Button from "../helpers/Button";

const UserResults = ({ searchQuery, searchResults }) => {
  const dispatch = useDispatch();

  const handleInviteUser = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`Hey book bestie, come join me on Pagebound\n ${window.location.origin}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  if (searchResults.length === 0) {
    return (
      <div className="bg-white rounded-xl p-6">
        <b>Hmmm, we didn't find anyone with the username "{searchQuery}"</b>
        <p className="mt-2">
          Not finding who you're looking for?{" "}
          <Button type="text" onClick={handleInviteUser} text="Invite" color="text-red" /> a friend to Pagebound!
        </p>
      </div>
    );
  }

  return (
    <>
      {searchResults.map((user) => (
        <UserCard key={user.id} user={user} />
      ))}
      <div className="flex flex-row items-center justify-between gap-6 bg-white rounded-xl shadow-main p-6 bg-opacity-80 text-left">
        <div>
          <b>Not finding who you're looking for?</b>
          <p className="mt-2">Invite a friend to Pagebound!</p>
        </div>
        <Button onClick={handleInviteUser} text="Invite" color="bg-red" />
      </div>
    </>
  );
};

export default UserResults;
