import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "../helpers/Button";

const AddBookCard = ({ onClick, variant }) => {
  if (variant === "vertical") {
    return (
      <button
        onClick={onClick}
        className="flex flex-col flex-none h-fit w-fit box-border py-[31.5px] px-[30px] md:px-[40px] md:py-[42.5px] justify-between bg-mainBlue items-center justify-center shadow-main rounded-default border border-offBlack"
      >
        <div className="flex justify-center items-center bg-white w-14 h-24 md:w-[100px] md:h-[165px] border border-offBlack cursor-pointer">
          <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
        </div>
      </button>
    );
  }

  return (
    <div className="flex md:flex-col w-full max-w-[330px] md:w-48 md:h-[290px] box-border gap-4 md:gap-0 p-4 md:px-4 md:py-6 md:justify-between bg-mainBlue items-center shadow-main rounded-default border border-offBlack">
      <div
        onClick={onClick}
        className="flex justify-center items-center bg-white w-14 h-24 md:w-24 md:h-40 border border-offBlack cursor-pointer"
      >
        <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
      </div>
      <Button onClick={onClick} text="add book" color="bg-neonBlue" />
    </div>
  );
};

export default AddBookCard;
