import React, { useEffect, useState, useRef } from "react";
import UserCard from "../cards/UserCard";
import api from "../../utils/api";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

const DiscoverPeople = () => {
  const [topContributors, setTopContributors] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [newUsersLoading, setNewUsersLoading] = useState(false);
  const [moreNew, setMoreNew] = useState(true);
  const newUsersRef = useRef(1);

  useEffect(() => {
    api.get(`/users?q=top`).then((data) => setTopContributors(data));
    api.get(`/users?q=new`).then((data) => {
      setNewUsers(data);
      if (data.length < 10) setMoreNew(false);
    });

    return () => {
      newUsersRef.current = 1;
    };
  }, []);

  const handleLoadMoreNew = () => {
    setNewUsersLoading(true);
    newUsersRef.current += 1;
    api
      .get(`/users?q=new&page=${newUsersRef.current}`)
      .then((data) => {
        setNewUsers((prev) => [...prev, ...data]);
        if (data.length < 10) setMoreNew(false);
      })
      .catch(() => {
        console.error("Error loading more new users");
      })
      .finally(() => {
        setNewUsersLoading(false);
      });
  };

  return (
    <div className="container flex flex-col max-w-[900px] mx-auto m-auto gap-10 items-start mb-[100px]">
      <div className="w-full text-left flex flex-col gap-6">
        <h2>Top Contributors</h2>
        <div className="flex flex-col gap-3">
          {topContributors.map((user) => (
            <UserCard key={user.id} user={user} variant="showcase" />
          ))}
        </div>
      </div>

      <div className="w-full text-left flex flex-col gap-6">
        <h2>New & Friendly</h2>
        <div className="flex flex-col gap-3">
          {newUsers.map((user) => (
            <UserCard key={user.id} user={user} variant="showcase" />
          ))}
          {newUsers.length > 0 && moreNew && (
            <button
              className="bg-transparent p-2 self-center text-sm"
              disabled={newUsersLoading}
              onClick={handleLoadMoreNew}
            >
              {newUsersLoading ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscoverPeople;
