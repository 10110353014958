import React from "react";
import TrendingBooks from "../book/TrendingBooks";
import PopularBooks from "../book/PopularBooks";
import PopularLists from "../lists/PopularLists";
import NewLists from "../lists/NewLists";

const DiscoverBooks = () => {
  return (
    <div className="container flex flex-col w-full max-w-[900px] mx-auto m-auto gap-12 md:gap-16 items-start mb-[100px]">
      <TrendingBooks />
      <PopularLists />
      <NewLists />
      <PopularBooks />
    </div>
  );
};

export default DiscoverBooks;
