import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import BookResults from "./BookResults";
import UserResults from "./UserResults";
import ClubResults from "./ClubResults";
import QuestResults from "./QuestResults";
import ListResults from "./ListResults";
import smallSquiggle from "../../assets/smallSquiggle.svg";
import GreenSquiggle from "../../assets/GreenSquiggle";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import { useDocumentTitle } from "../../utils/hooks";

const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("query");
  const searchType = searchParams.get("type") || "Book";
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  useDocumentTitle("Search Results");

  useEffect(() => {
    // Book searching is handled by Algolia in BookResults.jsx
    if (searchType === "Book") return;
    setIsLoading(true);
    api
      .get(`/search?q=${searchQuery}&type=${searchType}`)
      .then((data) => {
        setSearchResults(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchParams]);

  const handleSearch = (type) => {
    searchParams.set("type", type);
    setSearchParams(searchParams);
  };

  const ctas = [
    {
      text: "Books",
      onClick: () => handleSearch("Book"),
      isActive: searchType === "Book",
    },
    {
      text: "Users",
      onClick: () => handleSearch("User"),
      isActive: searchType === "User",
    },
    {
      text: "Lists",
      onClick: () => handleSearch("List"),
      isActive: searchType === "List",
    },
    {
      text: "Clubs",
      onClick: () => handleSearch("Club"),
      isActive: searchType === "Club",
    },
    {
      text: "Quests",
      onClick: () => handleSearch("Quest"),
      isActive: searchType === "Quest",
    },
  ];

  const renderResults = () => {
    if (isLoading) return <Loader />;
    const props = { searchQuery, searchResults };
    switch (searchType) {
      case "Book":
        return <BookResults {...props} />;
      case "User":
        return <UserResults {...props} />;
      case "Club":
        return <ClubResults {...props} />;
      case "Quest":
        return <QuestResults {...props} />;
      case "List":
        return <ListResults {...props} />;
    }
  };

  return (
    <div className="container px-3 mx-auto m-auto pt-8 md:pt-[4em] mb-[100px]">
      <header className="flex flex-col w-[80%] m-auto gap-5 md:gap-8 mb-8 md:mb-16 items-center">
        <h1>Results for "{searchQuery}"</h1>

        <div className="hidden md:flex flex-row gap-5 m-auto">
          {ctas?.map((cta) => (
            <button
              key={cta.text}
              className={clsx(
                cta.isActive && "bg-mainGreen",
                "shadow-main font-bold font-space text-sm lg:text-[1.25em] rounded-[50px] lg:px-8 px-4 lg:py-2 py-1 cursor-pointer border border-offBlack"
              )}
              onClick={cta.onClick}
              disabled={cta.disabled}
            >
              {cta.text}
            </button>
          ))}
        </div>

        <div className="hidden md:block">
          <GreenSquiggle width="340" height="37" />
        </div>

        <div className="flex md:hidden flex-row justify-between w-full max-w-[350px]">
          {ctas?.map((cta) => (
            <button
              key={cta.text}
              className="relative bg-transparent w-[50px] items-center font-bold font-space text-sm cursor-pointer flex flex-col gap-1"
              onClick={cta.onClick}
              disabled={isLoading}
            >
              {cta.text}
              {cta.isActive && <img src={smallSquiggle} alt="squiggle" className="absolute -bottom-3 " />}
            </button>
          ))}
        </div>
      </header>

      <div className="container flex flex-col bg-mainBlue rounded-default shadow-main border border-offBlack p-8 max-w-[900px] gap-5 m-auto">
        {renderResults()}
      </div>
    </div>
  );
};

export default SearchResults;
