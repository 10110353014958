import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unscheduleUserBook, scheduleUserBook } from "../../../app/slices/userBookSlice";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Button from "../../helpers/Button";
import LibraryBookCard from "../library/LibraryBookCard";
import LibraryAddBookCard from "../library/LibraryAddBookCard";
import { openModal } from "../../../app/slices/globalUISlice";
import IconButton from "../../helpers/IconButton";

const YearPlan = ({ year }) => {
  const dispatch = useDispatch();
  const { scheduledBooks } = useSelector((state) => state.userBook);
  const [yearBooks, setYearBooks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setYearBooks(scheduledBooks.filter((book) => book.scheduled_for_year == year));
  }, [scheduledBooks, year]);

  const scheduleBook = (book) => {
    dispatch(
      scheduleUserBook({
        userBookData: { book_id: book.id, scheduled_for_year: year },
      })
    );
  };

  const removeBookButton = (userBook) => {
    const removeBook = (userBook) => {
      dispatch(
        unscheduleUserBook({
          userBook: userBook,
          userBookData: { scheduled_for_year: null, scheduled_for_month: null },
        })
      );
    };

    return (
      <button onClick={() => removeBook(userBook)}>
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const onClickAddBookCard = () => {
    const addedBookIds = yearBooks.map((userBook) => userBook.book_id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick: scheduleBook } }));
  };

  return (
    <div key={year} className="flex flex-col flex-wrap w-full gap-4 box-border items-start">
      <div className="flex items-center w-full gap-6">
        <h2 className="font-bold">{`${year}`}</h2>
        {!isEditing && (
          <IconButton
            IconComponent={EditIcon}
            onClick={() => setIsEditing(true)}
            tooltip="Edit"
          />
        )}
        {isEditing && <Button text="Save" color="bg-fuschia" onClick={() => setIsEditing(false)} />}
      </div>

      <div className="flex w-full flex-wrap items-center gap-5" key={year}>
        {yearBooks.map((userBook) => (
          <LibraryBookCard
            userBook={userBook}
            key={userBook.uuid}
            icon={isEditing ? removeBookButton(userBook) : <></>}
          />
        ))}
        <LibraryAddBookCard onClick={onClickAddBookCard} key={`add-book-${year}`} />
      </div>
    </div>
  );
};

export default YearPlan;
