import React from "react";
import { useDocumentTitle } from "../utils/hooks";

const NotFound = () => {
  useDocumentTitle("Not found");

  return (
    <div className="container m-auto py-8">
      <h1>Page not found!</h1>
    </div>
  );
};

export default NotFound;
