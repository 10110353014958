import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUnreadNotifications } from "../../app/slices/userSlice";
import { openModal } from "../../app/slices/globalUISlice";
import api from "../../utils/api";
import FeedCard from "../cards/FeedCard";
import BookCard from "../cards/BookCard";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Loader from "../helpers/Loader";
import styles from "../../styles/App.module.scss";
import Button from "../helpers/Button";

const AuthenticatedHome = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [currentBooks, setCurrentBooks] = useState([]);
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreFeedItems, setMoreFeedItems] = useState(true);
  const page = useRef(1);

  useEffect(() => {
    setLoading(true);
    setError();
    api
      .get("/home_feed")
      .then((res) => {
        setCurrentBooks(res.current_reads);
        setFeedItems(res.feed_items);
      })
      .catch(() => {
        setError("Whoops, something went wrong. Try refreshing the page.");
      })
      .finally(() => {
        setLoading(false);
      });
    dispatch(fetchUnreadNotifications());

    return () => {
      page.current = 1;
    };
  }, []);

  const handleLoadMore = () => {
    setLoadingMore(true);
    page.current += 1;
    api
      .get(`/home_feed?page=${page.current}`)
      .then((res) => {
        if (res.feed_items?.length == 0) {
          setMoreFeedItems(false);
        }
        setFeedItems((prev) => [...prev, ...res.feed_items]);
      })
      .finally(() => {
        setLoadingMore(false);
      });
  };

  const setCurrentlyReading = (book) => {
    api.post("/user_books?return_book=true", { book_id: book.id, status: "current" }).then((data) => {
      setCurrentBooks((prevBooks) => [data, ...prevBooks]);
    });
  };

  return (
    <div className="min-h-[100vh] h-max m-auto pt-8 md:bg-cover bg-no-repeat bg-home-squiggle pb-[50px] md:pb-[100px]">
      {loading ? (
        <Loader variant="fullPage" />
      ) : (
        <div className="container m-auto px-4 md:px-0 md:w-9/12 flex flex-col items-center">
          <header className="flex flex-col mb-12 mt-2 md:my-16 gap-8 w-full md:w-9/12">
            <h2>Welcome back, {currentUser.username}</h2>
            <div
              className={`flex self-center w-fit max-w-full gap-3 md:gap-4 pb-2 px-2 overflow-x-scroll ${styles.customScrollbar}`}
            >
              {currentBooks?.length > 0 ? (
                currentBooks.map((book) => <BookCard key={book.id} book={book} variant="homeFeed" />)
              ) : (
                <div className="w-full bg-current flex flex-col gap-4 justify-center items-center rounded-default p-6 border border-offBlack shadow-main">
                  <p className="font-bold">What are you currently reading? 📖</p>
                  <Button
                    text="Search"
                    onClick={() =>
                      dispatch(openModal({ name: "searchReturnBook", data: { handleClick: setCurrentlyReading } }))
                    }
                    color="bg-neonBlue"
                  />
                </div>
              )}
            </div>
          </header>
          <div className="flex flex-col gap-4 md:gap-8 w-full items-center">
            {feedItems.map((item, index) => (
              <FeedCard key={index} activityItem={item} />
            ))}
            {moreFeedItems && !loading && (
              <button
                className="bg-transparent p-2 self-center text-sm"
                disabled={loadingMore}
                onClick={handleLoadMore}
              >
                {loadingMore ? (
                  "Loading..."
                ) : (
                  <>
                    View More <ExpandMoreOutlinedIcon fontSize="small" />
                  </>
                )}
              </button>
            )}
            {!moreFeedItems && !loading && (
              <p className="bg-transparent p-2 self-center text-sm">You're all caught up!</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthenticatedHome;
