import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useSessionStorage } from "@uidotdev/usehooks";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import api from "../../../utils/api";
import LibraryBookCard from "../library/LibraryBookCard";
import Loader from "../../helpers/Loader";
import IconButton from "../../helpers/IconButton";
import { BOOK_STATUS_LABEL_MAP } from "../../../constants";

const OtherUserLibrary = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [showListView, setShowListView] = useSessionStorage("pagebound_listView", false);
  const [showShelfList, setShowShelfList] = useState(true);
  const [currentShelf, setCurrentShelf] = useState({});
  // NOTE: the user_books stored in this state variable represent a combination of the other user's library data
  // (eg review, dates), but the status, shelves, and IDs of the current user's user_book (if any) to allow the
  // current user to add/update the book in their own library
  const [userBooks, setUserBooks] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [sortBy, setSortBy] = useState({ attribute: "title", isAscending: true });
  const pageRef = useRef(1);
  const shelves = user.shelves.filter((s) => !s.private);
  const shelfUuid = useParams().uuid;
  const status = useParams().status || "finished";

  const combineUserBookData = (data, callback) => {
    api.get(`/users/current/user_books?book_ids=${data.user_books.map((ub) => ub.book_id)}`)
      .then((currUserData) => {
        const currUserBooks = currUserData.user_books.reduce((acc, value) => ({ ...acc, [value.book_id]: value }), {});
        const combinedBookData = data.user_books.map((ub) => {
          const match = currUserBooks[ub.book_id];
          // Keep other user's user_book data, but combine with library attributes of current user
          return {
            ...ub,
            id: match?.id,
            uuid: match?.uuid,
            status: match?.status,
            shelves: match?.shelves || [],
          }
        });
        callback(combinedBookData);
      })
    .catch((err) => setError(err.message))
    .finally(() => setIsLoading(false));
  };

  const fetchNewData = () => {
    pageRef.current = 1;
    const action = shelfUuid
      ? `/shelves/${shelfUuid}?page=1${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`
      : `/users/${user.id}/user_books?status=${status}&page=1${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`;

    api.get(action)
      .then((data) => {
        setCurrentShelf(data.shelf || { name: status });
        setMoreData(data.total_pages > 1);
        combineUserBookData(data, (combinedData) => setUserBooks(combinedData));
      })
      .catch((err) => setError(err.message))
  };

  useEffect(() => {
    setIsLoading(true);
    fetchNewData();
  }, [shelfUuid, status]);

  useEffect(() => {
    fetchNewData(); // Don't want to show loading state when just changing sorting
  }, [sortBy]);

  const handleMoreData = async () => {
    pageRef.current += 1;
    const action = shelfUuid
      ? `/shelves/${shelfUuid}?page=${pageRef.current}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`
      : `/users/${user.id}/user_books?status=${status}&page=${pageRef.current}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`;

    api.get(action).then((data) => {
      if (data.total_pages === pageRef.current) setMoreData(false);
      combineUserBookData(data, (combinedData) => setUserBooks((prevBooks) => [...prevBooks, ...combinedData]));
    });
  };

  const handleShelfClick = (shelf) => {
    setShowShelfList(false);
    navigate(`/users/${user.username}/library/tags/${shelf.uuid}`);
  };

  const handleStatusClick = (status) => {
    setShowShelfList(false);
    navigate(`/users/${user.username}/library/${status}`);
  };

  const renderShelfList = () => {
    return (
      <ul className="w-[250px] flex flex-col gap-1.5 justify-center md:justify-start text-left">
        {Object.keys(BOOK_STATUS_LABEL_MAP).map((status) => (
          <li key={status}>
            <button
              onClick={() => handleStatusClick(status)}
              className={clsx(
                "font-space font-bold truncate",
                currentShelf.name == status ? "text-offBlack" : "hover:text-pink text-gray"
              )}
            >
              {BOOK_STATUS_LABEL_MAP[status]}
            </button>
          </li>
        ))}
        <hr className="text-gray my-2" />
        {shelves.map((shelf) => (
          <li key={shelf.name}>
            <button
              onClick={() => handleShelfClick(shelf)}
              className={clsx(
                "font-space font-bold truncate",
                currentShelf.name == shelf.name ? "text-offBlack" : "hover:text-pink text-gray"
              )}
            >
              {shelf.name}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderShelfBooks = () => {
    if (isLoading) return <Loader />;
    if (error) return <div>Oops - something went wrong! Please try again. Error: {error}</div>;
    if (userBooks.length === 0) return <div className="mt-6">No books yet</div>;

    const updateSorting = (col) => {
      if (sortBy.attribute === col) {
        setSortBy((prev) => ({ ...prev, isAscending: !prev.isAscending }));
      } else {
        setSortBy({ attribute: col, isAscending: true });
      };
    };
  
    const sortIcon = sortBy.isAscending
      ? <span className="relative"><ArrowDropUpRoundedIcon classes={{ root: "absolute -top-[7px] -left-[9px]" }} fontSize="large" color="primary" /></span>
      : <span className="relative"><ArrowDropDownRoundedIcon classes={{ root: "absolute -top-[7px] -left-[9px]" }} fontSize="large" color="primary" /></span>;

    return (
      showListView ? (
        <Table>
          <TableHead classes={{ root: "whitespace-nowrap" }}>
            <TableRow>
              <TableCell classes={{ root: "w-[90px]" }}>Cover</TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("title")}>
                Title {sortBy.attribute === "title" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("author")}>
                Author {sortBy.attribute === "author" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("rating")}>
                Their Rating {sortBy.attribute === "rating" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "min-w-[24px]" }}>{/* status icon button */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userBooks.map((ub) => (
              <LibraryBookCard userBook={ub} key={ub.book_id} isOtherUser view="row" />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="w-full flex flex-col md:grid md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 mt-4 items-center justify-center">
          {userBooks.map((ub) => (
            <LibraryBookCard userBook={ub} key={ub.book_id} isOtherUser />
          ))}
        </div>
      )
    );
  };

  if (!currentShelf) return <div className="mt-8">Nothing here yet!</div>;

  return (
    <div className="container max-w-[1280px] mx-auto mt-8 md:mt-16 flex lg:gap-12 justify-center px-3">
      {/* desktop */}
      <div className="hidden lg:inline-block mt-12">{renderShelfList()}</div>
      <div className="hidden lg:flex w-full lg:w-fit justify-center lg:justify-start">
        <div>
          <div className="hidden lg:flex flex-row justify-between mb-6">
            <div>{/* TODO: search bar */}</div>
            <div className="flex flex-row gap-2">
              <IconButton
                IconComponent={GridViewRoundedIcon}
                onClick={() => setShowListView(false)}
                isActive={!showListView}
                tooltip="Showcase view"
              />
              <IconButton
                IconComponent={FormatListBulletedRoundedIcon}
                onClick={() => setShowListView(true)}
                isActive={showListView}
                tooltip="List view"
              />
            </div>
          </div>
          <div className="w-full lg:w-[652px] xl:w-[856px] min-h-[350px] flex flex-col flex-wrap gap-2 bg-gradient-to-b from-pastelPink to-pastelPurple p-6 md:p-8 rounded-default text-left">
            {!isLoading && (
              <>
                <div className="flex flex-row justify-between">
                  <div className="flex gap-4">
                    <h2>{currentShelf.name}</h2>
                  </div>
                </div>
                {currentShelf.description && <div className="mt-3">{currentShelf.description}</div>}
              </>
            )}
            <div className="flex flex-col">
              {renderShelfBooks()}
              {userBooks?.length > 0 && moreData && (
                <button onClick={handleMoreData} className="mt-4 self-center">
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="flex lg:hidden w-full justify-center lg:justify-start">
        {showShelfList ? (
          <div className="min-w-[300px] py-6 rounded-default border border-offBlack shadow-main bg-lightPurple flex m-auto justify-center items-center">
            {renderShelfList()}
          </div>
        ) : (
          <div className="w-full min-h-[350px] flex flex-col flex-wrap gap-2 bg-gradient-to-b from-pastelPink to-pastelPurple p-6 md:p-8 rounded-default text-left">
            {!isLoading && (
              <>
                <div className="flex flex-row justify-between">
                  <div className="flex gap-4">
                    <button className="flex items-center gap-1" onClick={() => setShowShelfList(true)}>
                      <h2>{currentShelf.name}</h2>
                      <ExpandMoreOutlinedIcon fontSize="small" />
                    </button>
                  </div>
                </div>
                {currentShelf.description && <div className="mt-3">{currentShelf.description}</div>}
              </>
            )}
            <div className="flex flex-col">
              {renderShelfBooks()}
              {userBooks?.length > 0 && moreData && (
                <button onClick={handleMoreData} className="mt-4 self-center">
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherUserLibrary;
