import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { markNotificationsAsRead } from "../../app/slices/userSlice";
import api from "../../utils/api";
import NotificationCard from "./NotificationCard";
import Loader from "../helpers/Loader";
import { useDocumentTitle } from "../../utils/hooks";

const NotificationsHome = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreItems, setMoreItems] = useState(false);
  const [error, setError] = useState(null);
  const page = useRef(1);
  useDocumentTitle("Notifications");

  useEffect(() => {
    setLoading(true);
    api
      .get(`/users/notifications?page=${page.current}`)
      .then((data) => {
        setNotifications(data);
        setMoreItems(data.length == 30);
      })
      .catch(() => setError("Oops! Something went wrong. Try refreshing."))
      .finally(() => setLoading(false));

    return () => {
      dispatch(markNotificationsAsRead());
      page.current = 1;
    };
  }, []);

  const handleLoadMore = () => {
    setLoadingMore(true);
    page.current += 1;
    api
      .get(`/users/notifications?page=${page.current}`)
      .then((data) => {
        if (data.length == 0) {
          setMoreItems(false);
        }
        setNotifications((prev) => [...prev, ...data]);
      })
      .catch(() => setError("Oops! Something went wrong. Try refreshing."))
      .finally(() => {
        setLoadingMore(false);
      });
  };

  if (loading) return <Loader variant="fullPage" />;

  return (
    <div className="container m-auto flex flex-col items-center px-2 md:px-0 pb-[50px]">
      <h1 className="mt-8 mb-4 md:mb-8">Notifications</h1>
      <div className="flex flex-col gap-2 md:gap-4 items-center w-full md:w-[700px]">
        {!loading && notifications.length == 0 && <p>No notifications yet!</p>}
        {notifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
        {error && <p>{error}</p>}
        {moreItems && !loadingMore && (
          <button
            className="bg-transparent p-2 self-center mt-2 text-sm"
            disabled={loadingMore}
            onClick={handleLoadMore}
          >
            {loadingMore ? (
              "Loading..."
            ) : (
              <>
                View More <ExpandMoreOutlinedIcon fontSize="small" />
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationsHome;
