import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Snackbar } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import AppRoutes from "./components/AppRoutes";
import MODAL_CONFIG from "./components/modals";
import Modal from "./components/modals/Modal";
import { fetchCurrentUser, fetchUnreadNotifications } from "./app/slices/userSlice";
import { closeSnackbar } from "./app/slices/globalUISlice";
import styles from "./styles/index.module.scss";
import Loader from "./components/helpers/Loader";
import "./styles/App.module.scss";

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { modalName, modalData, snackbarData } = useSelector((state) => state.globalUI);

  useEffect(() => {
    Promise.all([dispatch(fetchCurrentUser()).unwrap(), dispatch(fetchUnreadNotifications()).unwrap()])
      .then((data) => typeof heap !== "undefined" && heap.identify(data[0].uuid))
      .catch(() => {}) // quiet console logs for unauthed user
      .finally(() => setIsLoading(false));
  }, []);

  const renderModal = () => {
    if (!modalName) return null;
    const Component = MODAL_CONFIG[modalName] || Modal;
    return <Component name={modalName} {...modalData} />;
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Hanken Grotesk",
    },
    palette: {
      primary: {
        main: '#748BF6',
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            borderBottom: "0px",
            padding: "6px",
          },
          head: {
            fontWeight: "bold",
          },
        },
      },
    },
  });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        {renderModal()}
        <NavBar loading={isLoading} />
        {isLoading ? (
          <Loader variant="fullPage" />
        ) : (
          <div className={styles.root}>
            <AppRoutes />
          </div>
        )}
        <Footer />
        <Snackbar
          open={!!snackbarData}
          key={snackbarData?.message}
          onClose={() => dispatch(closeSnackbar())}
          autoHideDuration={3000}
          ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
          {...snackbarData}
          ContentProps={{
            style: {
              backgroundColor: snackbarData?.error ? "red" : undefined,
            },
          }}
        />
      </ThemeProvider>
    </Router>
  );
};

export default App;
