import React, { useEffect, useState, useRef } from "react";
import api from "../../utils/api";
import ListCard from "../cards/ListCard";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Loader from "../helpers/Loader";

const NewLists = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreLists, setMoreLists] = useState(true);
  const [error, setError] = useState();
  const [newLists, setNewLists] = useState([]);
  const page = useRef(1);

  useEffect(() => {
    setIsLoading(true);
    api.get("/lists?type=new&page=1")
      .then((data) => {
        setNewLists(data);
        if (data.length < 5) setMoreLists(false);
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));

    return () => {
      page.current = 1;
    };
  }, []);

  const fetchMoreLists = () => {
    setLoadingMore(true);
    page.current += 1;
    api
      .get(`/lists?type=new&page=${page.current}`)
      .then((data) => {
        setNewLists((prev) => [...prev, ...data]);
        if (data.length < 5) setMoreLists(false);
      })
      .catch()
      .finally(() => setLoadingMore(false));
  };

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Oops - something went wrong! Try refreshing the page.</div>;
  }
  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex gap-4 items-center">
        <h2 className="text-left">New Lists</h2>
      </div>

      {newLists.map((list) => (
        <ListCard key={list.id} listData={list} />
      ))}
      {moreLists && (
        <button className="bg-transparent p-2 self-center text-sm" disabled={loadingMore} onClick={fetchMoreLists}>
          {loadingMore ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default NewLists;
