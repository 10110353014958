import React from "react";
import styles from "../../styles/App.module.scss";

const Loader = ({ variant }) => {
  if (variant == "fullPage") {
    return (
      <div className="min-h-[100vh] h-max m-auto pt-8 flex items-center justify-center pb-[50px] md:pb-[100px]">
        <div className="relative flex w-full justify-center items-center">
          <div className={styles.loadRow}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex w-full justify-center items-center">
      <div className={styles.loadRow}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loader;
