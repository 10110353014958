const OnboardingProgressBar = ({ step }) => {
  return (
    <div className="mb-[50px] flex justify-center items-center h-fit">
      <div className="w-[250px] md:w-[480px] h-1 border-b border-dashed flex justify-between items-center">
        {step >= 1 ? (
          <div className="rounded-full w-[35px] h-[35px] flex justify-center items-center border shadow-main bg-mainGreen font-space">
            1
          </div>
        ) : (
          <div className="rounded-full w-[35px] h-[35px] bg-lightGray" />
        )}
        {step >= 2 ? (
          <div className="rounded-full w-[35px] h-[35px] flex justify-center items-center border shadow-main bg-mainGreen font-space">
            2
          </div>
        ) : (
          <div className="rounded-full w-[35px] h-[35px] bg-lightGray" />
        )}
        {step >= 3 ? (
          <div className="rounded-full w-[35px] h-[35px] flex justify-center items-center border shadow-main bg-mainGreen font-space">
            3
          </div>
        ) : (
          <div className="rounded-full w-[35px] h-[35px] bg-lightGray" />
        )}
      </div>
    </div>
  );
};

export default OnboardingProgressBar;
