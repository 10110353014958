import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Collapsible from "react-collapsible";
import EmojiPicker from "emoji-picker-react";
import { Formik, Field, FieldArray, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import Layout from "../layout/Layout";
import { CLUB_THEMES } from "../../constants";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "../modals/Modal";
import { createClub } from "../../app/slices/clubSlice";
import { closeModal, openModal } from "../../app/slices/globalUISlice";
import Button from "../helpers/Button";
import BookCover from "../helpers/BookCover";
import Switch from "../helpers/Switch";
import HeadTags from "../helpers/HeadTags";

const CreateClub = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [addedBooks, setAddedBooks] = useState([]);

  useEffect(() => {
    if (addedBooks.length === 10) {
      dispatch(closeModal());
    }
  }, [addedBooks]);

  const clubSchema = Yup.object().shape({
    name: Yup.string().required("Required").max(50, "Too Long"),
    description: Yup.string().required("Required").max(500, "Too Long"),
    private: Yup.boolean(),
    taste_description: Yup.string().max(500, "Too Long"),
    member_nickname: Yup.string().max(15, "Too Long"),
    color: Yup.string().required("Required"),
    emojis: Yup.array().required("Emojis are required").min(3, "Choose 3 emojis").max(3, "Maximum 3 emojis"),
    books: Yup.array().required("Add at least 1 book").max(10).min(1, "At least one book is required"),
  });

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    dispatch(createClub(values))
      .unwrap()
      .then((data) => {
        setSubmitting(false);
        navigate(`/clubs/${data.uuid}`);
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus({ error: error });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const PrivateSwitch = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
      <div className="flex flex-col gap-3 w-full items-start">
        <div className="flex flex-col gap-1 w-full items-start text-left">
          <label className="mr-4 font-bold" htmlFor="private">
            Do you want to make your Club private?
          </label>
          <p className="text-xs">If yes, you'll need to approve members who request to join.</p>
        </div>
        <div className="flex flex-row">
          <Switch id="private" isChecked={value} onChange={() => setValue(!value)} />
        </div>
      </div>
    );
  };

  const BookPicker = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    useEffect(() => {
      setValue(addedBooks.map((b) => b.id));
    }, [addedBooks]);

    const onClickAddBookCard = () => {
      const handleClick = (book) => {
        if (addedBooks.length < 10) {
          setAddedBooks((prev) => [...prev, book]);
        }
      };
      const addedBookIds = addedBooks.map((b) => b.id);
      dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick } }));
    };

    return (
      <div className="flex flex-col gap-3 w-full items-start">
        <div className="flex flex-col gap-1 w-full items-start text-left">
          <p className="font-bold">
            Select up to 10 books to showcase your Club's <q>Our Taste:</q>
          </p>
          <p className="text-xs">
            <q>Our Taste</q> helps people understand at a glance what your Club likes to read. You can add unlimited
            additional book recommendations later.
          </p>
        </div>
        <div className="flex flex-wrap w-full gap-4 items-start">
          {value.length > 0 &&
            addedBooks.map((book, index) => (
              <div className="relative w-16 h-24 md:w-[105px] md:h-40" key={index}>
                <button
                  className="absolute -top-[8px] -right-[7px]"
                  onClick={() => {
                    setAddedBooks((prev) => prev.filter((b) => b.id !== book.id));
                  }}
                  style={{
                    backgroundImage: `url(/assets/delete.svg)`,
                    backgroundSize: "cover",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <RemoveCircleIcon
                    fontSize="small"
                    className="text-[16px] md:text-[20px]"
                    sx={{
                      cursor: "pointer",
                      color: "red",
                    }}
                  />
                </button>
                <BookCover
                  book={book}
                  size="w-16 h-24 md:w-[105px] md:h-40 border shadow-main"
                  clickable={false}
                  showTitle={true}
                />
              </div>
            ))}
          {value.length < 10 && (
            <button
              onClick={onClickAddBookCard}
              className="flex justify-center items-center bg-white w-16 h-24 md:w-[105px] md:h-40 border border-offBlack shadow-main"
            >
              <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
            </button>
          )}
        </div>
      </div>
    );
  };

  const ThemePicker = (props) => {
    const [field, meta, helpers] = useField(props.name);

    const { value } = meta;
    const { setValue } = helpers;
    const isSelected = (v) => (v === value ? "selected" : "");

    const chevron =
      "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute after:top-2 after:left-[55px]";
    return (
      <Collapsible
        trigger={
          <div
            className={`w-[45px] h-[45px] rounded-boxy border border-offBlack ${CLUB_THEMES[value] || "bg-white"}`}
          />
        }
        triggerClassName={`${chevron} after:rotate-90`}
        triggerOpenedClassName={`${chevron} after:-rotate-90`}
        transitionTime={200}
        className="flex flex-col w-full items-start"
        openedClassName="flex flex-col w-full items-start"
        contentOuterClassName="w-full"
        contentInnerClassName="flex flex-wrap w-full gap-6 md:gap-10 items-start mt-4"
      >
        <React.Fragment>
          <button
            onClick={() => setValue("theme1")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme1} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme2")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme2} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme3")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme3} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme4")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme4} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme5")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme5} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme6")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme6} border border-offBlack rounded-boxy`}
          />
        </React.Fragment>
      </Collapsible>
    );
  };

  return (
    <Layout
      title="Create Club"
      description="Congrats on creating a Club! You'll be the moderator, which means you're the only one who can edit the Club and add book recs."
    >
      <HeadTags title="Create Club" description="Discuss books with likeminded readers and find your community." />
      <Formik
        validationSchema={clubSchema}
        onSubmit={handleSubmit}
        initialValues={{
          color: "",
          name: "",
          description: "",
          taste_description: "",
          member_nickname: "",
          emojis: [],
          books: [],
          private: false,
        }}
      >
        {({ errors, values, isSubmitting, isValid, status }) => (
          <Form
            onKeyDown={handleKeyDown}
            className="w-9/12 max-w-[900px] m-auto flex flex-col items-start p-8 gap-6 bg-seafoam border border-offBlack rounded-default shadow-main"
          >
            <div className="flex flex-col gap-3 w-full items-start">
              <div className="flex flex-col gap-1 w-full items-start text-left">
                <label htmlFor="name" className="font-bold">
                  Club name:
                </label>
                <p className="text-xs">
                  You will not be able to change this after Club creation, but you'll be able to edit everything else.
                </p>
              </div>
              <Field
                id="name"
                name="name"
                placeholder="max 50 characters"
                className="w-full ml-0 mr-5 px-4 py-3 my-auto"
              />
              <ErrorMessage name="name" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="flex flex-col gap-3 w-full items-start">
              <label htmlFor="description" className="font-bold">
                Club description:
              </label>
              <Field
                id="description"
                name="description"
                placeholder="max 500 characters"
                as="textarea"
                rows={3}
                className="w-full ml-0 mr-5 px-4 py-3 my-auto"
              />
              <ErrorMessage name="description" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <PrivateSwitch name="private" />
            <div className="flex flex-col gap-3 w-full items-start">
              <div className="flex flex-col gap-1 w-full items-start text-left">
                <label htmlFor="member_nickname" className="font-bold">
                  What will members be called?
                </label>
                <p className="text-xs">e.g., wizards, adventurers, fans</p>
              </div>
              <Field
                id="member_nickname"
                name="member_nickname"
                placeholder="Default: members"
                className="ml-0 mr-5 px-4 py-3 my-auto"
              />
              <ErrorMessage name="member_nickname" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="flex flex-col gap-3 w-full items-start text-left">
              <p className="font-bold">Choose a color theme:</p>
              <ThemePicker name="color" />
              <ErrorMessage name="color" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="flex flex-col gap-3 w-full items-start text-left">
              <p className="font-bold">Select 3 emojis to describe your Club:</p>
              <FieldArray
                name="emojis"
                render={(arrayHelpers) => (
                  <div className="flex gap-4 items-start">
                    {values.emojis?.length > 0 &&
                      values.emojis.map((emoji, index) => (
                        <div key={index} className="flex flex-col">
                          <div className="text-[25px]">{emoji}</div>
                          <button type="button" onClick={() => arrayHelpers.remove(index)}>
                            <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
                          </button>
                        </div>
                      ))}
                    {values.emojis?.length < 3 && (
                      <button type="button" className="pt-2" onClick={() => setShowEmojiPicker(true)}>
                        <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
                      </button>
                    )}

                    <Modal isOpen={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}>
                      <EmojiPicker
                        skinTonesDisabled
                        lazyLoadEmojis
                        previewConfig={{ showPreview: false }}
                        onEmojiClick={(emojiData, event) => {
                          if (values.emojis.length < 3) arrayHelpers.push(emojiData.emoji);
                          setShowEmojiPicker(false);
                        }}
                      />
                    </Modal>
                  </div>
                )}
              />
              <ErrorMessage name="emojis" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <BookPicker name="books" />
            <ErrorMessage name="books" render={(msg) => <div className="text-red">{msg}</div>} />
            <div className="flex flex-col gap-3 w-full items-start">
              <div className="flex flex-col gap-1 w-full items-start text-left">
                <label htmlFor="taste_description" className="font-bold">
                  <q>Our Taste</q> description:
                </label>
                <p className="text-xs">
                  (optional) Why did you pick these books? This text will appear next to the books you selected above.
                </p>
              </div>
              <Field
                id="taste_description"
                name="taste_description"
                placeholder="max 500 characters"
                as="textarea"
                rows={3}
                className="w-full ml-0 mr-5 px-4 py-3 my-auto"
              />
              <ErrorMessage name="taste_description" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="w-full flex justify-end items-center">
              {status && status.error && <div className="text-red mr-4">{status.error}</div>}
              <Button disabled={!isValid || isSubmitting} color="bg-neonBlue" text="Publish" />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default CreateClub;
