import React, { useEffect, useState, useRef } from "react";
import api from "../../utils/api";
import ClubCard from "../cards/ClubCard";
import OnboardingClubCard from "../onboarding/OnboardingClubCard";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Loader from "../helpers/Loader";

const PopularClubs = ({ isOnboarding }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreClubs, setMoreClubs] = useState(true);
  const [error, setError] = useState();
  const [popularClubs, setPopularClubs] = useState([]);
  const page = useRef(1);
  const Component = isOnboarding ? OnboardingClubCard : ClubCard;

  useEffect(() => {
    setIsLoading(true);
    const path = isOnboarding
      ? "/clubs/index_by_type?type=popular&page=1&isOnboarding=true"
      : "/clubs/index_by_type?type=popular&page=1";
    api
      .get(path)
      .then((data) => {
        setPopularClubs(data);
        if (data.length < 5) setMoreClubs(false);
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));

    return () => {
      page.current = 1;
    };
  }, []);

  const fetchMoreClubs = () => {
    setLoadingMore(true);
    page.current += 1;
    api
      .get(`/clubs/index_by_type?type=popular&page=${page.current}`)
      .then((data) => {
        setPopularClubs([...popularClubs, ...data]);
        if (data.length < 5) setMoreClubs(false);
      })
      .catch()
      .finally(() => setLoadingMore(false));
  };

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Oops - something went wrong! Try refreshing the page.</div>;
  }
  return (
    <div className="flex flex-col gap-6 w-full">
      {popularClubs.map((club) => (
        <Component key={club.id} club={club} />
      ))}
      {moreClubs && (
        <button className="bg-transparent p-2 self-center text-sm" disabled={loadingMore} onClick={fetchMoreClubs}>
          {loadingMore ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default PopularClubs;
