import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchPost } from "../../app/slices/forumSlice";
import FullPost from "./showPost/FullPost";
import Button from "../helpers/Button";
import Loader from "../helpers/Loader";
import HeadTags from "../helpers/HeadTags";

const PostPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchPost(id))
      .unwrap()
      .then((data) => {
        if (data.error) {
          console.error("Error fetching post:", data.error);
        } else {
          setPost(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <Loader variant="fullPage" />;
  }

  if (!loading && !post) {
    return (
      <div className="min-h-[100vh] h-max m-auto pt-8 flex flex-col gap-4 items-center justify-center pb-[50px] md:pb-[100px]">
        <h2>Hmm, we couldn't find that...</h2>
        <Button type="link" to="/" text="⬅ Back to home" color="bg-fuschia" />
      </div>
    );
  }

  const forumLink =
    post.forum.resource_type === "Book"
      ? `/books/${post.forum.resource_uuid}#forum-reviews`
      : `/clubs/${post.forum.resource_uuid}?view=forum`;

  return (
    <div className="container max-w-[856px] m-auto text-left px-2 py-8 md:py-16">
      <HeadTags title={`Post in the ${post?.forum?.name || ""} forum`} description={post?.content} />
      <div className="hidden md:block">
        <Button
          type="link"
          to={forumLink}
          text={`⬅ Back to ${post.forum.name ? post.forum.name : ""} forum`}
          color="bg-neonBlue"
        />
      </div>
      <div className="md:hidden">
        <Button
          type="link"
          to={forumLink}
          text={`⬅ Back to ${post.forum.resource_type === "Book" ? "book" : "club"} forum`}
          color="bg-neonBlue"
        />
      </div>
      <div className="bg-white shadow-main rounded-default mt-4 md:mt-8 p-4 md:p-6 border border-offBlack">
        <FullPost postData={post} />
      </div>
    </div>
  );
};

export default PostPage;
