import React, { useEffect, useState, useRef } from "react";
import ClubCard from "../cards/ClubCard";
import api from "../../utils/api";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Loader from "../helpers/Loader";

const NewClubs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreClubs, setMoreClubs] = useState(true);
  const [error, setError] = useState();
  const [newClubs, setNewClubs] = useState([]);
  const page = useRef(1);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/clubs/index_by_type?type=newest`)
      .then((data) => {
        setNewClubs(data);
        if (data.length < 5) setMoreClubs(false);
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));

    return () => {
      page.current = 1;
    };
  }, []);

  const fetchMoreClubs = () => {
    setLoadingMore(true);
    page.current += 1;
    api
      .get(`/clubs/index_by_type?type=newest&page=${page.current}`)
      .then((data) => {
        setNewClubs([...newClubs, ...data]);
        if (data.length < 5) setMoreClubs(false);
      })
      .catch()
      .finally(() => setLoadingMore(false));
  };

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Oops - something went wrong! Please try again. Error: {error}</div>;
  }
  return (
    <div className="flex flex-col gap-6">
      {newClubs.map((club) => (
        <ClubCard key={club.id} club={club} />
      ))}
      {moreClubs && (
        <button className="bg-transparent p-2 self-center text-sm" disabled={loadingMore} onClick={fetchMoreClubs}>
          {loadingMore ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default NewClubs;
