import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { fetchNestedComments, updateComment } from "../../../app/slices/forumSlice";
import { timeAgo } from "../../../utils/utils";
import CommentActions from "./CommentActions";
import PlusMinusIcon from "./PlusMinusIcon";
import Button from "../../helpers/Button";
import styles from "../../../styles/App.module.scss";
import UserProvidedContent from "../../helpers/UserProvidedContent";
import { setModalDirty } from "../../../app/slices/globalUISlice";

const Comment = ({ nestedLevel = 0, commentData, last = false }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState(commentData);
  const [isCommentsOpen, setIsCommentsOpen] = useState(comment?.child_comments?.length > 0);
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(comment.content);
  const pageRef = useRef(0);

  const hasReplies = comment.child_comments_count > 0 || comment.child_comments?.length > 0;
  const hasMoreReplies = comment.child_comments_count > comment.child_comments?.length;

  const handleLoadMore = async () => {
    pageRef.current += 1;
    await dispatch(fetchNestedComments({ commentId: comment.id, page: pageRef.current }))
      .unwrap()
      .then((data) => {
        setComment((prevComment) => ({
          ...data.parent_comment,
          child_comments: [
            ...prevComment.child_comments,
            ...data.comments.filter(
              (newComment) =>
                !prevComment.child_comments.some((existingComment) => existingComment.id === newComment.id)
            ),
          ],
        }));
        setIsCommentsOpen(true);
      });
  };

  const onChangeComment = (event) => {
    dispatch(setModalDirty(true));
    setNewContent(event.target.value);
  };

  const handleSubmitEdit = () => {
    const commentData = { is_edited: true, content: newContent };
    dispatch(updateComment({ commentId: comment.id, commentData }))
      .unwrap()
      .then((data) => {
        setComment(data);
        setIsEditing(false);
        dispatch(setModalDirty(false));
      });
  };

  if (!comment) {
    return;
  }

  const renderMoreRepliesButton = () => {
    // if !hasReplies (open or closed), hide
    // if open, show remaining to fetch + fetch
    // if closed, show child_comments_count. if child_comments.length === 0 (ie no comments fetched yet), fetch

    const repliesToFetch = comment.child_comments_count - (comment.child_comments?.length || 0);

    if (!hasReplies || (isCommentsOpen && repliesToFetch === 0)) {
      return <></>;
    }

    const handleShowMore = () => {
      if (isCommentsOpen || comment.child_comments?.length === 0) {
        handleLoadMore();
      } else {
        setIsCommentsOpen(true);
      }
    };

    return (
      <button
        id={`load-more-replies-${nestedLevel}`}
        onClick={handleShowMore}
        className="relative z-10 flex items-center gap-2 ml-[4px] pt-2 h-fit text-xs leading-none text-gray"
      >
        <PlusMinusIcon
          className={clsx("w-5 h-5 bg-white transition-transform", isCommentsOpen ? "transform rotate-180" : "")}
          isPlus={true}
        />
        <div className="hover:underline">
          {isCommentsOpen ? repliesToFetch : comment.child_comments_count} more replies
        </div>
      </button>
    );
  };

  return (
    <div className={`relative flex flex-col text-left bg-white w-full ${nestedLevel === 0 ? "mt-6" : "mt-2"} h-fit`}>
      {last && <div className="absolute top-[5px] -left-[30px] w-[30px] h-full bg-white z-10" />}

      <details open className={`relative ${styles.noDefaultArrow}`}>
        <summary className={`${styles.noDefaultArrow} cursor-pointer`}>
          <div className="flex text-gray text-xs items-center gap-2">
            <div className="relative rounded-full h-[30px] w-[30px] bg-gray z-10 flex-none">
              {nestedLevel > 0 && (
                <div
                  id={`comment-curve-${nestedLevel}`}
                  className="absolute -left-4 top-1 w-5 h-4 border-l border-b border-gray z-20"
                  style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "0px" }}
                />
              )}
              {comment.user_image_url && (
                <img
                  src={comment.user_image_url}
                  className="w-full h-full rounded-full border border-offBlack absolute z-30"
                />
              )}
            </div>
            <div>
              <Link to={`/users/${comment.username}`}>{comment.username}</Link>
            </div>
            <>•</>
            <div>{timeAgo(comment.created_at)}</div>
            {comment.is_edited && <>•</>}
            {comment.is_edited && <div>Edited</div>}
          </div>
        </summary>

        <div className="relative w-full">
          <div className="pl-[38px] flex w-full flex-col gap-2">
            {isEditing ? (
              <textarea
                value={newContent}
                onChange={onChangeComment}
                rows={3}
                className="w-full overflow-x-scroll ml-0 text-left text-[14px] md:text-[16px] leading-tight py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
              />
            ) : (
              <UserProvidedContent resource={comment} content={comment.content} type="comment" />
            )}
            {isEditing ? (
              <Button onClick={handleSubmitEdit} text="Update" color="bg-neonBlue text-offBlack" />
            ) : (
              <div className="relative">
                <CommentActions
                  comment={comment}
                  setComment={setComment}
                  onEdit={() => setIsEditing(true)}
                  onReply={() => setIsCommentsOpen(true)}
                />
              </div>
            )}
          </div>
          {isCommentsOpen && (
            <button onClick={() => setIsCommentsOpen(false)} className="absolute z-10 bottom-0 left-[4px]">
              <PlusMinusIcon className="w-5 h-5 bg-white transition-transform" isPlus={false} />
            </button>
          )}
        </div>

        {isCommentsOpen && hasReplies && (
          <div id="nested-comments" className="flex flex-col pl-[30px]">
            {comment.child_comments?.map((childComment, index) => (
              <Comment
                marginLeft={20}
                nestedLevel={nestedLevel + 1}
                last={index === comment.child_comments?.length - 1 && !hasMoreReplies}
                commentData={childComment}
                key={childComment.uuid}
              />
            ))}
          </div>
        )}

        {renderMoreRepliesButton()}

        {hasReplies && (
          <div
            onClick={() => setIsCommentsOpen((prevState) => !prevState)}
            className={`absolute top-0 left-2.5 bottom-0 w-2 ${
              isCommentsOpen && !hasMoreReplies ? "h-[calc(100%-26px)]" : "h-full"
            } flex flex-col justify-center items-center z-0 cursor-pointer group mb-sm`}
          >
            <div className={`w-[1px] h-full group-hover:bg-black bg-gray`}></div>
          </div>
        )}
      </details>
    </div>
  );
};

export default Comment;
