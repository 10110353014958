import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

const Button = ({ type, onClick, isActive, text, disabled, color, to, size = "small" }) => {
  if (type === "text") {
    return (
      <button className={clsx("hover:text-pink", color)} onClick={onClick} disabled={disabled}>
        {text}
      </button>
    );
  }

  if (type === "navigation") {
    return (
      <button
        className={clsx(
          isActive ? "bg-mainGreen" : "bg-beige",
          "shadow-main font-bold font-space text-sm md:text-lg rounded-full px-4 py-1.5 md:px-8 md:py-2 cursor-pointer border border-offBlack"
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }

  const large = "text-lg md:px-8 md:py-2";
  const medium = "text-sm md:px-6 md:py-2";
  const small = "text-xs md:px-5";

  if (type === "link") {
    return (
      <Link
        className={clsx(
          color,
          size === "large" ? large : size === "small" ? small : medium,
          "w-fit flex items-center shadow-main flex-none leading-none font-bold text-center font-space rounded-full px-4 py-1.5 cursor-pointer border border-offBlack hover:text-black"
        )}
        to={to}
        disabled={disabled}
        target={to.startsWith("http") ? "_blank" : ""}
      >
        {text}
      </Link>
    );
  }

  return (
    <button
      className={clsx(
        color,
        size === "large" ? large : size === "small" ? small : medium,
        "w-fit flex-none shadow-main whitespace-nowrap leading-none font-bold font-space px-4 py-1.5 rounded-full cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray disabled:cursor-auto"
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
