import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBook } from "../../app/slices/bookSlice";
import ClubCard from "../cards/ClubCard";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import api from "../../utils/api";
import Button from "../helpers/Button";
import RecommendationCard from "../cards/RecommendationCard";
import Loader from "../helpers/Loader";
import { openModal, openSnackbar } from "../../app/slices/globalUISlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QuestCard from "../quests/QuestCard";
import ListCard from "../cards/ListCard";
import Layout from "../layout/Layout";
import HeadTags from "../helpers/HeadTags";

const AllRecommendationsPage = ({ view }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { currentBook } = useSelector((state) => state.book);
  const { currentUser } = useSelector((state) => state.user);
  const [recommendations, setRecommendations] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [quests, setQuests] = useState([]);
  const [lists, setLists] = useState([]);
  const [moreBookData, setMoreBookData] = useState(false);
  const [moreClubData, setMoreClubData] = useState(false);
  const [moreQuestData, setMoreQuestData] = useState(false);
  const [moreListData, setMoreListData] = useState(false);
  const [loading, setLoading] = useState(false);
  const bookPageRef = useRef(1);
  const clubPageRef = useRef(1);
  const questPageRef = useRef(1);
  const listPageRef = useRef(1);

  useEffect(() => {
    setLoading(true);
    if (!currentBook) dispatch(fetchBook(uuid));

    if (view === "recommendations" && recommendations.length === 0) {
      api
        .get(`/books/${uuid}/recommendations?page=1`)
        .then((data) => {
          setRecommendations(data.recommendations);
          setMoreBookData(data.total_pages > 1);
        })
        .finally(() => setLoading(false));
    }

    if (view === "clubs" && clubs.length === 0) {
      api
        .get(`/books/${uuid}/clubs?page=1`)
        .then((data) => {
          setClubs(data.clubs);
          setMoreClubData(data.total_pages > 1);
        })
        .finally(() => setLoading(false));
    }

    if (view === "quests" && quests.length === 0) {
      api
        .get(`/books/${uuid}/quests?page=1`)
        .then((data) => {
          setQuests(data.quests);
          setMoreQuestData(data.total_pages > 1);
        })
        .finally(() => setLoading(false));
    }

    if (view === "lists" && lists.length === 0) {
      api
        .get(`/books/${uuid}/lists?page=1`)
        .then((data) => {
          setLists(data.lists);
          setMoreListData(data.total_pages > 1);
        })
        .finally(() => setLoading(false));
    }

    return () => {
      bookPageRef.current = 1;
      clubPageRef.current = 1;
      questPageRef.current = 1;
      listPageRef.current = 1;
    };
  }, [uuid, view]);

  const handleMoreBookData = async () => {
    setLoading(true);
    bookPageRef.current += 1;
    api
      .get(`/books/${uuid}/recommendations?page=${bookPageRef.current}`)
      .then((data) => {
        setRecommendations((prevRecs) => [...prevRecs, ...data.recommendations]);
        if (data.total_pages === bookPageRef.current) setMoreBookData(false);
      })
      .finally(() => setLoading(false));
  };

  const handleMoreClubData = async () => {
    setLoading(true);
    clubPageRef.current += 1;
    api
      .get(`/books/${uuid}/clubs?page=${clubPageRef.current}`)
      .then((data) => {
        setClubs((prevClubs) => [...prevClubs, ...data.clubs]);
        if (data.total_pages === clubPageRef.current) setMoreClubData(false);
      })
      .finally(() => setLoading(false));
  };

  const handleMoreQuestData = async () => {
    setLoading(true);
    questPageRef.current += 1;
    api
      .get(`/books/${uuid}/quests?page=${questPageRef.current}`)
      .then((data) => {
        setQuests((prevQuests) => [...prevQuests, ...data.quests]);
        if (data.total_pages === questPageRef.current) setMoreQuestData(false);
      })
      .finally(() => setLoading(false));
  };

  const handleMoreListData = async () => {
    setLoading(true);
    listPageRef.current += 1;
    api
      .get(`/books/${uuid}/lists?page=${listPageRef.current}`)
      .then((data) => {
        setLists((prevLists) => [...prevLists, ...data.lists]);
        if (data.total_pages === listPageRef.current) setMoreListData(false);
      })
      .finally(() => setLoading(false));
  };

  const addRecommendationCard = () => {
    const onClick = () => {
      if (!currentUser) {
        dispatch(openModal("loginPrompt"));
        return;
      }

      const addRecommendation = (recommended_book) => {
        const params = { book_id: currentBook.id, recommended_book_id: recommended_book.id };
        api
          .post(`/recommendations`, { recommendation: params })
          .then((data) => setRecommendations([...recommendations, data]))
          .catch((error) => {
            console.error("Error creating recommendation:", error?.message);
            if (error.response.status === 422) {
              dispatch(openSnackbar({ message: "Recommendation already added!", error: error }));
            }
          });
      };
      const addedBookIds = recommendations.map((r) => r.recommended_book.id);
      dispatch(openModal({ name: "searchReturnBook", data: { handleClick: addRecommendation, addedBookIds } }));
    };

    return (
      <div className="flex" key="add-book-card-container">
        <button
          onClick={onClick}
          className="flex flex-col flex-none h-fit w-fit box-border py-[31.5px] px-[45px] md:px-[40px] md:py-[42.5px] justify-between bg-mainBlue items-center justify-center shadow-main rounded-default border border-offBlack"
        >
          <div className="flex justify-center items-center bg-white w-14 h-24 md:w-[100px] md:h-[165px] rounded-boxy border border-offBlack cursor-pointer">
            <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
          </div>
        </button>
      </div>
    );
  };

  const renderBooks = () => {
    if (loading) return <Loader />;

    if (recommendations.length < 1) {
      return (
        <div className="w-full">
          <div className="flex flex-col gap-2 md:gap-3 mb-4 text-left pr-5 bg-pastelOrange rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <b>No book recs yet ☹️</b>
            <p>Know what other fans of this book would like? Add a rec below.</p>
          </div>
          {addRecommendationCard()}
        </div>
      );
    }

    return (
      <div className="flex md:flex-col max-w-[908px] bg-gradient-to-b from-pastelOrange to-pastelGreen rounded-default p-4 md:p-8 w-fit m-auto gap-2">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 w-fit m-auto">
          {addRecommendationCard()}
          {recommendations.map((recommendation) => (
            <RecommendationCard key={recommendation.id} recommendation={recommendation} />
          ))}
        </div>
        {moreBookData && (
          <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreBookData}>
            {loading ? (
              "Loading..."
            ) : (
              <>
                View More <ExpandMoreOutlinedIcon fontSize="small" />
              </>
            )}
          </button>
        )}
      </div>
    );
  };

  const renderClubs = () => {
    if (clubs.length < 1) {
      return (
        <div className="w-fit md:w-full max-w-[908px] bg-pastelOrange flex flex-col md:flex-row justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
          {loading && <Loader />}
          {!loading && (
            <>
              <div className="flex flex-col gap-2 md:gap-3 text-left pr-5">
                <b>No Clubs yet ☹️</b>
                <p>Are you a fan? Be the first to create a Club featuring this book.</p>
              </div>
              <Button type="link" to="/clubs/new" color="bg-fuschia self-end md:self-auto" text="Create club" />
            </>
          )}
        </div>
      );
    }
    return (
      <>
        <div className="flex flex-col max-w-[908px] bg-gradient-to-b from-pastelOrange to-pastelGreen rounded-default p-4 md:p-8 w-fit gap-5 m-auto">
          {clubs.map((club) => (
            <ClubCard key={club.uuid} club={club} />
          ))}
          {moreClubData && (
            <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreClubData}>
              {loading ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>
      </>
    );
  };

  const renderQuests = () => {
    if (quests.length < 1) {
      return (
        <div className="w-full max-w-[908px] bg-pastelOrange flex flex-col md:flex-row justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
          {loading && <div className="w-full ">Loading...</div>}
          {!loading && (
            <div className="flex flex-col w-full max-w-[908px] gap-2 md:gap-3 text-left pr-5">
              <b>No Quests yet!</b>
            </div>
          )}
        </div>
      );
    }
    return (
      <>
        <div className="flex flex-col bg-gradient-to-b from-pastelOrange to-pastelGreen rounded-default p-4 md:p-8 w-fit gap-5 m-auto">
          {quests.map((quest) => (
            <QuestCard key={quest.uuid} quest={quest} />
          ))}
          {moreQuestData && (
            <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreQuestData}>
              {loading ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>
      </>
    );
  };

  const renderLists = () => {
    if (lists.length < 1) {
      return (
        <div className="w-fit md:w-full max-w-[908px] bg-pastelOrange flex flex-col md:flex-row justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
          {loading && <div className="w-full ">Loading...</div>}
          {!loading && (
            <>
              <div className="flex flex-col gap-2 md:gap-3 text-left pr-5">
                <b>No Lists yet ☹️</b>
                <p>Are you ready to be a tastemaker? Create a list featuring this book.</p>
              </div>
              <Button type="link" to="/lists/new" color="bg-fuschia self-end md:self-auto" text="Create list" />
            </>
          )}
        </div>
      );
    }
    return (
      <>
        <div className="flex flex-col max-w-[908px] bg-gradient-to-b from-pastelOrange to-pastelGreen rounded-default p-4 md:p-8 w-fit gap-5 m-auto">
          {lists.map((list) => (
            <ListCard key={list.uuid} listData={list} />
          ))}
          {moreListData && (
            <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreListData}>
              {loading ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>
      </>
    );
  };

  const ctas = [
    {
      text: "Books",
      onClick: () => navigate(`/books/${uuid}/recommendations`),
      isActive: view === "recommendations",
    },
    {
      text: "Clubs",
      onClick: () => navigate(`/books/${uuid}/clubs`),
      isActive: view === "clubs",
    },
    {
      text: "Quests",
      onClick: () => navigate(`/books/${uuid}/quests`),
      isActive: view === "quests",
    },
    {
      text: "Lists",
      onClick: () => navigate(`/books/${uuid}/lists`),
      isActive: view === "lists",
    },
  ];

  return (
    <Layout title={`Recs if you like ${currentBook?.title}...`} ctas={ctas}>
      <HeadTags title={currentBook?.title} description={currentBook?.description} image={currentBook?.image_url} />
      <div className="container max-w-[908px] flex flex-col items-center gap-8 mx-auto m-auto px-3 md:px-0 mb-[100px]">
        <div className="flex flex-col md:flex-row gap-6 w-full">
          <Button type="link" to={`/books/${uuid}`} text="⬅ Back to book" color="bg-neonBlue self-start" />
          {/* <h2 className="max-w-[756px] line-clamp-2 md:line-clamp-1">
            {view === "recommendations" ? "Book" : view === "clubs" ? "Club" : view === "quests" ? "Quest" : "List"}
            {view === "recommendations" ? " recs" : view === "clubs" ? "s" : view === "quests" ? "s" : "s"} if you like{" "}
            {currentBook?.title}
          </h2> */}
        </div>
        {view === "recommendations" && renderBooks()}
        {view === "clubs" && renderClubs()}
        {view === "quests" && renderQuests()}
        {view === "lists" && renderLists()}
      </div>
    </Layout>
  );
};

export default AllRecommendationsPage;
