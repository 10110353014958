import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import createFilter from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import bookReducer from "./slices/bookSlice";
import userBookReducer from "./slices/userBookSlice";
import forumReducer from "./slices/forumSlice";
import userReducer from "./slices/userSlice";
import shelfReducer from "./slices/shelfSlice";
import clubReducer from "./slices/clubSlice";
import globalUIReducer from "./slices/globalUISlice";
import onboardingReducer from "./slices/onboardingSlice";

const appReducer = combineReducers({
  user: userReducer,
  book: bookReducer,
  userBook: userBookReducer,
  forum: forumReducer,
  shelf: shelfReducer,
  club: clubReducer,
  globalUI: globalUIReducer,
  onboarding: onboardingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};

const userSubsetFilter = createFilter("user", ["currentUserId", "token"]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "onboarding"],
  transforms: [userSubsetFilter],
  version: 1,
  migrate: (state) => Promise.resolve(state),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER",
          "globalUI/openModal",
          "globalUI/openSnackbar",
        ],
        ignoredPaths: ["globalUI.modalData", "globalUI.snackbarData"],
      },
    }),
});

export const persistor = persistStore(store);
