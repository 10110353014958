import { Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";

const IconButton = ({ IconComponent, onClick, isActive, tooltip, children }) => {
  const icon = (
    <div
      onClick={onClick}
      className={clsx(
        "flex justify-center items-center w-[30px] h-[30px] shadow-main rounded-full border border-offBlack cursor-pointer",
        isActive ? "bg-mainGreen" : "bg-transparent"
      )}
    >
      {IconComponent && <IconComponent fontSize="small" />}
      {children}
    </div>
  )

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {icon}
      </Tooltip>
    );
  } else {
    return icon;
  };
};

export default IconButton;
