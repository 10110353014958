import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/slices/globalUISlice";
import SaveIcon from "../../assets/SaveIcon";
import BookCover from "../helpers/BookCover";
import api from "../../utils/api";

const QuestCard = ({ quest, variant }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [isSaved, setIsSaved] = useState(quest?.is_bookmarked || false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCardClick = () => {
    navigate(`/quests/${quest.uuid}`);
  };

  const handleIconClick = async (event) => {
    event.stopPropagation();
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: "Challenge", id: quest.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setIsSaved(!isSaved);
      });
    }
  };

  const joinButton = () => {
    if (quest.joined && quest.user_challenge?.status === "in_progress") {
      return (
        <div className="bg-yellow rounded-default border-2 border-orange px-4 py-1">
          <p className="leading-tight text-[11px] md:text-[13px]">
            {quest.user_challenge.completed_books_count} of {quest.goal} read
          </p>
        </div>
      );
    }

    if (quest.joined && quest.user_challenge?.status === "finished") {
      return (
        <div className="bg-mainGreen rounded-default border border-offBlack px-4 py-1">
          <p className="leading-tight text-[11px] md:text-[13px]">Finished</p>
        </div>
      );
    }

    return (
      <div className="bg-beige rounded-default border border-offBlack px-4 py-1">
        <p className="leading-tight text-[11px] md:text-[13px]">Not Joined</p>
      </div>
    );
  };

  if (variant === "small") {
    return (
      <div
        onClick={handleCardClick}
        className="relative w-[190px] h-[320px] flex flex-col bg-white border border-offBlack shadow-main rounded-default p-6 gap-3 cursor-pointer items-center"
      >
        <button
          className="absolute top-4 right-3 p-1 rounded-full hover:bg-yellow"
          onClick={handleIconClick}
          disabled={isLoading}
        >
          <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
        </button>

        <div className="grid grid-cols-2 gap-2 w-fit">
          {quest.book_images.map((image, index) => (
            <BookCover book={{ image_url: image }} size="w-[54px] h-[80px]" clickable={false} key={index} />
          ))}
        </div>
        <div className="flex flex-col flex-grow text-center w-full justify-between">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-xs md:text-sm leading-none md:leading-none text-ellipsis line-clamp-2">
              {quest.name}
            </p>
            <p className="leading-none text-xs md:text-sm">{quest.goal} books</p>
          </div>

          {joinButton()}
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex w-full max-w-[900px] pr-6 md:pr-6 p-4 md:p-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between">
      <button
        onClick={handleIconClick}
        className="absolute top-1 md:top-4 right-2 md:right-4 p-1 rounded-full hover:bg-yellow"
        disabled={isLoading}
      >
        <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
      </button>
      <div className="flex gap-4 md:gap-6 w-full cursor-pointer" onClick={handleCardClick}>
        <div className="grid grid-cols-2 gap-2 w-fit flex-none">
          {quest.book_images.map((image, index) => (
            <BookCover book={{ image_url: image }} size="w-[54px] h-[80px]" clickable={false} key={index} />
          ))}
        </div>

        <div className="flex md:flex-grow flex-col justify-between">
          <div className="flex flex-col gap-3">
            <p className="font-bold">{quest.name}</p>
            <div className="hidden md:block text-sm">
              created {quest.created_at} // {quest.goal} books
            </div>
            <div className="md:hidden flex flex-col text-xs">
              <div>created {quest.created_at}</div>
              <div>{quest.goal} books</div>
            </div>
            <p className="hidden md:block leading-tight">{quest.description}</p>
            <div className="md:hidden flex gap-1">
              {quest.emojis?.map((emoji, index) => (
                <div key={index} className="text-[20px]">
                  {emoji}
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full justify-between items-center">
            <div className="hidden md:flex gap-3">
              {quest.emojis?.map((emoji, index) => (
                <div key={index} className="text-[24px]">
                  {emoji}
                </div>
              ))}
            </div>
            {joinButton()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestCard;
