import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import api from "../../../utils/api";
import Button from "../../helpers/Button";
import ProfileHeader from "./ProfileHeader";
import ProfileShowcasedBooks from "./ProfileShowcasedBooks";
import PostCard from "../../forum/PostCard";
import UserCard from "../../cards/UserCard";
import PointCard from "../../cards/PointCard";
import { fetchCurrentUser } from "../../../app/slices/userSlice";
import Loader from "../../helpers/Loader";
import YourReadingChallengeCard from "../../yearChallenge/YourReadingChallengeCard";
import { useDocumentTitle } from "../../../utils/hooks";

const CurrentUserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useSelector((state) => state.user);
  const followersRef = useRef(null);
  useDocumentTitle("My Profile");

  useEffect(() => {
    dispatch(fetchCurrentUser()).then(() => setIsLoading(false));
  }, []);

  const query = new URLSearchParams(useLocation().search);
  const view = query.get("view") || "posts";
  const handleViewChange = (newView) => {
    query.set("view", newView);
    navigate({ search: query.toString() });
  };

  const handleFollowersScroll = () => {
    query.set("view", "followers");
    navigate({ search: query.toString() });

    if (followersRef?.current) {
      followersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (isLoading) return <Loader variant="fullPage" />;

  return (
    <div className="py-8 md:py-16">
      <ProfileHeader user={currentUser} isCurrentUser handleFollowersScroll={handleFollowersScroll} />
      <ProfileShowcasedBooks user={currentUser} isCurrentUser />

      <div className="mx-4 md:mx-auto md:w-2/3 mt-8 max-w-[1000px]">
        <div className="mb-8 mx-auto w-full">
          <YourReadingChallengeCard user={currentUser} />
        </div>

        <div className="flex flex-row gap-2 md:gap-5 m-auto justify-center">
          {["Posts", "Followers", "Following", "Points"].map((cta) => (
            <Button
              type="navigation"
              key={cta}
              text={cta}
              onClick={() => handleViewChange(cta.toLowerCase())}
              isActive={view === cta.toLowerCase()}
            />
          ))}
        </div>

        <div className="flex flex-col gap-3 mt-6 box-border border border-offBlack shadow-main rounded-default bg-mainBlue p-5 md:p-8 w-full">
          <div className="flex flex-col gap-4 md:gap-6">
            {view === "posts" && <UserProfilePosts />}
            {view === "followers" && <UserProfileFollows isFollowers={true} followersRef={followersRef} />}
            {view === "following" && <UserProfileFollows isFollowers={false} followersRef={followersRef} />}
            {view === "points" && <UserProfilePoints />}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserProfilePosts = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/users/current/posts")
      .then((data) => setPosts(data))
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Oops - something went wrong! Please try again. Error: {error}</div>;
  }
  return (
    <>
      {posts.map((p) => (
        <PostCard key={p.id} postData={p} onClick={() => navigate(`/posts/${p.id}`)} />
      ))}
      {posts.length === 0 && (
        <div className="bg-white rounded-xl p-6">
          <b>Nothing here yet!</b>
          <p className="mt-3">Create a post in a book forum or Club forum to see it here.</p>
        </div>
      )}
    </>
  );
};

const UserProfileFollows = ({ isFollowers, followersRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [follows, setFollows] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/users/current/follows?is_followers=${isFollowers}`)
      .then((data) => setFollows(data))
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Oops - something went wrong! Please try again. Error: {error}</div>;
  }
  return (
    <>
      <div ref={followersRef} />
      {follows.map((u) => (
        <UserCard key={u.id} user={u} />
      ))}
      {follows.length === 0 && (
        <div className="bg-white rounded-xl p-6">
          <b>{isFollowers ? "You don't have any followers yet!" : "You're not following anyone yet!"}</b>
          <p className="mt-3">
            {isFollowers ? (
              "Invite a friend to Pagebound to follow along with their books and posts."
            ) : (
              <>
                Browse people already on Pagebound{" "}
                <Link to="/discover/people" className="text-pink">
                  here
                </Link>{" "}
                or invite a friend.
              </>
            )}
          </p>
        </div>
      )}
    </>
  );
};

const UserProfilePoints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [pointAwards, setPointAwards] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/users/point_awards")
      .then((data) => setPointAwards(data))
      .catch((err) => setError("Oops - something went wrong! Please try again."))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div>{error}</div>;

  return (
    <>
      {pointAwards.map((award) => (
        <PointCard key={award.id} pointAward={award} />
      ))}
    </>
  );
};

export default CurrentUserProfile;
