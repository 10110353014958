import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import ListBooks from "./ListBooks";
import Votes from "../votes/Votes";
import { useDispatch, useSelector } from "react-redux";
import { openModal, openSnackbar } from "../../app/slices/globalUISlice";
import shareIcon from "../../assets/shareIcon.svg";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "../../assets/SaveIcon";
import Button from "../helpers/Button";
import EditListForm from "./EditListForm";
import IconButton from "../helpers/IconButton";
import HeadTags from "../helpers/HeadTags";

const ListPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/lists/${uuid}`)
      .then((res) => {
        setList(res);
        setIsSaved(res.is_bookmarked);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [uuid]);

  const handleSave = async () => {
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoadingSave(true);
      const params = { type: "List", id: list.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoadingSave(false);
        setIsSaved(!isSaved);
      });
    }
  };

  const handleUpvote = () => {
    api.post(`/lists/${uuid}/upvote`).then((res) => {
      setList((prevList) => ({
        ...prevList,
        upvotes: res.upvotes,
        user_vote: 1,
      }));
    });
  };

  const handleDownvote = () => {
    api.post(`/lists/${uuid}/downvote`).then((res) => {
      setList((prevList) => ({
        ...prevList,
        upvotes: res.upvotes,
        user_vote: -1,
      }));
    });
  };

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/lists/${list.uuid}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const renderIcons = () => {
    return (
      <React.Fragment>
        {currentUser?.id === list.user.id && (
          <div className="flex gap-3">
            {!isEditing ? (
              <IconButton
                IconComponent={EditIcon}
                onClick={() => setIsEditing(true)}
                tooltip="Edit"
              />
            ) : (
              <Button onClick={() => setIsEditing(false)} text="Save" color="bg-neonBlue" />
            )}
            <IconButton onClick={handleShare} tooltip="Share">
              <img src={shareIcon} alt="share" className="w-[10px] h-[10px] md:w-[15px] md:h-[15px]" />
            </IconButton>
          </div>
        )}
      </React.Fragment>
    );
  };

  const afterListEdit = (newList) => {
    setList(newList);
    setIsEditing(false);
  };

  if (loading || !list) return <Loader variant="fullPage" />;

  return (
    <div className="container relative flex flex-col box-border lg:flex-row gap-8 lg:gap-0">
      <HeadTags title={list?.name} description={list?.description} />
      <div className="relative z-10">
        <div className="lg:fixed flex flex-col lg:pt-[100px] pb-[18px] lg:pb-0 px-3 lg:px-6 gap-6 lg:gap-8 items-center w-full lg:max-w-[350px] lg:h-[100vh] bg-gradient-to-b from-pastelOrange to-pastelBlue">
          {isEditing ? (
            <EditListForm list={list} afterListEdit={afterListEdit} />
          ) : (
            <>
              <div className="flex flex-col lg:gap-2 pt-[48px] lg:pt-0 items-center">
                <h2>{list.name}</h2>
                <p className="max-w-[400px]">{list.description}</p>
                <div className="flex gap-2">
                  {list.emojis.map((emoji, index) => (
                    <div className="text-[20px] md:text-[31px]" key={index}>
                      {emoji}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col lg:gap-3">
                <p>
                  created by <Link to={`/users/${list.user.username}`}>{list.user.username}</Link>
                </p>
                <p className="text-xs md:text-sm">last updated {list.updated_at}</p>
              </div>

              <div className="flex gap-6">
                <Votes
                  upvotes={list.upvotes}
                  handleUpvote={handleUpvote}
                  handleDownvote={handleDownvote}
                  userVote={list.user_vote}
                  variant="large"
                />
                <button
                  onClick={handleSave}
                  disabled={isLoadingSave}
                  className="hidden lg:block p-1 rounded-full hover:bg-yellow"
                >
                  <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="23" height="32" />
                </button>
                <button
                  onClick={handleSave}
                  disabled={isLoadingSave}
                  className="lg:hidden p-1 rounded-full hover:bg-yellow"
                >
                  <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="15" height="20" />
                </button>
              </div>
              <div className="self-end lg:self-auto -mt-[16px] lg:-mt-0 mr-[10px] lg:mr-0">{renderIcons()}</div>
            </>
          )}
        </div>
      </div>

      <ListBooks list={list} isEditing={isEditing} />
    </div>
  );
};

export default ListPage;
