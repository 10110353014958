import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import clsx from "clsx";
import { Rating, Tooltip } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Button from "../helpers/Button";

const StarRating = ({ text, value, isTrigger }) => {
  // NOTE: this is obnoxious because of the chevron trigger in the Collapsible component below -
  // flex positioning messes up the chevron positioning (but only on actual mobile devices)
  return (
    <span
      className={clsx(
        "mb-1",
        isTrigger ? "text-base md:text-lg" : "text-sm md:text-base",
        !isTrigger && "flex flex-row gap-2 items-center"
      )}
    >
      {text && <span className="w-[90px] md:w-[120px]">{`${text}: `}</span>}
      <Rating
        name={`${text}-rating`}
        defaultValue={Number(value) || 0}
        precision={0.1}
        size={isTrigger ? "medium" : "small"}
        readOnly
        icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="inherit" />}
        emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="inherit" />}
      />
      <span className={clsx("mx-2", isTrigger && "relative -top-[5px]")}>{value}</span>
    </span>
  );
};

const BookPageDetails = ({ setShowContent, reviewButtonRef }) => {
  const { currentBook } = useSelector((state) => state.book);
  const { currentUserBook: userBook } = useSelector((state) => state.userBook);
  const canWriteReview = userBook?.has_ever_finished || ["dnf", "finished"].includes(userBook?.status);
  const ratings = currentBook?.aggregate_ratings;
  const chevron = "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute";

  return (
    <>
      <h1 className="text-[20px] md:text-[36px] leading-tight">{currentBook?.title}</h1>
      <Link to={`/authors/${currentBook?.author_uuid}`} className="cursor-pointer w-fit block">
        <h3 className="leading-tight">{currentBook?.author_name}</h3>
      </Link>

      <div className="flex flex-col gap-1 my-2 md:gap-2 md:my-4">
        <div className="font-space font-bold">
          <Collapsible
            trigger={<StarRating value={ratings?.overall || undefined} isTrigger />}
            triggerClassName={`${chevron} after:rotate-90`}
            triggerOpenedClassName={`${chevron} after:-rotate-90`}
            transitionTime={200}
            className="relative z-0"
          >
            <StarRating text="Enjoyment" value={ratings?.entertainment || undefined} />
            <StarRating text="Quality" value={ratings?.quality || undefined} />
            <StarRating text="Characters" value={ratings?.character || undefined} />
            <StarRating text="Plot" value={ratings?.plot || undefined} />
            <div className="mb-1 mt-2">
              {canWriteReview ? (
                <Button
                  color="bg-fuschia"
                  size="medium"
                  text={userBook?.review ? "See Review" : "Write a review"}
                  onClick={() => {
                    setShowContent("reviews");
                    if (reviewButtonRef.current) {
                      if (reviewButtonRef.current) {
                        const yOffset = -40;
                        const y = reviewButtonRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }
                    }
                  }}
                />
              ) : (
                <Tooltip title="You must finish or DNF this book to write a review" disableFocusListener>
                  <div className="w-fit h-fit leading-none whitespace-nowrap text-sm px-4 py-1.5 md:px-6 md:py-2 font-bold font-space rounded-full cursor-pointer border bg-offWhite border-gray text-gray">
                    Write a review
                  </div>
                </Tooltip>
              )}
            </div>
          </Collapsible>
        </div>
        {ratings && ratings.emojis.length > 0 && (
          <div className="flex flex-row gap-2 justify-start leading-none">
            {ratings.emojis.map((e, i) => (
              <div key={`emoji_${i}`} className="text-[25px]">
                {e}
              </div>
            ))}
          </div>
        )}
        <p className="text-[10px] md:text-[12px] leading-tight">
          {ratings?.ratings_count || 0} ratings • {ratings?.reviews_count || 0} reviews
        </p>
      </div>
    </>
  );
};

export default BookPageDetails;
