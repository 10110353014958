import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookCard from "../cards/BookCard";
import { openModal } from "../../app/slices/globalUISlice";
import { useSelector, useDispatch } from "react-redux";
import SaveIcon from "../../assets/SaveIcon";
import Squiggle from "../../assets/SquiggleIcon";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import HeadTags from "../helpers/HeadTags";

const QuestPage = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const { uuid } = useParams();
  const [quest, setQuest] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/challenges/${uuid}`)
      .then((data) => {
        setQuest(data);
        setIsSaved(data.is_bookmarked);
      })
      .catch((error) => console.error("Error fetching quest:", error))
      .finally(() => setLoading(false));
  }, [uuid]);

  const handleJoinQuest = () => {
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      api
        .post(`/challenges/${uuid}/join`)
        .then((data) => {
          setQuest((prevQuest) => ({
            ...prevQuest,
            user_challenge: data,
            joined: true,
            participant_count: prevQuest.participant_count + 1,
          }));
        })
        .catch((error) => console.error("Error joining quest:", error));
    }
  };

  const handleLeaveQuest = () => {
    const handleAbandonQuest = () => {
      api
        .post(`/challenges/${uuid}/leave`)
        .then(() => {
          setQuest((prevQuest) => ({
            ...prevQuest,
            user_challenge: null,
            joined: false,
            participant_count: prevQuest.participant_count - 1,
          }));
        })
        .catch((error) => console.error("Error abandoning quest:", error));
    };
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to leave this quest?",
          confirmText: "Yes, leave",
          onConfirm: handleAbandonQuest,
        },
      })
    );
  };

  const handleBookmark = async () => {
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoadingSave(true);
      const params = { type: "Challenge", id: quest.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoadingSave(false);
        setIsSaved(!isSaved);
      });
    }
  };

  if (!quest) {
    return <Loader variant="fullPage" />;
  }

  return (
    <div className="relative h-max min-h-[100vh] mx-auto m-auto bg-cover bg-no-repeat bg-quest-squiggle">
      <HeadTags title={quest?.name} description={quest?.description} />
      <div className="container mx-auto m-auto pt-8 md:pt-[4em]">
        <header className="flex flex-col w-[80%] m-auto gap-5 md:gap-6 mb-12 md:mb-16 items-center">
          <h1>Quest</h1>
          <div className="hidden md:block">
            <Squiggle width="340" height="37" />
          </div>
          <div className="md:hidden">
            <Squiggle width="250" height="26" />
          </div>
        </header>
        <div className="container m-auto w-9/12 max-w-[1280px] box-border pb-[100px]">
          <div className="flex flex-col gap-6 md:gap-8 text-left">
            <div className="flex justify-between">
              <div className="flex flex-col md:flex-row gap-8 md:items-center">
                <h2 className="leading-none">{quest.name}</h2>
                <div className="flex gap-8 flex-row">
                  {quest.joined ? (
                    <button
                      onClick={handleLeaveQuest}
                      className="bg-mainGreen shadow-main font-bold font-space text-[11px] md:text-[13px] rounded-[50px] px-[2em] py-1 cursor-pointer border border-offBlack"
                    >
                      Joined
                    </button>
                  ) : (
                    <button
                      onClick={handleJoinQuest}
                      className="bg-neonBlue shadow-main font-bold font-space text-[11px] md:text-[13px] rounded-[50px] px-[2em] py-1 cursor-pointer border border-offBlack"
                    >
                      Join
                    </button>
                  )}
                  {quest.joined && quest.user_challenge.status === "in_progress" && (
                    <div className="bg-yellow rounded-default border-2 border-orange px-4 py-1.5">
                      <p className="leading-tight text-[11px] md:text-[13px]">
                        {quest.user_challenge.completed_books_count} of {quest.goal} read
                      </p>
                    </div>
                  )}
                  {quest.joined && quest.user_challenge.status === "finished" && (
                    <div className="bg-mainGreen rounded-default border border-offBlack px-4 py-1.5">
                      <p className="leading-tight text-[11px] md:text-[13px]">Finished</p>
                    </div>
                  )}
                </div>
              </div>

              <button
                onClick={handleBookmark}
                disabled={isLoadingSave}
                className="self-start p-1 rounded-full hover:bg-yellow"
              >
                <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="23" height="32" />
              </button>
            </div>
            <div>
              <p className="mb-2 text-sm">
                created {quest.created_at} // {quest.participant_count} participants
              </p>
              <p>{quest.description}</p>
              <div className="flex gap-2 mt-2">
                {quest.emojis.map((emoji, index) => (
                  <div key={index} className="text-[25px]">
                    {emoji}
                  </div>
                ))}
              </div>
            </div>
            {/* <p className="text-gray">{quest.participant_count} participants</p> */}
            <div className="flex flex-wrap gap-6">
              {quest.books.map((book) => {
                return <BookCard book={book} key={book.uuid} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestPage;
