import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Button from "../helpers/Button";
import ClubCard from "../cards/ClubCard";
import PostCard from "../forum/PostCard";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ClubHomeEmpty from "./ClubHomeEmpty";
import LoginPrompt from "../helpers/LoginPrompt";
import Loader from "../helpers/Loader";
import api from "../../utils/api";
import { openModal } from "../../app/slices/globalUISlice";
import HeadTags from "../helpers/HeadTags";

const ClubHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [joinedClubs, setJoinedClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clubHighlights, setClubHighlights] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreHighlights, setMoreHighlights] = useState(true);
  const page = useRef(1);

  useEffect(() => {
    setLoading(true);
    api
      .get("/clubs/joined")
      .then((clubs) => setJoinedClubs(clubs))
      .catch(() => setError("Whoops, something went wrong. Try refreshing the page."))
      .finally(() => setLoading(false));

    return () => {
      page.current = 1;
    };
  }, []);

  useEffect(() => {
    if (joinedClubs.length > 0) {
      api.get(`/clubs/joined_top_posts?page=${page.current}`).then((posts) => {
        setClubHighlights(posts);
        if (posts.length < 10) setMoreHighlights(false);
      });
    }
  }, [joinedClubs]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    page.current += 1;
    api
      .get(`/clubs/joined_top_posts?page=${page.current}`)
      .then((posts) => {
        if (posts.length == 0) setMoreHighlights(false)
        setClubHighlights((prev) => [...prev, ...posts]);
      })
      .catch(() => setError("Whoops, something went wrong. Try refreshing the page."))
      .finally(() =>  setLoadingMore(false));
  };

  const onCreateClub = () => {
    dispatch(openModal({
      name: "confirmation",
      data: {
        title: "FYI, you’ll have 7 days to gain at least 5 members to keep your Club active.",
        body: "We do this to ensure that Clubs stay vibrant and engaging",
        confirmText: "Sounds good!",
        cancelText: "Go back",
        onConfirm: () => navigate("/clubs/new"),
      },
    }));
  };

  if (loading) {
    return <Loader variant="fullPage" />;
  };

  return (
    <Layout
      title="Clubs"
      description="Discuss books with likeminded readers and find your community."
    >
      <HeadTags title="Clubs" description="Discuss books with likeminded readers and find your community." />
      {error && <p>{error}</p>}
      <div className="relative flex flex-col gap-8 md:gap-16">
        <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
          <div className="flex gap-8 text-left w-full mb-6">
            <h2 className="hidden md:block">Clubs You've Joined</h2>
            <h2 className="block md:hidden">Joined</h2>
            {!!currentUser && (
              <>
                <div className="flex md:hidden gap-2">
                  <Button text="Create" onClick={onCreateClub} color="bg-neonBlue" size="small" />
                  <Button text="Browse" type="link" to="/clubs/browse" color="bg-fuschia" size="small" />
                </div>
                <div className="hidden md:flex gap-3">
                  <Button text="Create Club" onClick={onCreateClub} color="bg-neonBlue" />
                  <Button text="Browse all" type="link" to="/clubs/browse" color="bg-fuschia" />
                </div>
              </>
            )}
          </div>
          {currentUser ? (
            <div>
              <div className="flex flex-col md:flex-row flex-wrap gap-8">
                {joinedClubs.map((club) => (
                  <ClubCard key={club.id} club={club} variant="small" />
                ))}
              </div>
              {joinedClubs.length == 0 && (
                <div className="w-full max-w-[900px] bg-lightPurple flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
                  <div className="flex flex-col gap-3 text-left pr-5">
                    <p className="font-bold">You haven't joined any Clubs yet!</p>
                    <p>Don't be shy - browse and join a Club. Once you do, you can find all your Clubs here.</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <LoginPrompt />
          )}
        </div>

        {joinedClubs.length == 0 ? (
          <ClubHomeEmpty />
        ) : (
          <div className="relative w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <div className="flex gap-8 text-left w-full mb-6">
              <h2>Highlights From Your Clubs</h2>
            </div>
            {clubHighlights.length == 0 ? (
              <div className="w-full max-w-[900px] bg-lightPurple flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
                <div className="flex flex-col gap-3 text-left pr-5">
                  <p className="font-bold">No posts in your Clubs yet!</p>
                  <p>Don't be shy - post in a Club forum to start the convo.</p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-6">
                {clubHighlights.map((post) => (
                  <div
                    key={post.id}
                    className="bg-pastelBlue rounded-default border border-offBlack text-left shadow-main flex flex-col p-4 gap-2"
                  >
                    <p className="font-bold">From the {post.forum.name} forum</p>
                    <PostCard key={post.id} postData={post} onClick={() => navigate(`/posts/${post.id}`)} />
                  </div>
                ))}
                {moreHighlights && (
                  <button className="bg-transparent p-2 self-center mt-2" disabled={loadingMore} onClick={handleLoadMore}>
                    {loadingMore ? (
                      "Loading..."
                    ) : (
                      <>
                        View More <ExpandMoreOutlinedIcon fontSize="small" />
                      </>
                    )}
                  </button>
                )}
                {!moreHighlights && !loadingMore && (
                  <p className="bg-transparent p-2 self-center mt-2">You're all caught up!</p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ClubHome;
