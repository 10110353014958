import AddBookFromGoodreadsModal from "./AddBookFromGoodreadsModal";
import BookSocialActivityModal from "./BookSocialActivityModal";
import ChangeBookStatusModal from "./ChangeBookStatusModal";
import ConfirmationModal from "./ConfirmationModal";
import GoodreadsDirectionsModal from "./GoodreadsDirectionsModal";
import LoginPromptModal from "./LoginPromptModal";
import NewReviewModal from "./NewReviewModal";
import SearchReturnBookModal from "./SearchReturnBookModal";
import SearchTBRModal from "./SearchTBRModal";
import ShowPostModal from "./ShowPostModal";
import ViewClubMembersModal from "./ViewClubMembersModal";
import InfoModal from "./InfoModal";

const MODAL_CONFIG = {
  addBookFromGoodreads: AddBookFromGoodreadsModal,
  bookSocialActivity: BookSocialActivityModal,
  changeBookStatus: ChangeBookStatusModal,
  confirmation: ConfirmationModal,
  goodreadsDirections: GoodreadsDirectionsModal,
  loginPrompt: LoginPromptModal,
  newReview: NewReviewModal,
  searchReturnBook: SearchReturnBookModal,
  searchTBR: SearchTBRModal,
  showPost: ShowPostModal,
  viewClubMembers: ViewClubMembersModal,
  info: InfoModal,
};

export default MODAL_CONFIG;
