import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../helpers/Button";
import SaveIcon from "../../assets/SaveIcon";
import { joinClub } from "../../app/slices/clubSlice";
import api from "../../utils/api";

const OnboardingClubCard = ({ club, variant }) => {
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(club?.is_bookmarked || false);
  const [isJoined, setIsJoined] = useState(club?.member_status === "joined" || false);
  const [isLoading, setIsLoading] = useState(false);
  const altBookImg = "/assets/AltBookImg.svg";

  const handleIconClick = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    const params = { type: "Club", id: club.id };
    const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
    action.then(() => {
      setIsLoading(false);
      setIsSaved(!isSaved);
    });
  };

  const handleJoin = async () => {
    if (club.member_status === "joined" || isJoined || club.name === "Pagebound Club") return;
    await dispatch(joinClub(club.uuid))
      .unwrap()
      .then(() => setIsJoined(true));
  };

  return (
    <div className="relative flex flex-col w-full md:max-w-[900px] p-4 md:p-6 gap-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between">
      <button
        onClick={handleIconClick}
        className="absolute top-4 right-3 p-1 rounded-full hover:bg-yellow"
        disabled={isLoading}
      >
        <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
      </button>
      <div className="flex flex-col md:flex-row gap-2 md:gap-6 w-full justify-between">
        <div className="hidden md:flex flex-col gap-3 mr-2">
          <div className="flex gap-6">
            <p className="font-bold truncate">{club.name}</p>
            <Button
              text={club.member_status === "joined" || isJoined ? "Joined" : "Join"}
              color={club.member_status === "joined" || isJoined ? "bg-mainGreen" : "bg-neonBlue"}
              disabled={club.member_status === "joined" || isJoined}
              onClick={handleJoin}
            />
          </div>
          {club.name !== "Pagebound Club" && <div className="text-xs md:text-s">{club.member_count} joined</div>}
          <p className="leading-tight">{club.description}</p>
        </div>

        {/* mobile style */}
        <div className="flex md:hidden flex-col gap-3">
          <p className="font-bold truncate">{club.name}</p>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              {club.name !== "Pagebound Club" && <div className="text-xs md:text-s">{club.member_count} joined</div>}
              <Button
                text={club.member_status === "joined" || isJoined ? "Joined" : "Join"}
                color={club.member_status === "joined" || isJoined ? "bg-mainGreen" : "bg-neonBlue"}
                onClick={handleJoin}
                disabled={club.member_status === "joined" || isJoined}
              />
            </div>
            <div className="flex gap-1">
              {club.emojis.map((emoji, index) => (
                <div key={index} className="text-[20px]">
                  {emoji}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5 md:items-end">
          <div className="hidden md:flex w-full gap-3 justify-center mr-2">
            {club.emojis.map((emoji, index) => (
              <div key={index} className="text-[24px]">
                {emoji}
              </div>
            ))}
          </div>

          <div className="flex gap-2">
            {club.book_images.map((image, index) => (
              <div
                key={index}
                className="w-[54px] h-[80px]"
                style={{
                  backgroundImage: `url(${image || altBookImg})`,
                  backgroundSize: "cover",
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingClubCard;
