import React from "react";
import PopularClubs from "./PopularClubs";
import NewClubs from "./NewClubs";

const ClubHomeEmpty = () => {
  return (
    <>
      <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
        <div className="flex gap-8 text-left w-full mb-6">
          <h2>Discover Popular Clubs</h2>
        </div>
        <PopularClubs />
      </div>

      <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
        <div className="flex flex-col gap-2 text-left w-full mb-6">
          <h2>Discover New Clubs</h2>
          <p>Newly created Clubs have 7 days to gain at least 5 members.</p>
        </div>
        <NewClubs />
      </div>
    </>
  );
};

export default ClubHomeEmpty;
