import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/slices/globalUISlice";
import SaveIcon from "../../assets/SaveIcon";
import api from "../../utils/api";
import BookCover from "../helpers/BookCover";
import Button from "../helpers/Button";
import { Link, useNavigate } from "react-router-dom";
import Votes from "../votes/Votes";

const ListCard = ({ listData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [isSaved, setIsSaved] = useState(listData?.is_bookmarked || false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState(listData);

  if (!list) return;

  const handleIconClick = async (event) => {
    event.stopPropagation();
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: "List", id: list.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setIsSaved(!isSaved);
      });
    }
  };

  const handleUpvote = (event) => {
    event.stopPropagation();
    api.post(`/lists/${list.uuid}/upvote`).then((res) => {
      setList((prevList) => ({
        ...prevList,
        upvotes: res.upvotes,
        user_vote: 1,
      }));
    });
  };

  const handleDownvote = (event) => {
    event.stopPropagation();
    api.post(`/lists/${list.uuid}/downvote`).then((res) => {
      setList((prevList) => ({
        ...prevList,
        upvotes: res.upvotes,
        user_vote: -1,
      }));
    });
  };

  return (
    <div
      onClick={() => navigate(`/lists/${list.uuid}`)}
      className="relative w-full max-w-[900px] hover:cursor-pointer bg-white rounded-default p-4 md:p-6 border border-offBlack shadow-main flex gap-6 max-h-[242px] text-left"
    >
      <button
        onClick={handleIconClick}
        className="absolute top-1 md:top-2 right-2 md:right-4 p-1 rounded-full hover:bg-yellow"
        disabled={isLoading}
      >
        <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
      </button>
      <Votes
        className="hidden md:flex"
        isVertical
        handleUpvote={handleUpvote}
        handleDownvote={handleDownvote}
        userVote={list.user_vote}
        upvotes={list.upvotes}
      />
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div className="flex flex-col gap-1 justify-between">
          <p className="font-bold leading-none">{list.name}</p>
          <div className="flex gap-2">
            <div className="text-xs text-gray">
              <Button
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/users/${list.user.username}`);
                }}
                text={`by ${list.user.username}`}
              />
            </div>
            <div className="text-xs text-gray hidden md:block">//</div>
            <div className="text-xs text-gray hidden md:block">{list.book_count} books</div>
          </div>
          <div className="hidden md:flex text-sm mb-2 leading-snug text-ellipsis line-clamp-2">{list.description}</div>
          <div className="flex gap-1">
            {list.emojis?.map((emoji, index) => (
              <div key={index} className="text-[20px] md:text-[24px]">
                {emoji}
              </div>
            ))}
          </div>
        </div>
        <div className="hidden md:flex gap-3 mt-4 ml-1">
          {list.book_images?.map((image, index) => (
            <BookCover
              key={index}
              book={{ image_url: image }}
              clickable={false}
              size="w-[54px] h-[80px] md:w-16 md:h-24"
            />
          ))}
        </div>
        <Votes
          className="block md:hidden"
          handleUpvote={handleUpvote}
          handleDownvote={handleDownvote}
          userVote={list.user_vote}
          upvotes={list.upvotes}
        />
      </div>
      <div className="flex md:hidden gap-3 mt-4 ml-1">
        {list.book_images?.slice(0, 2).map((image, index) => (
          <BookCover
            key={index}
            book={{ image_url: image }}
            clickable={false}
            size="w-[54px] h-[80px] md:w-16 md:h-24"
          />
        ))}
      </div>
    </div>
  );
};

export default ListCard;
