import React from "react";
import QuestCard from "../quests/QuestCard";

const QuestResults = ({ searchQuery, searchResults }) => {
  if (searchResults.length === 0) {
    return (
      <div className="bg-white rounded-xl p-6">
        <b>Hmmm, we didn't find any anything for "{searchQuery}"</b>
        <p className="mt-2">
          Sorry! That Quest doesn't exist yet but shoot us an email at team@pagebound.co
          with your request and we'll do our best to create one!
        </p>
      </div>
    );
  };

  return (
    <>
      {searchResults.map((quest) => <QuestCard key={quest.id} quest={quest} />)}
    </>
  );
};

export default QuestResults;
