import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapsible from "react-collapsible";
import EmojiPicker from "emoji-picker-react";
import { CLUB_THEMES } from "../../../constants";
import { Formik, Field, FieldArray, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { updateClub } from "../../../app/slices/clubSlice";
import Modal from "../../modals/Modal";
import Button from "../../helpers/Button";
import Switch from "../../helpers/Switch";

const EditClub = ({ setIsEditing }) => {
  const dispatch = useDispatch();
  const { currentClub } = useSelector((state) => state.club);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const clubSchema = Yup.object().shape({
    description: Yup.string().required("Required").max(500, "Too Long"),
    private: Yup.boolean(),
    taste_description: Yup.string().max(500, "Too Long"),
    member_nickname: Yup.string().max(15, "Too Long"),
    color: Yup.string().required("Required"),
    emojis: Yup.array().min(3, "Select 3 emojis").max(3, "Maximum 3 emojis"),
  });

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    dispatch(updateClub({ clubData: values, uuid: currentClub.uuid }))
      .unwrap()
      .then((data) => {
        if (data.error) {
          setSubmitting(false);
          setStatus({
            error: "An unexpected error occurred. Please check your form and try again.",
          });
          console.error("if statement error:", data.error);
        } else {
          setIsEditing(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus({
          error: "An unexpected error occurred. Please check your form and try again.",
        });
        console.error("catch error: ", error);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const PrivateSwitch = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
      <div className="flex flex-col gap-3 w-full items-start">
        <div className="flex flex-col gap-1 w-full items-start text-left">
          <label className="mr-4 font-bold" htmlFor="private">
            Do you want to make your Club private?
          </label>
          <p className="text-xs">If yes, you'll need to approve members who request to join.</p>
        </div>
        <div className="flex flex-row">
          <Switch id="private" isChecked={value} onChange={() => setValue(!value)} />
        </div>
      </div>
    );
  };

  const ThemePicker = (props) => {
    const [field, meta, helpers] = useField(props.name);

    const { value } = meta;
    const { setValue } = helpers;
    const isSelected = (v) => (v === value ? "selected" : "");

    const chevron =
      "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute after:top-2 after:left-[55px]";
    return (
      <Collapsible
        trigger={
          <div
            className={`w-[45px] h-[45px] rounded-boxy border border-offBlack ${CLUB_THEMES[value] || "bg-white"}`}
          />
        }
        triggerClassName={`${chevron} after:rotate-90`}
        triggerOpenedClassName={`${chevron} after:-rotate-90`}
        transitionTime={200}
        className="flex flex-col w-full items-start"
        openedClassName="flex flex-col w-full items-start"
        contentOuterClassName="w-full"
        contentInnerClassName="flex flex-wrap w-full gap-6 md:gap-10 items-start mt-4"
      >
        <React.Fragment>
          <button
            onClick={() => setValue("theme1")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme1} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme2")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme2} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme3")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme3} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme4")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme4} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme5")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme5} border border-offBlack rounded-boxy`}
          />
          <button
            onClick={() => setValue("theme6")}
            type="button"
            className={`w-[45px] h-[45px] ${CLUB_THEMES.theme6} border border-offBlack rounded-boxy`}
          />
        </React.Fragment>
      </Collapsible>
    );
  };

  return (
    <Formik
      validationSchema={clubSchema}
      onSubmit={handleSubmit}
      initialValues={{
        color: currentClub.color,
        description: currentClub.description,
        private: currentClub.private,
        taste_description: currentClub.taste_description,
        member_nickname: currentClub.member_nickname,
        emojis: currentClub.emojis,
      }}
    >
      {({ errors, values, isSubmitting, isValid, status }) => (
        <Form
          onKeyDown={handleKeyDown}
          className="w-full m-auto flex flex-col items-start p-8 gap-6 bg-seafoam border border-offBlack rounded-default shadow-main"
        >
          <div className="flex gap-2 max-w-full flex-wrap text-left">
            <h2>Edit Club</h2>
          </div>
          <div className="flex flex-col gap-3 w-full items-start">
            <label htmlFor="description" className="font-bold">
              Club description:
            </label>
            <Field
              id="description"
              name="description"
              placeholder="max 500 characters"
              as="textarea"
              rows={3}
              className="w-full ml-0 mr-5 px-4 py-3 my-auto"
            />
            <ErrorMessage name="description" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <PrivateSwitch name="private" />
          <div className="flex flex-col gap-3 w-full items-start">
            <div className="flex flex-col gap-1 w-full items-start text-left">
              <label htmlFor="member_nickname" className="font-bold">
                What will members of your Club be called?
              </label>
              <p className="text-xs">e.g., wizards, adventurers, girlies</p>
            </div>
            <Field
              id="member_nickname"
              name="member_nickname"
              placeholder="Default: members"
              className="ml-0 mr-5 px-4 py-3 my-auto"
            />
            <ErrorMessage name="member_nickname" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <div className="flex flex-col gap-3 w-full items-start text-left">
            <p className="font-bold">Choose a color theme for your Club:</p>
            <ThemePicker name="color" />
            <ErrorMessage name="color" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <div className="flex flex-col gap-3 w-full items-start text-left">
            <p className="font-bold">Select 3 emojis to describe your Club:</p>
            <FieldArray
              name="emojis"
              render={(arrayHelpers) => (
                <div className="flex gap-4 items-start">
                  {values.emojis?.length > 0 &&
                    values.emojis.map((emoji, index) => (
                      <div key={index} className="flex flex-col">
                        <div className="text-[25px]">{emoji}</div>
                        <button type="button" onClick={() => arrayHelpers.remove(index)}>
                          <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
                        </button>
                      </div>
                    ))}
                  {values.emojis?.length < 3 && (
                    <button type="button" className="pt-2" onClick={() => setShowEmojiPicker(true)}>
                      <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
                    </button>
                  )}

                  <Modal isOpen={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}>
                    <EmojiPicker
                      skinTonesDisabled
                      lazyLoadEmojis
                      previewConfig={{ showPreview: false }}
                      onEmojiClick={(emojiData, event) => {
                        if (values.emojis.length < 3) arrayHelpers.push(emojiData.emoji);
                        if (values.emojis.length == 2) setShowEmojiPicker(false);
                      }}
                    />
                  </Modal>
                </div>
              )}
            />
            <ErrorMessage name="emojis" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <div className="flex flex-col gap-3 w-full items-start">
            <div className="flex flex-col gap-1 w-full items-start text-left">
              <label htmlFor="taste_description" className="font-bold">
                <q>Our Taste</q> description:
              </label>
              <p className="text-xs">
                (optional) Why did you pick these books? This text will appear next to the books you selected above.
              </p>
            </div>
            <Field
              id="taste_description"
              name="taste_description"
              placeholder="max 500 characters"
              as="textarea"
              rows={3}
              className="w-full ml-0 mr-5 px-4 py-3 my-auto"
            />
            <ErrorMessage name="taste_description" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <ErrorMessage name="books" render={(msg) => <div className="text-red">{msg}</div>} />
          <div className="w-full flex justify-end items-center">
            {status && status.error && <div className="text-red mr-4">{status.error}</div>}
            <Button disabled={!isValid || isSubmitting} color="bg-neonBlue" text="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditClub;
