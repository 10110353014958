import React from "react";
import ListCard from "../cards/ListCard";
import Button from "../helpers/Button";

const ListResults = ({ searchQuery, searchResults }) => {
  if (searchResults.length === 0) {
    return (
      <div className="flex flex-col md:flex-row items-center justify-between gap-6 bg-white rounded-xl border border-offBlack shadow-main p-6 bg-opacity-80 text-left">
        <div>
          <b>Not finding the list you're looking for?</b>
          <p className="mt-2">You should create it 😏</p>
        </div>
        <Button type="link" to="/lists/new" text="Create list" color="bg-red" />
      </div>
    );
  }

  return (
    <>
      {searchResults.map((list) => (
        <ListCard key={list.id} listData={list} />
      ))}
    </>
  );
};

export default ListResults;
