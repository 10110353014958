import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../helpers/Button";
import { openSnackbar } from "../../app/slices/globalUISlice";

const OnboardingReferral = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const ONBOARDING_ACTIVITY_TYPE_MAP = {
    lists: {
      color: "bg-pastelPink",
      title: "Lists",
      description:
        "Like a bookish Pinterest board, curate a themed collection of books and earn votes from the community. ☝️",
      cta: "View Lists",
      ctaColor: "bg-dnf",
      link: "/lists",
    },
    quests: {
      color: "bg-pastelOrange",
      title: "Quests",
      description:
        "A quest is a reading challenge with 10 books max. Earn points for completion 🏆 or just use it to discover new books.",
      cta: "View Quests",
      ctaColor: "bg-finished",
      link: "/discover/quests",
    },
    friends: {
      color: "bg-pastelPurple",
      title: "Follow readers",
      description: "Check out other reader profiles, and make friends based on shared taste. 🫂",
      cta: "View Users",
      ctaColor: "bg-current",
      link: "/discover/people",
    },
  };

  return (
    <div className="min-h-[100vh] h-max bg-gradient-to-b to-pastelPurple from-pastelBlue m-auto flex flex-col items-center">
      <div className="container mt-[78px] md:mt-[120px] flex flex-col gap-6 justify-center items-center mb-[50px] px-4">
        <h2>
          Welcome to Pagebound, {""}
          <br className="md:hidden" />
          {currentUser.username} 🎉 🥳 🍾
        </h2>
        <p className="max-w-[400px]">Here are a few places to jump into the community:</p>
        {Object.keys(ONBOARDING_ACTIVITY_TYPE_MAP).map((type) => (
          <div
            key={type}
            className={`${ONBOARDING_ACTIVITY_TYPE_MAP[type].color} w-full max-w-[800px] flex flex-col md:flex-row justify-between items-center rounded-default p-6 border border-offBlack shadow-main `}
          >
            <div className="flex flex-col gap-3 text-left pr-5">
              <p className="font-bold">{ONBOARDING_ACTIVITY_TYPE_MAP[type].title}</p>
              <p>{ONBOARDING_ACTIVITY_TYPE_MAP[type].description}</p>
            </div>
            <div className="self-end md:self-center flex-none mt-3 md:mt-0">
              <Button
                type="link"
                text={ONBOARDING_ACTIVITY_TYPE_MAP[type].cta}
                to={ONBOARDING_ACTIVITY_TYPE_MAP[type].link}
                color={ONBOARDING_ACTIVITY_TYPE_MAP[type].ctaColor}
              />
            </div>
          </div>
        ))}
        <p className="leading-tight w-9/12 md:w-full text-left md:text-center mt-2">
          Right now, we're only allowing limited readers onto Pagebound, {""}
          <br className="hidden md:block" />
          but you can share this early access link with your friends.
        </p>
        <div className="flex flex-col md:flex-row gap-6 items-center justify-center">
          <Button text="Copy early access link" color="bg-neonBlue" size="medium" onClick={handleShare} />
          <Button text="Show me my feed" color="bg-fuschia" size="medium" onClick={() => navigate("/")} />
        </div>
        <p className="w-9/12 md:w-full text-left md:text-center mb-4">
          You can always access a link to share from your profile page.
        </p>
      </div>
    </div>
  );
};

export default OnboardingReferral;
