import React from "react";
import Helmet from "react-helmet";

const HeadTags = ({ title, description, image }) => {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title} | Pagebound </title>
          <meta property="og:title" content={`${title} | Pagebound`} />
        </Helmet>
      )}

      {description && (
        <Helmet>
          <meta name="description" property="og:description" content={description.slice(0, 200)} />
        </Helmet>
      )}

      {image && (
        <Helmet>
          <meta property="og:image" content={image} />
        </Helmet>
      )}
    </>
  );
};

export default HeadTags;
