import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/slices/globalUISlice";
import BookCover from "../helpers/BookCover";
import BookStatusButton from "../helpers/BookStatusButton";
import SaveIcon from "../../assets/SaveIcon";
import api from "../../utils/api";
import Votes from "../votes/Votes";
import { BOOK_STATUS_BG_COLOR } from "../../constants";

const RecommendationCard = ({ recommendation, icon }) => {
  const dispatch = useDispatch();
  const book = recommendation.recommended_book;
  const { currentUser } = useSelector((state) => state.user);
  const [isSaved, setIsSaved] = useState(book?.is_bookmarked || false);
  const [isLoading, setIsLoading] = useState(false);
  const [userBookData, setUserBookData] = useState(book?.user_book);
  const [recommendationData, setRecommendationData] = useState(recommendation);

  const upvote = (recommendation) => {
    api
      .post(`/recommendations/${recommendation.id}/upvote`)
      .then((data) => {
        setRecommendationData(data);
      })
      .catch((error) => console.error("Error upvoting recommendation:", error));
  };

  const downvote = (recommendation) => {
    api
      .post(`/recommendations/${recommendation.id}/downvote`)
      .then((data) => {
        setRecommendationData(data);
      })
      .catch((error) => console.error("Error downvoting recommendation:", error));
  };

  const handleIconClick = async (event) => {
    event.stopPropagation();
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: "Book", id: book.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setIsSaved(!isSaved);
      });
    }
  };

  return (
    <div
      className={`relative flex flex-col flex-none box-border w-fit h-fit py-4 px-[35px] md:py-6 md:px-[45px] ${
        BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
      } items-center shadow-main rounded-default border border-offBlack`}
    >
      <div className="absolute top-2 justify-center items-center md:top-4 right-2 md:right-[10px] flex flex-col gap-2">
        {icon || (
          <button className="p-1 rounded-full hover:bg-yellow" onClick={handleIconClick} disabled={isLoading}>
            <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
          </button>
        )}
        <BookStatusButton userBook={userBookData} bookId={book.id} setUserBookData={setUserBookData} />
      </div>
      <div className="w-[62px] h-24 md:w-[104px] md:h-40 cursor-pointer">
        <BookCover book={book} size="w-[62px] h-24 md:w-[104px] md:h-40 border shadow-main" showTitle={true} />
      </div>
      <div className="self-center mt-3 md:mt-5">
        <Votes
          key={`${recommendation.id}-votes`}
          userVote={recommendationData.user_vote}
          upvotes={recommendationData.upvotes}
          handleDownvote={() => downvote(recommendationData)}
          handleUpvote={() => upvote(recommendationData)}
        />
      </div>
    </div>
  );
};

export default RecommendationCard;
