import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Rating } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { closeModal } from "../../app/slices/globalUISlice";
import Modal from "./Modal";
import api from "../../utils/api";
import { BOOK_STATUS_BG_COLOR, BOOK_STATUS_BORDER_COLOR, BOOK_STATUS_ICON_MAP } from "../../constants";
import Loader from "../helpers/Loader";

const BookSocialActivityModal = ({ view: initialView }) => {
  const dispatch = useDispatch();
  const { currentBook } = useSelector((state) => state.book);
  const [view, setView] = useState(initialView);
  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/books/${currentBook.uuid}/social_activity?status=${view}`)
      .then((data) => setActivity(data))
      .finally(() => setIsLoading(false));
  }, [view]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const textMap = {
    tbr: "want to read",
    current: "are reading",
    finished: "finished",
    dnf: "DNFd",
  };

  return (
    <Modal isOpen>
      <div className="md:w-[425px] flex flex-col gap-3">
        <b className="line-clamp-2 md:line-clamp-1">
          People you follow who {textMap[view]} {currentBook?.title}
        </b>
        <div className="flex flex-row gap-1.5 md:gap-3">
          {["tbr", "current", "finished", "dnf"].map((status) => (
            <div
              key={status}
              className={clsx(
                "flex items-center justify-center rounded-boxy cursor-pointer grow h-[45px] border-2",
                BOOK_STATUS_BORDER_COLOR[status],
                view === status && BOOK_STATUS_BG_COLOR[status]
              )}
              onClick={() => setView(status)}
            >
              <img src={BOOK_STATUS_ICON_MAP[status]} alt={status} className="w-[20px] h-[20px]" />
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-3">
          {isLoading ? (
            <Loader />
          ) : activity.length === 0 ? (
            <div>No activity yet!</div>
          ) : (
            activity?.map((item, i) => (
              <div key={i} className="flex flex-row gap-2 items-center">
                <div className="relative h-[30px] w-[30px]">
                  <img src={item.user_image_url} className="rounded-full border border-offBlack absolute" />
                </div>
                <Link to={`/users/${item.username}`} onClick={handleClose}>
                  {item.username}
                </Link>
                {item.rating && (
                  <>
                    {" "}
                    rated{" "}
                    <Rating
                      defaultValue={Number(item.rating)}
                      precision={0.1}
                      size="small"
                      readOnly
                      icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="inherit" />}
                      emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="inherit" />}
                    />
                  </>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BookSocialActivityModal;
