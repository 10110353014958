import React from "react";
import Button from "../helpers/Button";
import Modal from "./Modal";

const GoodreadsDirectionsModal = () => {
  return (
    <Modal isOpen>
      <div className="md:max-w-[500px] p-2 flex flex-col gap-4">
        <p>
          To export your Goodreads data into a CSV file...
          <br />
          <br />
          1. Log in to your Goodreads account and click My Books.
          <br />
          <br />
          2. Click Import and Export under Tools on the left sidebar.
          <br />
          <br />
          3. Click the Export Library button at the top of the screen below the Export heading. Your export from (date)
          - (time) will appear below.
          <br />
          <br />
          4. Click on that text to download the CSV file.
        </p>
        <Button text="Take me to Goodreads" type="link" to="https://www.goodreads.com/" color="bg-neonBlue self-end" />
      </div>
    </Modal>
  );
};

export default GoodreadsDirectionsModal;
