import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { addBookToShelf, removeBookFromShelf } from "../../../app/slices/shelfSlice";
import { openModal, openSnackbar } from "../../../app/slices/globalUISlice";
import LibraryBookCard from "./LibraryBookCard";
import LibraryAddBookCard from "./LibraryAddBookCard";
import EditTagForm from "./EditTagForm";
import api from "../../../utils/api";
import Tag from "../../helpers/Tag";
import IconButton from "../../helpers/IconButton";
import Loader from "../../helpers/Loader";
import shareIcon from "../../../assets/shareIcon.svg";

const TagView = ({ shelfUuid, setShowLibraryList, showListView }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [shelf, setShelf] = useState(null);
  const [userBooks, setUserBooks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [moreData, setMoreData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({ attribute: "added_at", isAscending: false });
  const pageRef = useRef(1);

  useEffect(() => {
    if (!shelfUuid) return;
    setError(null);
    pageRef.current = 1;
    api
      .get(`/shelves/${shelfUuid}?page=1${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setShelf(data.shelf);
        setUserBooks(data.user_books);
        setMoreData(data.total_pages > 1);
      })
      .catch((err) => console.error(`Error fetching shelf: ${err}`));
  }, [shelfUuid, sortBy]);

  const handleMoreData = () => {
    setLoading(true);
    pageRef.current += 1;
    api
      .get(`/shelves/${shelfUuid}?page=${pageRef.current}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setUserBooks((prevBooks) => [...prevBooks, ...data.user_books]);
        setMoreData(data.total_pages > pageRef.current);
      })
      .finally(() => setLoading(false));
  };

  const addBook = async (book) => {
    dispatch(addBookToShelf({ shelfId: shelf.id, bookId: book.id }))
      .unwrap()
      .then((data) => {
        setUserBooks([...userBooks, data]);
      })
      .catch((err) => {
        dispatch(openSnackbar({ message: err }));
      });
  };

  const afterShelfEdit = (newShelfData) => {
    setShelf((prevShelf) => ({ ...prevShelf, ...newShelfData }));
    setIsEditing(false);
  };

  const onClickAddBookCard = () => {
    const addedBookIds = userBooks.map((ub) => ub.book_id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick: addBook } }));
  };

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/users/${currentUser.username}/library/${shelf.uuid}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const removeBookIcon = (userBook) => {
    return (
      <button
        type="button"
        onClick={() => {
          dispatch(removeBookFromShelf({ shelfId: shelf.id, userBookId: userBook.id }))
          .unwrap()
          .then(() => setUserBooks(userBooks.filter((b) => b.id !== userBook.id)))
          .catch((err) => dispatch(openSnackbar({ message: err })));
        }}
      >
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const updateSorting = (col) => {
    if (sortBy.attribute === col) {
      setSortBy((prev) => ({ ...prev, isAscending: !prev.isAscending }));
    } else {
      setSortBy({ attribute: col, isAscending: true });
    };
  };

  const sortIcon = sortBy.isAscending
    ? <span className="relative"><ArrowDropUpRoundedIcon classes={{ root: "absolute -top-[7px] -left-[9px]" }} fontSize="large" color="primary" /></span>
    : <span className="relative"><ArrowDropDownRoundedIcon classes={{ root: "absolute -top-[7px] -left-[9px]" }} fontSize="large" color="primary" /></span>;

  return (
    <div className="w-full min-w-[350px] lg:w-[652px] xl:w-[856px] flex flex-col flex-wrap gap-2 bg-gradient-to-b from-pastelBlue to-pastelPink box-border p-6 md:p-8 rounded-default items-start text-left">
      <div className="flex justify-between items-center w-full">
        {error ? (
          <div className="text-red">{error}</div>
        ) : !shelf ? (
          <Loader />
        ) : isEditing ? (
          <EditTagForm shelf={shelf} afterShelfEdit={afterShelfEdit} />
        ) : (
          <div className="w-full">
            <div className="flex justify-between w-full mb-2">
              <div className="flex gap-4 items-center mr-2">
                <button className="lg:hidden flex items-center gap-1 text-left" onClick={() => setShowLibraryList(true)}>
                  <h2>{shelf.name}</h2>
                  <ExpandMoreOutlinedIcon fontSize="small" />
                </button>
                <h2 className="hidden lg:inline-block">{shelf.name}</h2>
                {shelf.private && <Tag text="private" color="bg-pastelPink" IconComponent={LockOutlinedIcon} />}
              </div>
              <div className="flex gap-2">
                <IconButton
                  IconComponent={EditIcon}
                  onClick={() => setIsEditing(true)}
                  tooltip="Edit"
                />
                <IconButton onClick={handleShare} tooltip="Share">
                  <img src={shareIcon} alt="share" className="w-[15px] h-[15px]" />
                </IconButton>
              </div>
            </div>
            <p>{shelf.description}</p>
          </div>
        )}
      </div>
      {showListView ? (
        <Table>
          <TableHead classes={{ root: "whitespace-nowrap" }}>
            <TableRow>
              <TableCell classes={{ root: "w-[90px]" }}>Cover</TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("title")}>
                Title {sortBy.attribute === "title" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("author")}>
                Author {sortBy.attribute === "author" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("rating")}>
                My Rating {sortBy.attribute === "rating" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "min-w-[85px] cursor-pointer" }} onClick={() => updateSorting("read_at")}>
                Read on {sortBy.attribute === "read_at" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("added_at")}>
                Added on {sortBy.attribute === "added_at" && sortIcon}
              </TableCell>
              <TableCell classes={{ root: "min-w-[24px]" }}>{/* status icon button */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shelf && isEditing && (
              <TableRow>
                <TableCell>
                  <div
                    className="flex justify-center items-center w-[65px] h-[100px] bg-white border shadow-main cursor-pointer"
                    onClick={onClickAddBookCard}
                  >
                    <AddCircleOutlineIcon fontSize="small" sx={{ color: "#333" }} />
                  </div>
                </TableCell>
                <TableCell>add book</TableCell>
              </TableRow>
            )}
            {userBooks?.map((userBook) => (
              <LibraryBookCard userBook={userBook} key={userBook.id} view="row" icon={isEditing ? removeBookIcon(userBook) : null} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="w-full flex flex-col md:grid md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 mt-4 items-center justify-center">
          {shelf && isEditing && <LibraryAddBookCard onClick={onClickAddBookCard} />}
          {userBooks?.map((userBook) => (
            <LibraryBookCard userBook={userBook} key={userBook.id} icon={isEditing ? removeBookIcon(userBook) : null} />
          ))}
          {shelf && !isEditing && <LibraryAddBookCard onClick={onClickAddBookCard} />}
        </div>
      )}

      {userBooks?.length > 0 && moreData && (
        <button className="bg-transparent p-2 self-center mt-2" disabled={loading} onClick={handleMoreData}>
          {loading ? (
            "Loading..."
          ) : (
            <>
              View More <ExpandMoreOutlinedIcon fontSize="small" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default TagView;
