import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../../helpers/Menu";
import {
  createComment,
  upvoteComment,
  downvoteComment,
  updateComment,
  deleteComment,
} from "../../../app/slices/forumSlice";
import { openModal, openSnackbar, setModalDirty } from "../../../app/slices/globalUISlice";
import ChevronRightCircled from "../../../assets/chevron-right-circled.svg";
import Votes from "../../votes/Votes";
import api from "../../../utils/api";

const CommentActions = ({ comment, setComment, onEdit, onReply }) => {
  const dispatch = useDispatch();
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const currentUser = useSelector((state) => state.user.currentUser);
  const isCurrentUsersComment = currentUser?.id === comment.user_id;

  const handleUpvote = () => {
    dispatch(upvoteComment(comment.id))
      .unwrap()
      .then((data) => {
        setComment({ ...comment, upvotes: data.upvotes, user_vote: data.user_vote });
      });
  };

  const handleDownvote = () => {
    dispatch(downvoteComment(comment.id))
      .unwrap()
      .then((data) => {
        setComment({ ...comment, upvotes: data.upvotes, user_vote: data.user_vote });
      });
  };

  const onChangeReply = (e) => {
    dispatch(setModalDirty(true));
    setReplyContent(e.target.value);
  };

  const createReply = (event) => {
    event.preventDefault();
    dispatch(
      createComment({
        parent_comment_id: comment.id,
        post_id: comment.post_id,
        content: replyContent,
      })
    )
      .unwrap()
      .then((data) => {
        setComment((prevState) => ({
          ...data.parent,
          child_comments: [...prevState.child_comments, data.comment],
        }));
        dispatch(setModalDirty(false));
      })
      .catch((err) => console.error("Error creating reply:", err));

    onReply();
    setReplyContent("");
    setShowReplyInput(false);
  };

  const handleShare = () => {
    navigator.clipboard.writeText(`${window.location.origin}/posts/${comment.post_id}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const handleFlag = () => {
    const body = (
      <>
        Flag this comment if it violates Pagebound's{" "}
        <Link to="/" target="_blank">
          Terms of Use
        </Link>
        . We will remove the comment until we can do a manual review. Since we are a small company, this review may take
        up to 24 hours.
        <br />
        <br />
        Thanks for your help moderating the Pagebound community!
      </>
    );
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to flag this comment?",
          body,
          confirmText: "Yes, flag",
          onConfirm: () => {
            api
              .post(`/flags`, { resource_type: "Comment", resource_id: comment.id })
              .then(() => setComment((prevComment) => ({ ...prevComment, is_flagged: true })));
          },
        },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to delete this comment?",
          confirmText: "Yes, delete",
          onConfirm: () => {
            dispatch(deleteComment(comment.id))
              .unwrap()
              .then((data) => setComment(data));
          },
        },
      })
    );
  };

  const menuItems = [
    isCurrentUsersComment && !comment.is_flagged && !comment.deleted_at && { text: "edit", onClick: onEdit },
    { text: "share", onClick: handleShare },
    currentUser &&
      !isCurrentUsersComment &&
      !comment.is_flagged &&
      !comment.deleted_at && { text: "flag", onClick: handleFlag },
    isCurrentUsersComment && !comment.deleted_at && { text: "delete", onClick: handleDelete },
  ];

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3">
        <Votes
          handleUpvote={handleUpvote}
          handleDownvote={handleDownvote}
          userVote={comment.user_vote}
          upvotes={comment.upvotes}
        />
        <button className="text-xs font-bold text-gray" onClick={() => setShowReplyInput(!showReplyInput)}>
          Reply
        </button>
        <Menu items={menuItems} />
      </div>

      {showReplyInput && (
        <form className="flex gap-3 w-inherit mb-[2px]" onSubmit={createReply}>
          <textarea
            minLength="2"
            placeholder="Write a reply..."
            value={replyContent}
            onChange={onChangeReply}
            className="border py-2 text-base border-gray px-[10px] h-20 rounded-[20px] w-full focus:outline-none resize-none placeholder:text-gray text-offBlack"
            required
          />
          <button type="submit">
            <img src={ChevronRightCircled} alt="submit reply" />
          </button>
        </form>
      )}
    </div>
  );
};

export default CommentActions;
