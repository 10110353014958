import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BookCard from "./BookCard";
import ClubCard from "./ClubCard";
import QuestCard from "../quests/QuestCard";
import ListCard from "./ListCard";
import PostCard from "../forum/PostCard";
import ReviewCard from "../review/ReviewCard";
import YourReadingChallengeCard from "../yearChallenge/YourReadingChallengeCard";
import Button from "../helpers/Button";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import api from "../../utils/api";

const FeedCard = ({ activityItem }) => {
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(activityItem.is_liked);
  const [upvotes, setUpvotes] = useState(activityItem.upvotes);
  const [loadingLike, setLoadingLike] = useState(false);
  const { currentUser } = useSelector((state) => state.user);

  const ACTIVITY_TYPE_COLOR_MAP = {
    book_post: "bg-mainBlue",
    club_post: "bg-pastelBlue",
    joined_club: "bg-pastelPurple",
    created_club: "bg-pastelPurple",
    completed_quest: "bg-pastelPurple",
    joined_quest: "bg-pastelPurple",
    written_review: "bg-pastelPurple",
    non_written_review: "bg-pastelPurple",
    finished_book: "bg-pastelPurple",
    dnf_book: "bg-pastelPurple",
    tbr_book: "bg-pastelPurple",
    start_book: "bg-pastelPurple",
    completed_yearly_goal: "bg-pastelPurple",
    set_yearly_goal: "bg-pastelPurple",
    global_announcement: "bg-pastelPink",
    global_item: "bg-pastelPink",
    created_list: "bg-pastelPurple",
  };

  const LIKEABLE_ACTIVITY_TYPES = [
    "finished_book",
    "dnf_book",
    "tbr_book",
    "start_book",
    "completed_yearly_goal",
    "set_yearly_goal",
    "joined_quest",
    "completed_quest",
    "joined_club",
    "created_club",
  ];

  const ONBOARDING_ACTIVITY_TYPE_MAP = {
    join_club: {
      color: "bg-pastelBlue",
      title: "Join more Clubs!",
      description:
        "Like book clubs or subreddits, Clubs are the ideal way to find your community and get curated recommendations.",
      cta: "Discover Clubs",
      link: "/discover/clubs",
    },
    join_quest: {
      color: "bg-pastelOrange",
      title: "You haven't joined a Quest yet!",
      description: "A Quest is a themed reading challenge. Earn points for completion. 🏆",
      cta: "Discover Quests",
      link: "/discover/quests",
    },
    create_list: {
      color: "bg-gradient-to-b from-pastelBlue to-pastelPink",
      title: "You haven't created a List yet...",
      description: "Are you ready to be a tastemaker? Curate a collection of books and earn votes from the community.",
      cta: "Create a List",
      link: "/lists",
    },
    follow_users: {
      color: "bg-pastelPurple",
      title: "You're not following anyone yet!",
      description: "Check out reader profiles and make friends based on shared taste.",
      cta: "Discover Users",
      link: "/discover/people",
    },
    set_up_year_goal: {
      color: "bg-gradient-to-b from-pastelOrange to-pastelGreen",
      title: "Start your Yearly Reading Challenge",
      description: "Set your goal for the year, and keep track of the books you've finished.",
      cta: "Take me there",
      link: "/reading-challenge",
    },
  };

  const OnboardingActivityCard = ({ type }) => {
    return (
      <div
        className={`${ONBOARDING_ACTIVITY_TYPE_MAP[type].color} w-full max-w-[900px] flex flex-col md:flex-row justify-between items-center rounded-default p-6 border border-offBlack shadow-main `}
      >
        <div className="flex flex-col gap-3 text-left pr-5">
          <p className="font-bold">{ONBOARDING_ACTIVITY_TYPE_MAP[type].title}</p>
          <p>{ONBOARDING_ACTIVITY_TYPE_MAP[type].description}</p>
        </div>
        <div className="self-end md:self-center flex-none mt-3 md:mt-0">
          <Button
            type="link"
            text={ONBOARDING_ACTIVITY_TYPE_MAP[type].cta}
            to={ONBOARDING_ACTIVITY_TYPE_MAP[type].link}
            color="bg-fuschia"
          />
        </div>
      </div>
    );
  };

  const renderCard = () => {
    switch (activityItem.resource_type) {
      case "Post":
        return (
          <PostCard postData={activityItem.resource} onClick={() => navigate(`/posts/${activityItem.resource.id}`)} />
        );
      case "Book":
        return <BookCard book={activityItem.resource} variant="large" />;
      case "Club":
        return <ClubCard club={activityItem.resource} />;
      case "Challenge":
        return <QuestCard quest={activityItem.resource} />;
      case "List":
        return <ListCard listData={activityItem.resource} />;
      case "Review":
        return <ReviewCard review={activityItem.resource} variant="with-image" />;
      case "YearlyGoal":
        return <YourReadingChallengeCard user={activityItem.resource.user} variant="feed" />;
      default:
        return <p>{activityItem.resource_type}</p>;
    }
  };

  if (activityItem.action_type in ONBOARDING_ACTIVITY_TYPE_MAP) {
    return <OnboardingActivityCard type={activityItem.action_type} />;
  }

  const renderTitle = () => {
    if (activityItem.username) {
      const truncatedTitle = activityItem.title.split(" ").slice(1).join(" ");
      return (
        <p className="font-bold">
          <Link to={`/users/${activityItem.username}`}>{activityItem.username}</Link> {truncatedTitle}
        </p>
      );
    }
    return <p className="font-bold">{activityItem.title}</p>;
  };

  const handleLike = () => {
    setLoadingLike(true);
    api.post(`/feed/like`, { id: activityItem.id });
    setIsLiked(true);
    setUpvotes(upvotes + 1);
    setLoadingLike(false);
  };

  const handleRemoveLike = () => {
    setLoadingLike(true);
    api.post(`/feed/remove_like`, { id: activityItem.id });
    setIsLiked(false);
    setUpvotes(upvotes - 1);
    setLoadingLike(false);
  };

  const renderLikeButton = () => {
    return (
      <div className="flex gap-1 self-start items-center justify-center rounded-default">
        <button
          className="flex justify-start"
          onClick={isLiked ? handleRemoveLike : handleLike}
          disabled={loadingLike || activityItem.user_id === currentUser.id}
        >
          {isLiked ? (
            <FavoriteOutlinedIcon color="#FF695F" fontSize="small" className="text-red" />
          ) : (
            <FavoriteBorderOutlinedIcon
              color="#D2D2D2"
              fontSize="small"
              className={`${activityItem.user_id !== currentUser.id && "hover:text-red"}`}
            />
          )}
        </button>
        <div className="text-xs text-center">{upvotes}</div>
      </div>
    );
  };

  return (
    <div
      className={`${
        ACTIVITY_TYPE_COLOR_MAP[activityItem.action_type]
      } w-full max-w-[900px] rounded-default border border-offBlack text-left shadow-main flex flex-col p-4 gap-2`}
    >
      {renderTitle()}
      {renderCard()}
      {LIKEABLE_ACTIVITY_TYPES.includes(activityItem.action_type) && (
        <div className="flex gap-6 justify-start mt-1 ml-1">
          {renderLikeButton()}
          {/* <div className="flex items-center justify-center text-xs rounded-default gap-1">
            <img src={comment} alt="chat bubble" />
            {activityItem.comment_count}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default FeedCard;
