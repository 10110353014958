import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import QuestCard from "./QuestCard";
import Button from "../helpers/Button";
import Squiggle from "../../assets/SquiggleIcon";
import LoginPrompt from "../helpers/LoginPrompt";
import Loader from "../helpers/Loader";
import HeadTags from "../helpers/HeadTags";

const QuestsHome = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [joinedQuests, setJoinedQuests] = useState([]);
  const [joinedQuestsLoading, setJoinedQuestsLoading] = useState(false);
  const [newQuests, setNewQuests] = useState([]);

  useEffect(() => {
    setJoinedQuestsLoading(true);
    api
      .get(`/challenges/joined`)
      .then((data) => setJoinedQuests(data))
      .finally(() => setJoinedQuestsLoading(false));
    api.get(`/challenges?q=new`).then((data) => setNewQuests(data));
  }, []);

  const renderInProgressQuests = () => {
    if (joinedQuestsLoading) return <Loader />;
    if (!currentUser) return <LoginPrompt />;
    if (joinedQuests.length > 0)
      return (
        <div className="flex flex-wrap gap-8 w-full justify-start">
          {joinedQuests.map((quest) => (
            <QuestCard key={quest.id} quest={quest} variant={window.innerWidth >= 768 ? "small" : "large"} />
          ))}
        </div>
      );
    return (
      <div className="w-full bg-lightPurple flex flex-col md:flex-row justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
        <div className="flex flex-col gap-3 text-left pr-5">
          <p className="font-bold">You haven't joined any Quests yet!</p>
          <p>Don't be shy - browse and join a Quest. Once you do, you can find all your Quests here.</p>
        </div>
        <Button type="link" to="/discover/quests" text="Discover Quests" color="bg-fuschia self-end md:self-auto" />
      </div>
    );
  };

  return (
    <div className="container mx-auto m-auto pt-8 md:pt-[4em] pb-[60px]">
      <HeadTags title="Quests" description="Themed challenges of 10 books or less that you earn points for completing" />
      <header className="flex flex-col w-[80%] m-auto gap-5 md:gap-6 mb-8 md:mb-16 items-center">
        <h1>Quests</h1>
        <h3>
          A themed reading challenge of 10 books or less. <br />
          Complete Quests to earn points.
        </h3>
        <div className="hidden md:block">
          <Squiggle width="340" height="37" />
        </div>
        <div className="md:hidden">
          <Squiggle width="250" height="26" />
        </div>
      </header>

      <div className="container flex flex-col w-10/12 lg:w-full max-w-[900px] mx-auto m-auto gap-8 md:gap-12 items-start mb-[100px]">
        <div className="w-full text-left">
          <h2 className="mb-5 md:mb-6">Quests You've Joined</h2>
          {renderInProgressQuests()}
        </div>
        <div className="w-full text-left flex flex-col gap-5 md:gap-6">
          <div className="flex gap-8 items-center">
            <h2>Discover New Quests</h2>
            <Button type="link" to="/quests/browse" text="Browse all" color="bg-fuschia" />
          </div>

          <div className="flex flex-col gap-4">
            {newQuests.map((quest) => (
              <QuestCard key={quest.id} quest={quest} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestsHome;
