import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TableRow, TableCell, Rating, Tooltip } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import BookCover from "../../helpers/BookCover";
import BookStatusButton from "../../helpers/BookStatusButton";
import { openModal } from "../../../app/slices/globalUISlice";
import api from "../../../utils/api";
import SaveIcon from "../../../assets/SaveIcon";
import { BOOK_STATUS_BG_COLOR } from "../../../constants";
import { formatDate } from "../../../utils/utils";

const LibraryBookCard = ({ userBook, icon, isOtherUser, view = "card" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userBookData, setUserBookData] = useState(userBook);
  const [isSaved, setIsSaved] = useState(userBook?.is_bookmarked);
  const [isLoading, setIsLoading] = useState(false);
  const bookId = userBook?.book_id;
  const { currentUser } = useSelector((state) => state.user);

  const onClickReview = () => {
    if (isOtherUser && !userBook.review) {
      return;
    }

    const handleReviewSubmit = ({ newReview }) => {
      setUserBookData({ ...userBookData, ...{ review: newReview } });
    };
    const data = { userBook: userBookData, handleReviewSubmit, isOtherUser };
    dispatch(openModal({ name: "newReview", data }));
  };

  if (!userBookData) {
    return null;
  }

  const handleIconClick = async (event) => {
    event.stopPropagation();
    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: "Book", id: userBook.book_id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setIsSaved(!isSaved);
      });
    }
  };

  if (view === "card")
    return (
      <div
        className={`relative flex md:flex-col md:gap-0 gap-5 w-full max-w-80 md:h-[250px] md:w-[180px] box-border p-4 md:px-6 md:py-6 md:justify-between ${
          BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
        } md:items-center shadow-main rounded-default border border-offBlack`}
        key={userBook.uuid}
      >
        <div className="absolute top-3 md:top-4 right-2 md:right-[10px] flex flex-col justify-center items-center gap-1">
          {icon}
          {isOtherUser && (
            <button className="p-1 rounded-full hover:bg-yellow" onClick={handleIconClick} disabled={isLoading}>
              <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
            </button>
          )}
          <BookStatusButton userBook={userBookData} setUserBookData={setUserBookData} bookId={bookId} />
        </div>
        
        <a
          href={`/books/${userBook.book_uuid}`}
          className="hidden md:block w-[104px] h-40 cursor-pointer hover:text-black"
        >
          <BookCover book={userBook} showTitle={true} size="w-[104px] h-40 border shadow-main" clickable={false} />
        </a>

        <a href={`/books/${userBook.book_uuid}`} className="md:hidden w-[62px] h-24 cursor-pointer hover:text-black">
          <BookCover book={userBook} size="w-[62px] h-24 border shadow-main" clickable={false} />
        </a>

        <div className="flex flex-col justify-between">
          <div className="block md:hidden pl-1 pr-3">
            <button onClick={() => navigate(`/books/${userBook.book_uuid}`)} className="flex flex-col text-left">
              <b className="leading-tight text-ellipsis line-clamp-3">{userBook.title}</b>
              <p className="text-[11px]">by {userBook.author_name}</p>
            </button>
          </div>

          <Tooltip title={isOtherUser && "Their Rating"}>
            <button
              onClick={onClickReview}
              className={`w-[106px] h-[20px] ${
                isOtherUser && !userBook.review ? "cursor-default" : "cursor-pointer"
              }`}
            >
              <Rating
                value={Number(userBookData?.review?.overall_rating || 0)}
                precision={0.5}
                size="small"
                readOnly
                icon={<StarRoundedIcon className={`stroke-offBlack ${isOtherUser ? "text-red" : "text-yellow"}`} fontSize="small" />}
                emptyIcon={
                  <StarRoundedIcon
                    className={`stroke-offBlack ${
                      !isOtherUser && (userBookData?.status === "finished" || userBookData?.status === "dnf")
                        ? "text-white"
                        : "text-transparent"
                    }`}
                    fontSize="small"
                  />
                }
              />
            </button>
          </Tooltip>
        </div>
      </div>
    );

  if (view === "row")
    return (
      <TableRow key={userBookData.uuid}>
        <TableCell>
          <BookCover
            book={{ image_url: userBook.image_url, uuid: userBook.book_uuid }}
            clickable
            size="w-[65px] h-[100px] border shadow-main"
          />
        </TableCell>
        <TableCell>
          <Link to={`/books/${userBook.book_uuid}`}>{userBookData.title}</Link>
        </TableCell>
        <TableCell>
          <Link to={`/authors/${userBook.author_uuid}`}>{userBookData.author_name}</Link>
        </TableCell>
        <TableCell>
          <button
            onClick={onClickReview}
            className={`flex flex-col w-[106px] items-center ${isOtherUser && !userBook.review ? "cursor-default" : "cursor-pointer"}`}
          >
            <Rating
              value={Number(userBookData?.review?.overall_rating || 0)}
              precision={0.5}
              size="small"
              readOnly
              icon={<StarRoundedIcon className={`stroke-offBlack ${isOtherUser ? "text-red" : "text-yellow"}`} fontSize="small" />}
              emptyIcon={
                <StarRoundedIcon
                  className={`stroke-offBlack ${
                    !isOtherUser && (userBookData?.status === "finished" || userBookData?.status === "dnf")
                      ? "text-white"
                      : "text-transparent"
                  }`}
                  fontSize="small"
                />
              }
            />
          </button>
        </TableCell>
        {!isOtherUser && (
          <>
            <TableCell align="center">
              {userBookData.dates_read.map((d) => formatDate(d)).join(", ")}
            </TableCell>
            <TableCell align="center">
              {formatDate(userBookData.date_added)}
            </TableCell>
          </>
        )}
        <TableCell>
          <div className="flex flex-col gap-2">
            {icon || <BookStatusButton userBook={userBookData} setUserBookData={setUserBookData} bookId={bookId} />}
          </div>
        </TableCell>
      </TableRow>
    );
};

export default LibraryBookCard;
