import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import App from "./App.jsx";
import { store, persistor } from "./app/store";
import { injectStore } from './utils/api';
import './index.css';

injectStore(store);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <h1>Something went wrong.</h1>
          {process.env.NODE_ENV !== "production" && <div>{this.state.error.toString()}</div>}
        </>
      );
    }

    return this.props.children;
  }
}

if (process.env.NODE_ENV !== "development") {
  datadogRum.init({
    applicationId: '8075d767-dc1f-4789-96d0-4a48ff18ed7b',
    clientToken: 'pubbe9847e787a059fcea0fa2fcfc22670d',
    site: 'datadoghq.com',
    service: 'pagebound-client',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    applicationId: '8075d767-dc1f-4789-96d0-4a48ff18ed7b',
    clientToken: 'pubbe9847e787a059fcea0fa2fcfc22670d',
    site: 'datadoghq.com',
    service: 'pagebound-client',
    env: process.env.NODE_ENV,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
