import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RecommendationCard from "../cards/RecommendationCard";
import AddBookCard from "../cards/AddBookCard";
import Button from "../helpers/Button";
import api from "../../utils/api";
import { openModal, openSnackbar } from "../../app/slices/globalUISlice";
import styles from "../../styles/App.module.scss";
import Loader from "../helpers/Loader";
import CommunityCard from "../cards/CommunityCard";

const BookPageRecommendations = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const { currentBook, bookDataLoading } = useSelector((state) => state.book);
  const [communities, setCommunities] = useState([]);
  const [recommendations, setRecommendations] = useState(currentBook?.recommendations || []);
  const scrollableBookDivRef = useRef();
  const scrollableQuestDivRef = useRef();

  useEffect(() => {
    const clubs = currentBook.clubs;
    const lists = currentBook.lists;
    const quests = currentBook.quests;
    setCommunities(
      [clubs[0], lists[0], quests[0], clubs[1], lists[1], quests[1], clubs[2], lists[2], quests[2]].filter(Boolean)
    );
  }, [currentBook]);

  const onClickAddBook = () => {
    if (!currentUser) {
      dispatch(openModal("loginPrompt"));
      return;
    }

    const addRecommendation = (recommended_book) => {
      const params = { book_id: currentBook.id, recommended_book_id: recommended_book.id };
      api
        .post(`/recommendations`, { recommendation: params })
        .then((data) => setRecommendations([...recommendations, data]))
        .catch((error) => {
          console.error("Error creating recommendation:", error?.message);
          if (error.response.status === 422) {
            dispatch(openSnackbar({ message: "Recommendation already added!", error: error }));
          }
        });
    };
    const addedBookIds = recommendations.map((r) => r.recommended_book.id);
    dispatch(openModal({ name: "searchReturnBook", data: { handleClick: addRecommendation, addedBookIds } }));
  };

  if (!currentBook || bookDataLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2 h-fit my-3">
        <div className="flex gap-6 items-center mb-2">
          <b>Community recs for similar books</b>
          {currentBook?.recommendations.length >= 3 && (
            <div className="flex justify-center items-center">
              <Button color="bg-fuschia" text="Show all" onClick={() => navigate(`/books/${uuid}/recommendations`)} />
            </div>
          )}
        </div>
        <div
          ref={scrollableBookDivRef}
          className={`flex gap-3 md:gap-6 px-2 md:px-2.5 pb-1 overflow-x-scroll md:w-[500px] lg:w-[660px] xl:w-[900px] ${styles.customScrollbar}`}
        >
          {recommendations.map((recommendation) => (
            <RecommendationCard recommendation={recommendation} key={recommendation.id} />
          ))}
          <div className="flex" key="add-book-card-container">
            <AddBookCard onClick={onClickAddBook} key="add-book-card" variant="vertical" />
          </div>
        </div>
      </div>

      {communities.length > 0 && (
        <div className="flex flex-col gap-2 h-fit mb-10 md:mb-16">
          <div className="flex gap-6 items-center mb-2">
            <b>Find this book in...</b>
            {communities.length > 3 && (
              <div className="flex justify-center items-center">
                <Button color="bg-fuschia" text="Show all" onClick={() => navigate(`/books/${uuid}/lists`)} />
              </div>
            )}
          </div>
          <div
            ref={scrollableQuestDivRef}
            className={`flex gap-3 md:gap-6 md:px-2.5 pb-1 overflow-x-scroll md:w-[500px] lg:w-[660px] xl:w-[900px] ${styles.customScrollbar}`}
          >
            {communities.slice(0, 3).map((community) => (
              <CommunityCard item={community} key={community.uuid} type={community.class_name} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookPageRecommendations;
