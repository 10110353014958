import React from "react";
import Button from "../helpers/Button.jsx";
import LoginPrompt from "../helpers/LoginPrompt.jsx";

const Layout = ({ title, description, ctas, showUnauth, children }) => {
  return (
    <div className="container mx-auto m-auto pt-8 md:pt-[4em] pb-[50px]">
      <header className="flex flex-col items-center m-auto gap-5 md:gap-6 mb-8 md:mb-16">
        <h1>{title}</h1>
        {description && <h3 className="w-[80%] md:w-[60%] whitespace-pre-wrap">{description}</h3>}
        {ctas && (
          <div className="flex flex-row gap-3 md:gap-5 m-auto">
            {ctas?.map((cta) => (
              <Button type="navigation" key={cta.text} {...cta} />
            ))}
          </div>
        )}
      </header>

      {showUnauth ? <LoginPrompt /> : children}
    </div>
  );
};

export default Layout;
