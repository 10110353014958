import React, { useEffect, useState, useRef } from "react";
import Button from "../helpers/Button";
import api from "../../utils/api";
import QuestCard from "../quests/QuestCard";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

const DiscoverQuests = () => {
  const [popularQuests, setPopularQuests] = useState([]);
  const [newQuests, setNewQuests] = useState([]);
  const [loadingPopular, setLoadingPopular] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [morePopular, setMorePopular] = useState(true);
  const [moreNew, setMoreNew] = useState(true);
  const newPage = useRef(1);
  const popularPage = useRef(1);

  useEffect(() => {
    api.get(`/challenges?q=popular`).then((data) => {
      setPopularQuests(data);
      if (data.length < 5) setMorePopular(false);
    });
    api.get(`/challenges?q=new`).then((data) => {
      setNewQuests(data);
      if (data.length < 5) setMoreNew(false);
    });

    return () => {
      newPage.current = 1;
      popularPage.current = 1;
    };
  }, []);

  const fetchMorePopular = () => {
    setLoadingPopular(true);
    popularPage.current += 1;
    api
      .get(`/challenges?q=popular&page=${popularPage.current}`)
      .then((data) => {
        setPopularQuests([...popularQuests, ...data]);
        if (data.length < 5) setMorePopular(false);
      })
      .catch()
      .finally(() => setLoadingPopular(false));
  };

  const fetchMoreNew = () => {
    setLoadingNew(true);
    newPage.current += 1;
    api
      .get(`/challenges?q=new&page=${newPage.current}`)
      .then((data) => {
        setNewQuests([...newQuests, ...data]);
        if (data.length < 5) setMoreNew(false);
      })
      .catch()
      .finally(() => setLoadingNew(false));
  };

  return (
    <div className="container flex flex-col max-w-[900px] mx-auto m-auto gap-8 md:gap-10 items-start mb-[100px]">
      <div className="w-full text-left flex flex-col gap-5 md:gap-6">
        <div className="flex gap-8 items-center">
          <h2>Popular Quests</h2>
          <Button type="link" to="/quests/browse" text="Browse all" color="bg-fuschia" />
        </div>
        <div className="flex flex-col gap-3">
          {popularQuests.map((quest) => (
            <QuestCard key={quest.id} quest={quest} />
          ))}
          {morePopular && (
            <button
              className="bg-transparent p-2 self-center mt-2"
              disabled={loadingPopular}
              onClick={fetchMorePopular}
            >
              {loadingPopular ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>
      </div>

      <div className="w-full text-left flex flex-col gap-5 md:gap-6">
        <h2>New Quests</h2>
        <div className="flex flex-col gap-3">
          {newQuests.map((quest) => (
            <QuestCard key={quest.id} quest={quest} />
          ))}
          {moreNew && (
            <button className="bg-transparent p-2 self-center mt-2" disabled={loadingNew} onClick={fetchMoreNew}>
              {loadingNew ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscoverQuests;
