import React from "react";
import clsx from "clsx";

const Checkbox = ({ id, isChecked, onChange, text }) => {
  return (
    <label className="flex items-center cursor-pointer gap-2">
      <input id={id} type="checkbox" className="hidden" checked={isChecked} onChange={onChange} />
      <div
        className={clsx(
          "flex flex-none items-center justify-center border-offBlack border shadow-small cursor-pointer w-[18px] h-[18px] rounded-full",
          isChecked ? "bg-mainGreen" : "bg-white"
        )}
      >
        {isChecked && <span className="font-bold text-xs font-sans">✓</span>}
      </div>
      <p className={isChecked ? "text-mainGreen leading-none" : "text-white leading-none"}>{text}</p>
    </label>
  );
};

export default Checkbox;
