import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AVATARS } from "../../../constants";
import x from "../../../assets/graphic-x.svg";
import Switch from "../../helpers/Switch";
import * as Yup from "yup";
import { updateUserProfile } from "../../../app/slices/userSlice";
import { Formik, Field, Form, useField, ErrorMessage } from "formik";

const EditProfile = ({ setIsEditing }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [openAvatarSelector, setOpenAvatarSelector] = useState(false);

  const AvatarSelector = ({ name, values }) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    const AvatarModal = () => {
      const handleSelectAvatar = (avatar) => {
        setValue(avatar);
        setOpenAvatarSelector(false);
      };

      return (
        <div
          className="fixed z-20 inset-0 overflow-y-auto bg-offBlack bg-opacity-35 flex items-center justify-center"
          onClick={() => setOpenAvatarSelector(false)}
        >
          <div
            className="relative bg-white w-fit overflow-y-scroll overflow-hidden border box-border max-h-[500px] border-offBlack shadow-main rounded-[20px]"
            onClick={(e) => e.stopPropagation()}
          >
            <button onClick={() => setOpenAvatarSelector(false)} className="absolute top-4 right-4">
              <img src={x} alt="close modal" className="h-5 w-5" />
            </button>
            <div className="p-7">
              <div className="grid grid-cols-4 gap-3 mt-3">
                {Object.entries(AVATARS).map(([key, value]) => (
                  <button onClick={() => handleSelectAvatar(value)} key={key}>
                    <img src={value} className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] border rounded-full" />
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="flex gap-4 items-center">
        <div>Select Avatar</div>
        {openAvatarSelector && <AvatarModal />}
        <button onClick={() => setOpenAvatarSelector(true)}>
          <div className="relative w-[80px] h-[80px]">
            <div
              className={`w-full h-full rounded-full absolute border border-offBlack ${
                values.open_to_friends && "bg-mainGreen shadow-main left-1 top-1 "
              }`}
            />
            {value && <img src={value} className="w-full h-full rounded-full border border-offBlack absolute" />}
          </div>
        </button>
      </div>
    );
  };

  const UsernameField = (props) => {
    const [field, meta] = useField(props);
    const [didFocus, setDidFocus] = React.useState(false);
    const handleFocus = () => setDidFocus(true);
    const showFeedback = (!!didFocus && field.value.trim().length > 2) || meta.touched;

    return (
      <div className="flex flex-col gap-2 w-full items-start">
        <div className="flex items-center space-between">
          <label htmlFor="username">Username</label>{" "}
          {showFeedback ? (
            <div
              id="username-feedback"
              aria-live="polite"
              className={`feedback text-sm ml-2 ${meta.error && "text-red"}`}
            >
              {meta.error ? meta.error : "✓"}
            </div>
          ) : null}
        </div>
        <input
          {...props}
          {...field}
          placeholder="spacebunny99"
          className="w-full ml-0 mr-5 px-4 py-3 leading-tight my-auto"
          aria-describedby="username-feedback username-help"
          onFocus={handleFocus}
        />
      </div>
    );
  };

  const OpenToFriendsSwitch = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
      <div className="flex">
        <label className="mr-4" htmlFor="open_to_friends">
          Open to making new friends?
        </label>
        <Switch id="open_to_friends" isChecked={value} onChange={() => setValue(!value)} />
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    dispatch(updateUserProfile(values))
      .unwrap()
      .then((payload) => {
        setSubmitting(false);
        setIsEditing(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus({
          error: error,
        });
      });
  };

  const profileSchema = Yup.object().shape({
    username: Yup.string()
      .required("Required")
      .min(5, "Must be at least 5 characters")
      .max(20, "Must be less than 20 characters")
      .matches(/^[a-zA-Z0-9._-]+$/, "Cannot contain special characters or spaces")
      .test(
        "not-starting-ending-dot-dash-underscore",
        "Must start and end with letter or number",
        (value) => !/^[._-]|[._-]$/.test(value)
      ),
    bio: Yup.string().max(200, "Max length reached"),
    open_to_friends: Yup.boolean().required("Required"),
    image_url: Yup.string().required("Required"),
  });

  return (
    <Formik
      validationSchema={profileSchema}
      validateOnMount={true}
      validateOnChange={true}
      onSubmit={handleSubmit}
      initialValues={{
        username: currentUser.username,
        bio: currentUser.bio,
        open_to_friends: currentUser.open_to_friends,
        image_url: currentUser.image_url,
      }}
    >
      {({ errors, values, isSubmitting, isValid, status }) => (
        <Form
          onKeyDown={handleKeyDown}
          className="w-11/12 lg:w-[700px] lg:pl-[40px] m-auto flex flex-col items-start justify-center gap-6"
        >
          <header className="flex items-center gap-3">
            <h2>Edit your profile</h2>
            <button
              type="submit"
              className="bg-neonBlue ml-3 shadow-main min-w-[60px] h-fit whitespace-nowrap font-bold leading-none font-space text-[11px] md:text-[13px] rounded-[50px] px-4 py-1.5 md:py-[.5em] cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray"
              disabled={!isValid || isSubmitting}
            >
              Update
            </button>
          </header>

          <AvatarSelector name="image_url" values={values} />
          <div className="flex flex-col gap-3 w-[350px] items-start">
            <UsernameField name="username" />
          </div>
          <div className="flex flex-col gap-2 w-full items-start">
            <label htmlFor="bio">Bio</label>
            <Field
              id="bio"
              name="bio"
              as="textarea"
              placeholder="max 200 characters"
              className="w-full md:w-[600px] ml-0 mr-5 px-4 py-3 leading-tight my-auto border border-offBlack rounded-default"
            />
            <ErrorMessage name="bio" render={(msg) => <div className="text-red leading-none">{msg}</div>} />
          </div>
          <OpenToFriendsSwitch name="open_to_friends" />
          <div className="w-full flex justify-end items-center">
            {status && status.error && <div className="text-red leading-none mr-4">{status.error}</div>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditProfile;
