import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import pluralize from "pluralize";
import Button from "../../helpers/Button";
import AllClubBooks from "./AllClubBooks";
import EditClub from "./EditClub";
import Forum from "../../forum/Forum";
import { addClubBook, removeClubBook } from "../../../app/slices/clubSlice";
import EditIcon from "@mui/icons-material/Edit";
import BookCard from "../../cards/BookCard";
import AddBookCard from "../../cards/AddBookCard";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CLUB_THEMES } from "../../../constants";
import { openModal, openSnackbar } from "../../../app/slices/globalUISlice";
import shareIcon from "../../../assets/shareIcon.svg";
import Tag from "../../helpers/Tag";
import IconButton from "../../helpers/IconButton";

const ClubModeratorView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentClub } = useSelector((state) => state.club);
  const { showcased_books } = useSelector((state) => state.club.currentClub);
  const [isEditingAboutUs, setIsEditingAboutUs] = useState(false);
  const [isEditingShowcase, setIsEditingShowcase] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const view = query.get("view") || "about-us"; // Default to 'about-us' if no view is set

  const handleViewChange = (newView) => {
    query.set("view", newView);
    navigate({ search: query.toString() });
  };

  const onClickAddBookCard = () => {
    const handleClick = (book) => {
      if (showcased_books.length < 10) {
        dispatch(
          addClubBook({
            book_id: book.id,
            showcase: true,
            club_uuid: currentClub.uuid,
          })
        );
      }
    };
    const addedBookIds = showcased_books.map((b) => b.id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick } }));
  };

  const removeBookIcon = (book) => {
    return (
      <button
        type="button"
        onClick={() =>
          dispatch(
            removeClubBook({
              book_id: book.id,
              club_uuid: currentClub.uuid,
            })
          )
        }
      >
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/clubs/${currentClub.uuid}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const AboutUsView = () => {
    const membersText = `${pluralize(currentClub.member_nickname || "members", currentClub.member_count, true)} joined`;
    const onViewMembers = () => {
      dispatch(openModal({ name: "viewClubMembers", data: { club: currentClub } }));
    };

    return (
      <div className="flex flex-col gap-8 md:gap-16 md:justify-start md:items-start">
        {!isEditingAboutUs && (
          <div className="flex flex-col gap-2 pl-4 md:pl-0 w-full">
            <div className="flex gap-3 md:gap-6 text-left items-center justify-between w-full">
              <h2>About Us</h2>
              <div className="flex gap-2">
                <IconButton
                  IconComponent={EditIcon}
                  onClick={() => setIsEditingAboutUs(true)}
                  tooltip="Edit"
                />
                <IconButton onClick={handleShare} tooltip="Share">
                  <img src={shareIcon} alt="share" className="w-[12px] h-[12px] md:w-[17px] md:h-[17px]" />
                </IconButton>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-start">
              <p>
                created by <Link to={`/users/${currentClub.creator_username}`}>{currentClub.creator_username}</Link>
              </p>
              <p className="hidden md:block">//</p>
              <div className="flex flex-row gap-2 cursor-pointer" onClick={onViewMembers}>
                <b className="hover:text-pink">{membersText}</b>
                {currentClub.pending_member_count > 0 && (
                  <Tag text={`${currentClub.pending_member_count} pending`} color="bg-red" />
                )}
              </div>
            </div>
            <div className="flex gap-2">
              {currentClub.emojis.map((emoji, index) => (
                <div className="text-[25px]" key={index}>
                  {emoji}
                </div>
              ))}
            </div>
          </div>
        )}
        {isEditingAboutUs && <EditClub setIsEditing={setIsEditingAboutUs} />}
        <div className="flex flex-col gap-3">
          <div className="flex gap-6 md:gap-8 pl-4 md:pl-0 items-start justify-start">
            <div className="flex gap-3 md:gap-6 items-center">
              <h2>Our Taste</h2>
              <div className="flex gap-4 items-center">
                {!isEditingShowcase && (
                  <IconButton
                    IconComponent={EditIcon}
                    onClick={() => setIsEditingShowcase(true)}
                    tooltip="Edit"
                  />
                )}
                {isEditingShowcase && (
                  <Button text="Save" onClick={() => setIsEditingShowcase(false)} color="bg-neonBlue" />
                )}
                <Button text="Add More Recs" onClick={() => handleViewChange("recommendations")} color="bg-fuschia" />
              </div>
            </div>
          </div>
          {currentClub.taste_description && <p className="pl-4 md:pl-0 text-left">{currentClub.taste_description}</p>}
          <div className="flex flex-wrap gap-6 mt-4 justify-center items-center md:justify-start">
            {showcased_books.map((book) => (
              <BookCard key={book.id} book={book} icon={isEditingShowcase ? removeBookIcon(book) : null} />
            ))}
            {isEditingShowcase && showcased_books.length < 10 && <AddBookCard onClick={onClickAddBookCard} />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <header
        className={`${CLUB_THEMES[currentClub.color]} w-screen flex items-center justify-center border-b px-4 md:px-0`}
      >
        <div className="flex flex-col my-8 md:my-12 gap-6 m-auto max-w-[650px] justify-center items-center">
          <h1 className="flex items-center gap-2">
            {currentClub.name}
            {currentClub.private && <LockOutlinedIcon className="text-offBlack" fontSize="inherit" />}
          </h1>
          <h3 className="leading-tight">{currentClub.description}</h3>
        </div>
      </header>
      <div className="container m-auto md:px-0 w-11/12 md:w-9/12 max-w-[1280px] box-border pb-[100px]">
        <div className="flex gap-6 items-center justify-center my-8 md:my-16">
          <Button
            type="navigation"
            text="About Us"
            isActive={view === "about-us"}
            onClick={() => handleViewChange("about-us")}
          />
          <Button
            type="navigation"
            text="Forum"
            isActive={view === "forum"}
            onClick={() => handleViewChange("forum")}
          />
          <Button
            type="navigation"
            text="Book Recs"
            isActive={view === "recommendations"}
            onClick={() => handleViewChange("recommendations")}
          />
        </div>

        {view === "about-us" && <AboutUsView />}
        {view === "recommendations" && <AllClubBooks />}
        {view === "forum" && (
          <div className="flex justify-center">
            <Forum resource={currentClub} resourceType="Club" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ClubModeratorView;
