import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Forum from "../forum/Forum";
import BookReviews from "../review/BookReviews";
import { fetchBook, fetchGoodreadsData } from "../../app/slices/bookSlice";
import { setCurrentUserBook } from "../../app/slices/userBookSlice";
import { openModal } from "../../app/slices/globalUISlice";
import amazonLogo from "../../assets/amazon.svg";
import bookshopLogo from "../../assets/bookshop.svg";
import goodreadsLogo from "../../assets/goodreads.svg";
import BookPageRecommendations from "./BookPageRecommendations";
import BookPageDetails from "./BookPageDetails";
import BookStatusButton from "../helpers/BookStatusButton";
import Button from "../helpers/Button";
import BookCover from "../helpers/BookCover";
import { debounce } from "../../utils/utils";
import api from "../../utils/api";
import SaveIcon from "../../assets/SaveIcon";
import Loader from "../helpers/Loader";
import BookPageSocialActivity from "./BookPageSocialActivity";
import HeadTags from "../helpers/HeadTags";

const BookPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentBook, error, bookDataLoading, goodreadsDataLoading } = useSelector((state) => state.book);
  const { currentUserBook: userBook } = useSelector((state) => state.userBook);
  const { currentUser } = useSelector((state) => state.user);
  const [userBookStatus, setUserBookStatus] = useState("set-status");
  const [isSaved, setIsSaved] = useState(currentBook?.is_bookmarked || false);
  const [showContent, setShowContent] = useState("bookRecs");
  const [isDescExpanded, setIsDescExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const reviewButtonRef = useRef(null);

  const setUserBookData = (userBook) => {
    dispatch(setCurrentUserBook(userBook));
  };

  const handleBookmark = () => {
    if (!currentUser) {
      dispatch(openModal("loginPrompt"));
      return;
    }

    const params = { type: "Book", id: currentBook.id };
    const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
    action.then(() => {
      setIsSaved(!isSaved);
    });
  };

  useEffect(() => {
    dispatch(fetchBook(uuid));
  }, [uuid, currentBook?.uuid]);

  useEffect(() => {
    const checkOverflow = () => {
      const el = textRef.current;
      if (el) setIsOverflowing(el.scrollHeight > el.clientHeight);
    };

    if (!isOverflowing) {
      debounce(checkOverflow, 1500)();
    }
  }, [currentBook?.description]);

  useEffect(() => {
    setIsSaved(currentBook?.is_bookmarked);
  }, [currentBook?.is_bookmarked]);

  useEffect(() => {
    setUserBookStatus(userBook?.status || "set-status");
  }, [userBook]);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.slice(1);
      if (elementId) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }, [location]);

  const handleScroll = (content) => {
    setShowContent(content);
    setTimeout(() => {
      if (reviewButtonRef.current) {
        const yOffset = -40;
        const y = reviewButtonRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 200);
  };

  const moreContentCtas = () => {
    if (showContent === "bookRecs") {
      return (
        <div className="flex gap-2 md:gap-4">
          <div className="w-full bg-mainBlue flex flex-col md:flex-row justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left md:pr-5">
              <p className="font-bold">Want to discuss?</p>
            </div>
            <Button onClick={() => handleScroll("forum")} text="See forum" color="bg-neonBlue" />
          </div>
          <div className="w-full bg-reviewContainer flex flex-col md:flex-row gap-2 md:justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left md:pr-5">
              <p className="font-bold">Looking for reviews?</p>
            </div>
            <Button onClick={() => handleScroll("reviews")} text="See reviews" color="bg-neonBlue" />
          </div>
        </div>
      );
    }

    if (showContent === "forum") {
      return (
        <div className="flex gap-2 md:gap-4">
          <div className="w-full bg-reviewContainer flex flex-col md:flex-row gap-2 md:justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left md:pr-5">
              <p className="font-bold">Looking for reviews?</p>
            </div>
            <Button onClick={() => handleScroll("reviews")} text="See reviews" color="bg-neonBlue" />
          </div>
          <div className="w-full bg-lightPurple flex flex-col md:flex-row gap-2 md:justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left md:pr-5">
              <p className="font-bold">Want more recs?</p>
            </div>
            <Button onClick={() => handleScroll("bookRecs")} text="See recs" color="bg-neonBlue" />
          </div>
        </div>
      );
    }

    if (showContent === "reviews") {
      return (
        <div className="flex gap-2 md:gap-4">
          <div className="w-full bg-mainBlue flex flex-col md:flex-row gap-2 md:justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left md:pr-5">
              <p className="font-bold">Want to discuss?</p>
            </div>
            <Button onClick={() => handleScroll("forum")} text="See forum" color="bg-neonBlue" />
          </div>
          <div className="w-full bg-lightPurple flex flex-col md:flex-row gap-2 md:justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main">
            <div className="flex flex-col gap-3 text-left md:pr-5">
              <p className="font-bold">Want more recs?</p>
            </div>
            <Button onClick={() => handleScroll("bookRecs")} text="See recs" color="bg-neonBlue" />
          </div>
        </div>
      );
    }
  };

  const canWriteReview = userBook?.has_ever_finished || userBookStatus === "dnf" || userBookStatus === "finished";

  if (error) {
    return <h3>Error loading book</h3>;
  } else if (!currentBook || bookDataLoading) {
    return <Loader variant="fullPage" />;
  }

  return (
    <>
      <div className="container relative flex flex-col box-border lg:flex-row gap-8 lg:gap-16 lg:mt-20 mb-[100px]">
      <HeadTags title={currentBook?.title} description={currentBook?.description} image={currentBook?.image_url} />
        <div className="flex px-4 pb-4 pt-8 lg:p-0 border-b bg-gradient-to-r from-pastelBlue to-pastelPink lg:border-none w-[100vw] lg:flex-col lg:fixed lg:top-[65px] lg:left-0 lg:pt-[80px] lg:h-[100vh] lg:w-[305px] lg:bg-bookpage-squiggle lg:bg-cover flex-none gap-4 lg:gap-8 justify-start items-start lg:items-center z-10">
          <div className="flex flex-col gap-4 items-center">
            <div className="w-[110px] h-[170px] lg:w-[207px] lg:h-80 flex-none">
              <BookCover
                book={currentBook}
                clickable={false}
                size="w-[110px] h-[170px] lg:w-[207px] lg:h-80 border shadow-main"
              />
            </div>
            {!currentBook.image_url && currentBook.goodreads_id && (
              <Button
                text="Fetch Bookcover"
                disabled={goodreadsDataLoading}
                onClick={() => dispatch(fetchGoodreadsData(currentBook.uuid))}
                color="bg-orange"
              />
            )}
          </div>
          <div className="lg:hidden flex flex-col gap-1 justify-center text-left">
            <BookPageDetails setShowContent={setShowContent} reviewButtonRef={reviewButtonRef} />
            <div className="lg:hidden flex flex-row gap-6">
              <BookStatusButton
                userBook={userBook}
                bookId={currentBook.id}
                variant="button"
                setUserBookData={setUserBookData}
              />

              <button onClick={handleBookmark}>
                <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="18" height="26" />
              </button>
            </div>
          </div>
          <div className="hidden lg:flex flex-col gap-8 items-center">
            <div className="flex flex-row gap-6">
              <BookStatusButton
                userBook={userBook}
                bookId={currentBook.id}
                variant="button"
                setUserBookData={setUserBookData}
              />
              <button onClick={handleBookmark}>
                <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} width="23" height="32" />
              </button>
            </div>
            {canWriteReview ? (
              <Button
                color="bg-fuschia"
                size="medium"
                text={userBook?.review ? "See Review" : "Write a review"}
                onClick={() => {
                  setShowContent("reviews");
                  if (reviewButtonRef.current) {
                    const yOffset = -40;
                    const y = reviewButtonRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: y, behavior: "smooth" });
                  }
                }}
              />
            ) : (
              <Tooltip title="You must finish or DNF this book to write a review" disableFocusListener>
                <div className="w-fit h-fit leading-none whitespace-nowrap text-sm px-4 py-1.5 md:px-6 md:py-2 font-bold font-space rounded-full cursor-pointer border bg-offWhite border-gray text-gray">
                  Write a review
                </div>
              </Tooltip>
            )}
          </div>

          <div className="hidden lg:flex flex-row gap-2 justify-center">
            <Link to={currentBook?.goodreads_url} target="_blank">
              <img src={goodreadsLogo} alt="goodreads" className="w-6 h-6" />
            </Link>
            <Link
              to={`https://bookshop.org/search?keywords=${currentBook?.title.split(" ").join("+")}`}
              target="_blank"
            >
              <img src={bookshopLogo} alt="bookshop" className="w-6 h-6" />
            </Link>
            <Link to={currentBook?.amazon_url || "https://www.amazon.com"} target="_blank">
              <img src={amazonLogo} alt="amazon" className="w-6 h-6" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col p-4 grow lg:ml-[335px] w-full max-w-[800px] justify-center lg:justify-start text-left z-0">
          <div className="flex flex-col gap-2.5">
            <div className="hidden lg:block relative">
              <BookPageDetails setShowContent={setShowContent} reviewButtonRef={reviewButtonRef} />
            </div>
            <div className="container mx-auto">
              <p
                ref={textRef}
                className={`overflow-hidden text-left text-[14px] leading-normal whitespace-pre-wrap ${
                  !isDescExpanded && "text-ellipsis line-clamp-4 md:line-clamp-5 overflow-hidden"
                }`}
              >
                {currentBook?.description}
              </p>
              {isOverflowing && currentBook?.description?.length > 0 && (
                <button
                  onClick={() => setIsDescExpanded(!isDescExpanded)}
                  className="text-gray text-xs md:text-sm mt-2"
                >
                  {isDescExpanded ? (
                    <>
                      Read Less <ExpandLessIcon fontSize="small" />
                    </>
                  ) : (
                    <>
                      Read More <ExpandMoreIcon fontSize="small" />
                    </>
                  )}
                </button>
              )}
              <BookPageSocialActivity />
            </div>
            <hr className="text-gray my-4 md:my-6" ref={reviewButtonRef} />
            <div className="flex flex-row gap-3 w-full justify-center">
              <Button
                type="navigation"
                text="Book Recs"
                onClick={() => setShowContent("bookRecs")}
                isActive={showContent === "bookRecs"}
              />
              <Button
                type="navigation"
                text="Forum"
                onClick={() => setShowContent("forum")}
                isActive={showContent === "forum"}
              />
              <Button
                type="navigation"
                text="Reviews"
                onClick={() => setShowContent("reviews")}
                isActive={showContent === "reviews"}
              />
            </div>
          </div>

          <div id="forum-reviews" className="flex flex-col mt-6 w-full max-w-[800px]">
            {showContent == "bookRecs" && <BookPageRecommendations />}
            {showContent == "forum" && <Forum resource={currentBook} resourceType="Book" />}
            {showContent == "reviews" && <BookReviews userBookStatus={userBookStatus} />}
          </div>
          <hr className="text-gray my-4 md:my-6" />
          {moreContentCtas()}
        </div>
      </div>
    </>
  );
};

export default BookPage;
