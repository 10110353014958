import React from "react";
import { useSelector } from "react-redux";
import AuthenticatedHome from "./AuthenticatedHome";
import UnauthenticatedHome from "./UnauthenticatedHome";
import { useDocumentTitle } from "../../utils/hooks";

const Home = () => {
  const { currentUser } = useSelector((state) => state.user);
  useDocumentTitle("Home");

  return (
    <div>{currentUser ? <AuthenticatedHome /> : <UnauthenticatedHome />}</div>
  );
};

export default Home;
