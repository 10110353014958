import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import { useSessionStorage } from "@uidotdev/usehooks";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { openModal } from "../../app/slices/globalUISlice";
import BookCard from "../cards/BookCard";
import AddBookCard from "../cards/AddBookCard";
import api from "../../utils/api";
import Tag from "../helpers/Tag";
import IconButton from "../helpers/IconButton";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";

const ListBooks = ({ list, isEditing }) => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [books, setBooks] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreBooks, setMoreBooks] = useState(false);
  const [showListView, setShowListView] = useSessionStorage("pagebound_listView", false);
  const [sortBy, setSortBy] = useState({ attribute: "added_at", isAscending: false });
  const pageRef = useRef(1);

  useEffect(() => {
    setLoadingMore(true);
    pageRef.current = 1;
    api
      .get(`/lists/${uuid}/books?page=1${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setBooks(data.books);
        setMoreBooks(data.total_pages > 1)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingMore(false));
  }, [list.uuid, sortBy]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    pageRef.current += 1;
    api
      .get(`/lists/${uuid}/books?page=${pageRef.current}${sortBy.attribute != null ? `&sort_by=${sortBy.attribute}&asc=${sortBy.isAscending}` : ''}`)
      .then((data) => {
        setBooks((prevBooks) => [...prevBooks, ...data.books]);
        setMoreBooks(data.total_pages > pageRef.current);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingMore(false));
  };

  const addBook = (book) => {
    api.post(`/lists/${uuid}/add_book`, { book_id: book.id }).then((res) => {
      setBooks((prevBooks) => [res, ...prevBooks]);
    });
  };

  const removeBookIcon = (book) => {
    return (
      <button
        type="button"
        onClick={() => {
          api.post(`/lists/${uuid}/remove_book`, { book_id: book.id }).then((res) => {
            setBooks(books.filter((b) => b.id !== book.id));
          });
        }}
      >
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const onClickAddBookCard = () => {
    const addedBookIds = books.map((b) => b.id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick: addBook } }));
  };

  const updateSorting = (col) => {
    if (sortBy.attribute === col) {
      setSortBy((prev) => ({ ...prev, isAscending: !prev.isAscending }));
    } else {
      setSortBy({ attribute: col, isAscending: true });
    };
  };

  const sortIcon = sortBy.isAscending
    ? <span className="relative"><ArrowDropUpRoundedIcon classes={{ root: "absolute -top-[7px] -left-[8px]" }} fontSize="large" color="primary" /></span>
    : <span  className="relative"><ArrowDropDownRoundedIcon classes={{ root: "absolute -top-[7px] -left-[8px]" }} fontSize="large" color="primary" /></span>;

  return (
    <div className="w-full flex flex-col gap-2 lg:ml-[440px] lg:pt-[100px] pb-[100px]">
      <div className="flex flex-row w-full justify-between px-4 lg:px-0">
        <div className="flex flex-row gap-2">
          <div>{pluralize("books", list.book_count, true)}</div>
          <Tag text={`${list.user_overlap_percent || 0}% overlap`} color="bg-yellow border-2 border-orange" />
        </div>
        <div>{/* TODO: search bar */}</div>
        <div className="hidden lg:flex flex-row gap-2">
          <IconButton
            IconComponent={GridViewRoundedIcon}
            onClick={() => setShowListView(false)}
            isActive={!showListView}
            tooltip="Showcase view"
          />
          <IconButton
            IconComponent={FormatListBulletedRoundedIcon}
            onClick={() => setShowListView(true)}
            isActive={showListView}
            tooltip="List view"
          />
        </div>
      </div>
      <div className="flex flex-col w-full flex-wrap gap-6 justify-center md:justify-start mt-3">
        {showListView ? (
          <Table>
            <TableHead classes={{ root: "whitespace-nowrap" }}>
              <TableRow>
                <TableCell classes={{ root: "w-[90px]" }}>Cover</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("title")}>
                  Title {sortBy.attribute === "title" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("author")}>
                  Author {sortBy.attribute === "author" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "w-[350px]" }}>Description</TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("avg_rating")}>
                  Avg Rating {sortBy.attribute === "avg_rating" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "cursor-pointer" }} onClick={() => updateSorting("added_at")}>
                  Added on {sortBy.attribute === "added_at" && sortIcon}
                </TableCell>
                <TableCell classes={{ root: "min-w-[24px]" }}>{/* status icon button */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEditing && (
                <TableRow>
                  <TableCell>
                    <div
                      className="flex justify-center items-center w-[65px] h-[100px] bg-white border shadow-main cursor-pointer"
                      onClick={onClickAddBookCard}
                    >
                      <AddCircleOutlineIcon fontSize="small" sx={{ color: "#333" }} />
                    </div>
                  </TableCell>
                  <TableCell>add book</TableCell>
                </TableRow>
              )}
              {books?.map((book) => (
                <BookCard key={book.id} book={book} variant="row" icon={isEditing ? removeBookIcon(book) : null} />
              ))}
            </TableBody>
          </Table>
        ) : (
          // <div className="w-full flex flex-col md:grid md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 mt-4 items-center justify-center">
          <div className="flex flex-wrap gap-6 justify-center md:justify-start">
            {currentUser?.id === list.user.id && <AddBookCard onClick={onClickAddBookCard} />}
            {books?.map((book) => (
              <BookCard key={book.id} book={book} icon={isEditing ? removeBookIcon(book) : null} />
            ))}
          </div>
        )}

        {moreBooks && (
          <button className="bg-transparent p-2 self-center mt-2" disabled={loadingMore} onClick={handleLoadMore}>
            {loadingMore ? (
              "Loading..."
            ) : (
              <>
                View More <ExpandMoreOutlinedIcon fontSize="small" />
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default ListBooks;
