import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import Menu from "./helpers/Menu.jsx";
import logo from "../assets/textLogo.svg";
import Squiggle from "../assets/Squiggle.jsx";
import Button from "./helpers/Button";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import Badge from "@mui/material/Badge";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { signInCallback, logout } from "../app/slices/userSlice.js";
import Autocomplete from "./helpers/Autocomplete.jsx";
import NotificationCard from "./notifications/NotificationCard.jsx";

const NavBar = ({ loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, unreadNotifications = [] } = useSelector((state) => state.user);

  const handleLogin = useGoogleLogin({
    onSuccess: (result) => signInCallback(result, dispatch),
    flow: "auth-code",
  });

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const navItems = [
    { text: "Library", path: "/library" },
    { text: "Lists", path: "/lists" },
    { text: "Clubs", path: "/clubs" },
    { text: "Quests", path: "/quests" },
    { text: "Discover", path: "/discover" },
  ];

  const mobileNavItems = [
    { text: "Library", path: "/library" },
    { text: "Lists", path: "/lists" },
    { text: "Clubs", path: "/clubs" },
    { text: "Quests", path: "/quests" },
    { text: "Discover", path: "/discover" },
    {
      text: (
        <>
          Notifications
          <Badge badgeContent={unreadNotifications?.length} classes={{ badge: "border bg-fuschia" }}>
            <span className="ml-5" />
          </Badge>
        </>
      ),
      path: "/notifications",
      requireLogin: true,
    },
    { text: "My Profile", path: "/profile", requireLogin: true },
  ];

  const profileMenuItems = [
    { text: "My Profile", path: "/profile" },
    { text: "Bookmarks", path: "/library/bookmarks" },
    { text: "Import Goodreads", path: "/goodreads-import" },
  ];

  return (
    <nav className="sticky top-0 bg-beige z-30 border-b-2 border-b-black w-screen">
      <ul className="relative h-[65px] flex lg:px-8 px-4 lg:gap-8 gap-3 text-md w-full items-center">
        {/* Logo, all users, mobile & desktop */}
        <li className="flex-none">
          <Link to="/" className="hidden lg:block">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to="/" className="lg:hidden">
            <Squiggle width="40 " height="44" />
          </Link>
        </li>

        {/* Search box, all users, mobile & desktop */}
        <li className="w-full lg:w-[350px] xl:w-[400px]">
          <Autocomplete />
        </li>

        {/* Desktop primary nav items (both unauthed and authed users) */}
        {navItems.map(({ text, path }) => (
          <li key={text} className="hidden lg:block">
            <Link className="font-bold" to={path}>
              {text}
            </Link>
          </li>
        ))}

        {/* Desktop authenticated user nav items (notifications and profile) */}
        <div className={`hidden ${loading ? "lg:hidden" : "lg:flex"} self-center ml-auto gap-4`}>
          {currentUser ? (
            <>
              <Menu
                IconComponent={
                  <Badge badgeContent={unreadNotifications?.length} classes={{ badge: "border bg-fuschia" }}>
                    <NotificationsRoundedIcon />
                  </Badge>
                }
                items={[
                  {
                    content: (
                      <div className="flex justify-between items-center mb-3">
                        <b>Notifications</b>
                        <Link to="/notifications" className="text-xs text-fuschia">
                          View all
                        </Link>
                      </div>
                    ),
                  },
                  unreadNotifications?.length == 0 && { content: "No new notifications", preventClose: true },
                  ...unreadNotifications?.map((notification) => ({
                    content: <NotificationCard notification={notification} variant="modal" />,
                  })),
                ]}
                classes={{
                  icon: "border shadow-main rounded-full w-[35px] h-[35px]",
                  menu: "w-[350px] py-4 px-6",
                }}
              />
              <Menu
                IconComponent={<PersonIcon />}
                items={[
                  ...profileMenuItems.map((i) => ({ text: i.text, onClick: () => navigate(i.path) })),
                  {
                    content: (
                      <div className="my-2 px-4">
                        <Button text="Log out" color="bg-neonBlue" onClick={handleLogout} />
                      </div>
                    ),
                  },
                ]}
                classes={{
                  icon: "border shadow-main rounded-full w-[35px] h-[35px]",
                  menu: "p-4",
                  item: "!text-base",
                }}
              />
            </>
          ) : (
            <Button onClick={handleLogin} text="Log in" color="bg-neonBlue" />
          )}
        </div>

        {/* Mobile nav items */}
        <Menu
          IconComponent={
            <Badge badgeContent={unreadNotifications?.length} classes={{ badge: "border bg-fuschia top-1.5 right-1" }}>
              <MenuOutlinedIcon fontSize="large" />
            </Badge>
          }
          items={[
            ...mobileNavItems.map(
              ({ text, path, requireLogin = false }) =>
                (!requireLogin || currentUser) && { text: text, onClick: () => navigate(path) }
            ),
            currentUser && {
              content: (
                <div className="my-4 px-6">
                  <Button text="Log out" color="bg-neonBlue" onClick={handleLogout} />
                </div>
              ),
            },
            !currentUser && {
              content: (
                <div className="my-4 px-6">
                  <Button text="Log in" color="bg-neonBlue" onClick={handleLogin} />
                </div>
              ),
            },
          ]}
          classes={{
            icon: "lg:hidden bg-transparent",
            menu: "px-6",
            item: "!text-base !font-bold !px-6 !my-2",
          }}
        />
      </ul>
    </nav>
  );
};

export default NavBar;
