import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/api";
import x from "../../../assets/graphic-x.svg";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import styles from "../../../styles/App.module.scss";
import Button from "../../helpers/Button";
import { openModal } from "../../../app/slices/globalUISlice";
import HeadTags from "../../helpers/HeadTags";

const GoodreadsImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadIcon = "/assets/Upload.svg";
  const [fileInput, setFileInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleOnFileSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", fileInput);

    setIsLoading(true);
    setError();
    api
      .postFile(`/goodreads/csv`, formData)
      .then(() => setSuccess(true))
      .catch((err) => setError(err?.message || true))
      .finally(() => setIsLoading(false));
  };

  if (success) {
    return (
      <div className="h-[80vh] m-auto flex flex-col gap-6 justify-center items-center">
        <h1>Success! 🎉</h1>
        <h3>Your Goodreads data has been imported to your Pagebound profile.</h3>
        <Button text="Go to my library" color="bg-fuschia" size="medium" onClick={() => navigate("/library")} />
      </div>
    );
  }

  return (
    <div className="container m-auto flex flex-col items-center w-fit px-3 pb-[100px]">
      <HeadTags title="Import Goodreads" description="Import your Goodreads data to your Pagebound profile" />
      {isLoading ? (
        <div className="flex flex-col text-left w-[300px] md:w-[400px]">
          <p className="mt-[50px] md:mt-[200px]">Importing your ratings and reviews...</p>
          <p className="mt-[30px]">Importing your books and shelves...</p>
          <h2 className="mt-[70px] mb-[100px]">Your profile will be updated after processing your Goodreads data...</h2>
          <div className="relative flex w-full justify-center items-center">
            <div className={styles.loadRow}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <header className="flex flex-col gap-4 text-left w-[300px] md:w-[600px]">
            <h2 className="mt-[50px] md:mt-[80px]">Import your Goodreads data to your Pagebound profile</h2>
            <p className="text-left md:text-center">
              Why? It'll make your experience seamless. We'll bring over your shelves, books, ratings, and reviews.{" "}
              <i> 80% of people import their Goodreads.</i>
            </p>
          </header>
          <div className="relative flex flex-col p-8 mt-8 w-[318px] rounded-default border shadow-main bg-pastelOrange items-center">
            <p className="font-bold">Upload your Goodreads data</p>
            <div
              className="text-base text-fuschia mt-3 mb-8 cursor-pointer"
              onClick={() => dispatch(openModal("goodreadsDirections"))}
            >
              Need help? View directions here
            </div>
            {!fileInput && (
              <div className="flex flex-col gap-3 w-[252px] h-[216px] bg-white border rounded-default items-center justify-center">
                <div
                  className="w-[63px] h-[44px] mb-8"
                  style={{
                    backgroundImage: `url(${uploadIcon})`,
                    backgroundSize: "100%",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <form>
                  <span id="file-selected">{fileInput?.name}</span>
                  <label
                    htmlFor="csvFileInput"
                    className="bg-neonBlue shadow-main min-w-[60px] h-fit whitespace-nowrap font-bold leading-none font-space text-[11px] md:text-[13px] rounded-[50px] px-4 py-1.5 md:py-[.5em] cursor-pointer border border-offBlack"
                  >
                    Choose file
                  </label>

                  <input
                    type={"file"}
                    className="hidden"
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={(e) => setFileInput(e.target.files[0])}
                  />
                </form>
              </div>
            )}
            {fileInput && (
              <div className="flex flex-col items-center">
                <div className="relative p-3 box-border flex w-[148px] h-[132px] items-center justify-center flex-col bg-white border rounded-default gap-2">
                  <button className="absolute top-2 right-2" onClick={() => setFileInput(null)}>
                    <img src={x} alt="x" />
                  </button>
                  <InsertDriveFileOutlinedIcon fontSize="large" />
                  <p className="text-[11px] md:text-[13px] leading-tight w-full truncate">{fileInput?.name}</p>
                </div>
                <Button text="Submit" onClick={handleOnFileSubmit} color="bg-neonBlue mt-8" />
                {error && (
                  <div className="mt-2 text-[12px] text-red font-bold leading-tight">
                    Sorry! That didn't work. You can try again later.
                  </div>
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GoodreadsImport;
