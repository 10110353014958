import React, { useEffect, useState, useRef } from "react";
import Loader from "../helpers/Loader";
import api from "../../utils/api";
import ClubCard from "../cards/ClubCard";
import InternalSearchbar from "../helpers/InternalSearchbar";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HeadTags from "../helpers/HeadTags";

const AllClubs = () => {
  const [clubs, setClubs] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [viewAll, setViewAll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const page = useRef(1);

  useEffect(() => {
    setLoading(true);
    api.get(`/clubs`)
      .then((res) => {
        setClubs(res);
        setHasMore(res.length >= 20);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  const loadMore = () => {
    page.current += 1;
    setLoadingMore(true);
    api.get(`/clubs?page=${page.current}`)
      .then((res) => {
        setClubs((prevClubs) => [...prevClubs, ...res]);
        setHasMore(res.length >= 20);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoadingMore(false));
  };

  const handleSearchResults = (results, query) => {
    setViewAll(false);
    setSearchResults(results);
    setSearchQuery(query);
  };

  const onReset = () => {
    setViewAll(true);
  };

  if (loading) return <Loader variant="fullPage" />;

  return (
    <div className="container max-w-[900px] m-auto text-left px-2 py-8 md:py-16 flex flex-col">
      <HeadTags title="Browse Clubs" description="Discuss books with likeminded readers and find your community." />
      <div className="flex flex-col gap-6 self-center">
        <h2 className="self-center">All Clubs</h2>
        <InternalSearchbar type="Club" resultHandler={handleSearchResults} onReset={onReset} />
      </div>

      <div className="mt-4 md:mt-8 flex flex-col gap-3 md:gap-6">
        {viewAll ? (
          <React.Fragment>
            {clubs.map((club) => (
              <ClubCard key={club.id} club={club} />
            ))}
            {hasMore && (
              <button className="bg-transparent p-2 self-center text-sm" disabled={loadingMore} onClick={loadMore}>
                {loadingMore ? (
                  "Loading..."
                ) : (
                  <>
                    View More <ExpandMoreOutlinedIcon fontSize="small" />
                  </>
                )}
              </button>
            )}
          </React.Fragment>
        ) : searchResults.length > 0 ? (
          searchResults.map((club) => <ClubCard key={club.id} club={club} />)
        ) : (
          <p className="text-center">We couldn't find any clubs matching "{searchQuery}" ☹️</p>
        )}
      </div>
    </div>
  );
};

export default AllClubs;
