import React from "react";
import { useNavigate } from "react-router-dom";

const PointCard = ({ pointAward }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex w-full p-4 md:p-6 gap-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between items-center ${
        pointAward.link && "cursor-pointer"
      }`}
      onClick={() => pointAward.link && navigate(pointAward.link)}
    >
      <div className="flex flex-col gap-1">
        <p className="text-xs text-gray">{pointAward.date}</p>
        <p className="font-bold leading-tight">{pointAward.description}</p>
      </div>
      {pointAward.points > 0 ? (
        <div className="flex flex-none w-[40px] h-[40px] leading-tight bg-yellow rounded-full border justify-center items-center text-[13px] font-bold">
          +{pointAward.points}
        </div>
      ) : (
        <div className="flex flex-none w-[40px] h-[40px] leading-tight bg-offWhite rounded-full border justify-center items-center text-[13px] font-bold">
          0
        </div>
      )}
    </div>
  );
};

export default PointCard;
