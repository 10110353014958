import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateShelf, deleteShelf } from "../../../app/slices/shelfSlice";
import { openModal } from "../../../app/slices/globalUISlice";
import Button from "../../helpers/Button";
import Switch from "../../helpers/Switch";

const EditTagForm = ({ shelf, afterShelfEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shelfName, setShelfName] = useState(shelf.name);
  const [shelfDescription, setShelfDescription] = useState(shelf.description || "");
  const [isPrivate, setIsPrivate] = useState(shelf.private);

  const handleSave = () => {
    const cleanedShelfName = shelfName?.trim();
    const cleanedShelfDescription = shelfDescription?.trim();

    const shelfData = {
      name: cleanedShelfName,
      description: cleanedShelfDescription,
      private: isPrivate,
    };

    dispatch(updateShelf({ shelfId: shelf.id, shelfData }));
    afterShelfEdit(shelfData);
  };

  const handleDelete = async () => {
    dispatch(openModal({
      name: "confirmation",
      data: {
        title: "Are you sure you want to delete this tag?",
        confirmText: "Yes, delete",
        onConfirm: () => dispatch(deleteShelf(shelf.id))
          .unwrap()
          .then(() => navigate("/library"))
          .catch(() => console.error("Failed to delete shelf"))
      },
    }));
  };

  return (
    <div className="flex flex-col gap-4 justify-between w-full mb-6">
      <div className="flex w-full justify-end gap-5">
        <Button
          text="Save"
          color="bg-neonBlue"
          onClick={handleSave}
          disabled={!shelfName || shelfName?.length < 2}
        />
        <Button text="Delete Tag" color="bg-orange" onClick={handleDelete} />
      </div>
      <div className="flex flex-col gap-2">
        <label className="mr-4 font-bold" htmlFor="shelfName">
          Tag Name
        </label>
        <input
          type="text"
          className="w-full placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
          placeholder={shelf.name}
          value={shelfName}
          onChange={(e) => setShelfName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <label className="mr-4 font-bold" htmlFor="shelfDescription">
          Description (optional)
        </label>
        <input
          type="text"
          className="w-full placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
          placeholder={shelf.description || "Add a description for this tag"}
          value={shelfDescription}
          onChange={(e) => setShelfDescription(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-3">
        <label className="mr-4 font-bold" htmlFor="isPrivate">
          Do you want to make this tag private?
        </label>
        <div className="flex gap-1">
          <Switch id="isPrivate" isChecked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
        </div>
      </div>
    </div>
  );
};

export default EditTagForm;
