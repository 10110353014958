import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unscheduleUserBook, scheduleUserBook } from "../../../app/slices/userBookSlice";
import { MONTHS } from "../../../constants";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Button from "../../helpers/Button";
import LibraryBookCard from "../library/LibraryBookCard";
import LibraryAddBookCard from "../library/LibraryAddBookCard";
import { openModal } from "../../../app/slices/globalUISlice";
import IconButton from "../../helpers/IconButton";

const MonthSchedule = ({ month, year }) => {
  const dispatch = useDispatch();
  const { scheduledBooks } = useSelector((state) => state.userBook);
  const [monthBooks, setMonthBooks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    let plannedBooks = scheduledBooks.filter((book) => {
      return MONTHS[book.scheduled_for_month] == month && year == book.scheduled_for_year;
    });

    setMonthBooks(plannedBooks);
  }, [scheduledBooks, month, year]);

  const scheduleBook = (book) => {
    const monthNumber = MONTHS.indexOf(month);
    dispatch(
      scheduleUserBook({
        userBookData: {
          book_id: book.id,
          scheduled_for_year: year,
          scheduled_for_month: monthNumber,
        },
      })
    );
  };

  const removeBookButton = (userBook) => {
    const removeBook = (userBook) => {
      dispatch(
        unscheduleUserBook({
          userBook: userBook,
          userBookData: { scheduled_for_month: null },
        })
      );
    };

    return (
      <button onClick={() => removeBook(userBook)}>
        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
      </button>
    );
  };

  const onClickAddBookCard = () => {
    const addedBookIds = monthBooks.map((userBook) => userBook.book_id);
    dispatch(openModal({ name: "searchReturnBook", data: { addedBookIds, handleClick: scheduleBook } }));
  };

  return (
    <div key={month} className="flex flex-col flex-wrap w-full gap-4 box-border items-start">
      <div className="flex items-center w-full gap-6">
        <h2 className="font-bold">{`${month} ${year}`}</h2>
        {!isEditing && monthBooks.length > 0 && (
          <IconButton
            IconComponent={EditIcon}
            onClick={() => setIsEditing(true)}
            tooltip="Edit"
          />
        )}
        {isEditing && <Button text="Save" color="bg-fuschia" onClick={() => setIsEditing(false)} />}
      </div>

      <div className="flex flex-wrap w-full items-center gap-5" key={month}>
        {monthBooks.map((userBook) => (
          <LibraryBookCard
            userBook={userBook}
            key={userBook.uuid}
            icon={isEditing ? removeBookButton(userBook) : <></>}
          />
        ))}
        <LibraryAddBookCard onClick={onClickAddBookCard} key={`add-book-${month}`} />
      </div>
    </div>
  );
};

export default MonthSchedule;
