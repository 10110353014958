import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { openSnackbar, openModal } from "../../../app/slices/globalUISlice";
import Button from "../../helpers/Button";
import EditIcon from "@mui/icons-material/Edit";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import EditProfile from "./EditProfile";
import shareIcon from "../../../assets/shareIcon.svg";
import IconButton from "../../helpers/IconButton";

const ProfileHeader = ({ user, isCurrentUser, handleFollow, handleFollowersScroll }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const { currentUser } = useSelector((state) => state.user);

  const handleShare = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/users/${user.username}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const handleFollowClick = () => {
    if (!currentUser) {
      dispatch(openModal("loginPrompt"));
      return;
    }

    handleFollow();
  };

  return (
    <div className="flex gap-8 mx-4 md:w-2/3 md:mx-auto">
      {isCurrentUser && isEditing ? (
        <EditProfile setIsEditing={setIsEditing} />
      ) : (
        <div className="w-full flex gap-8 m-auto">
          <Link to={isCurrentUser ? `/profile` : `/users/${user.username}`}>
            <div className="relative w-[80px] h-[80px] md:w-[100px] md:h-[100px]">
              <div
                className={`w-full h-full rounded-full absolute border border-offBlack ${
                  user.open_to_friends && "bg-mainGreen shadow-main left-1 top-1 "
                }`}
              />
              {user.image_url && (
                <img src={user.image_url} className="w-full h-full rounded-full border border-offBlack absolute" />
              )}
            </div>
          </Link>
          <div className="flex flex-col items-start gap-3 w-full">
            <div className={`flex gap-4 ${isCurrentUser && "justify-between"} w-full`}>
              <h3>{user.username}</h3>
              {isCurrentUser ? (
                <div className="flex gap-3">
                  <IconButton
                    IconComponent={EditIcon}
                    onClick={() => setIsEditing(true)}
                    tooltip="Edit profile"
                  />
                  <IconButton
                    IconComponent={DriveFolderUploadOutlinedIcon}
                    onClick={() => navigate("/goodreads-import")}
                    tooltip="Import Goodreads"
                  />
                  <IconButton onClick={handleShare} tooltip="Share profile">
                    <img src={shareIcon} alt="share" className="w-[15px] h-[15px]" />
                  </IconButton>
                </div>
              ) : (
                <>
                  {user.id !== currentUser?.id && (
                    <Button
                      text={user.is_following ? "Unfollow" : "Follow"}
                      color={user.is_following ? "bg-mainGreen" : "bg-neonBlue"}
                      onClick={handleFollowClick}
                    />
                  )}
                </>
              )}
            </div>
            <div className="flex flex-row gap-8 text-sm">
              <div>
                {user.num_posts} {user.num_posts === 1 ? "post" : "posts"}
              </div>
              {isCurrentUser && (
                <div onClick={handleFollowersScroll} className="cursor-pointer">
                  {user.num_followers} {user.num_followers === 1 ? "follower" : "followers"}
                </div>
              )}
              <div>
                {user.points} {user.points === 1 ? "point" : "points"}
              </div>
            </div>
            <p className="text-left">{user.bio}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileHeader;
