import React from "react";
import PopularClubs from "../clubs/PopularClubs";
import NewClubs from "../clubs/NewClubs";
import Button from "../helpers/Button";

const DiscoverClubs = () => {
  return (
    <div className="container flex flex-col max-w-[900px] mx-auto m-auto gap-8 md:gap-10 items-start pb-[100px]">
      <div className="w-full text-left flex flex-col gap-5 md:gap-6">
        <div className="flex gap-8 items-center">
          <h2>Popular Clubs</h2>
          <Button type="link" to="/clubs/browse" text="Browse all" color="bg-fuschia" />
        </div>
        <PopularClubs />
      </div>

      <div className="w-full text-left flex flex-col gap-5 md:gap-6">
        <div className="flex flex-col gap-2">
          <h2>New Clubs</h2>
          <p>Newly created Clubs have 7 days to gain at least 5 members.</p>
        </div>
        <NewClubs />
      </div>
    </div>
  );
};

export default DiscoverClubs;
