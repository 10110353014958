import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FeedCard from "../cards/FeedCard";
import Button from "../helpers/Button";
import Loader from "../helpers/Loader";
import api from "../../utils/api";

const ActivityItemPage = () => {
  const { id } = useParams();
  const [activityItem, setActivityItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/feed/activity_item/${id}`)
      .then((data) => {
        setActivityItem(data.activity_item);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <Loader variant="fullPage" />;
  }

  if (!loading && (!activityItem || error)) {
    return (
      <div className="min-h-[100vh] h-max m-auto pt-8 flex flex-col gap-4 items-center justify-center pb-[50px] md:pb-[100px]">
        <h2>Hmm, we couldn't find that...</h2>
        <Button type="link" to="/" text="⬅ Back to home" color="bg-fuschia" />
      </div>
    );
  }

  return (
    <div className="container max-w-[856px] m-auto text-left px-2 py-8 md:py-16">
      <div className="hidden md:block">
        <Button type="link" to="/" text={`⬅ Back to home`} color="bg-neonBlue" />
      </div>
      <div className="md:hidden">
        <Button type="link" to="/" text={`⬅ Back to home`} color="bg-neonBlue" />
      </div>
      <div className="mt-4 md:mt-8">
        <FeedCard activityItem={activityItem} />
      </div>
    </div>
  );
};

export default ActivityItemPage;
