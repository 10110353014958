import clsx from "clsx";

const Tag = ({ text, color, IconComponent }) => {
  return (
    <div
      className={clsx(
        "flex gap-1 justify-center items-center border border-offBlack text-[10px] md:text-xs px-2 md:px-4 py-1 h-fit rounded-default",
        color
      )}
    >
      {text}
      {IconComponent && <IconComponent fontSize="small" />}
    </div>
  );
};

export default Tag;
