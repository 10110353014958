import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../../helpers/Menu";
import { deletePost, pinPost, unpinPost } from "../../../app/slices/forumSlice";
import { openModal, openSnackbar } from "../../../app/slices/globalUISlice";
import api from "../../../utils/api";

const PostActions = ({ post, setPost, onEdit }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  if (!currentUser) return null;
  const isCurrentUsersPost = currentUser?.id === post.user_id;

  const handleShare = () => {
    navigator.clipboard.writeText(`${window.location.origin}/posts/${post.id}`);
    dispatch(openSnackbar({ message: "Link copied to clipboard" }));
  };

  const handleFlag = () => {
    const body = (
      <>
        Flag this post if it violates Pagebound's{" "}
        <Link to="/" target="_blank">
          Terms of Use
        </Link>
        . We will remove the post until we can do a manual review. Since we are a small company, this review may take up
        to 24 hours.
        <br />
        <br />
        Thanks for your help moderating the Pagebound community!
      </>
    );
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to flag this post?",
          body,
          confirmText: "Yes, flag",
          onConfirm: () => {
            api
              .post(`/flags`, { resource_type: "Post", resource_id: post.id })
              .then(() => setPost((prevPost) => ({ ...prevPost, is_flagged: true })));
          },
        },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to delete this post?",
          confirmText: "Yes, delete",
          onConfirm: () => {
            dispatch(deletePost(post.id))
              .unwrap()
              .then((data) => setPost(data));
          },
        },
      })
    );
  };

  const handlePin = () => {
    api
      .post(`/posts/${post.id}/pin`)
      .then((newPost) => {
        dispatch(pinPost(newPost));
        setPost((prevPost) => ({ ...prevPost, is_pinned: true }));
      })
      .catch((error) => dispatch(openSnackbar({ message: "Can only have 3 pinned posts at a time." })));
  };

  const handleUnpin = () => {
    api
      .put(`/posts/${post.id}`, { post: { is_pinned: false } })
      .then((newPost) => {
        dispatch(unpinPost(newPost));
        setPost((prevPost) => ({ ...prevPost, is_pinned: false }));
      })
      .catch((error) => console.error(error));
  };

  const menuItems = [
    currentUser && post.can_pin && !post.is_pinned && { text: "pin", onClick: handlePin },
    currentUser && post.can_pin && post.is_pinned && { text: "unpin", onClick: handleUnpin },
    isCurrentUsersPost && !post.is_flagged && !post.deleted_at && { text: "edit", onClick: onEdit },
    { text: "share", onClick: handleShare },
    currentUser && !isCurrentUsersPost && !post.is_flagged && !post.deleted_at && { text: "flag", onClick: handleFlag },
    isCurrentUsersPost && !post.deleted_at && { text: "delete", onClick: handleDelete },
  ];

  return <Menu items={menuItems} />;
};

export default PostActions;
