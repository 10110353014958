import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clsx } from "clsx";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import sparkle from "../../../assets/sparkle.svg";
import BookCard from "../../cards/BookCard";
import ListCard from "../../cards/ListCard";
import QuestCard from "../../quests/QuestCard";
import ClubCard from "../../cards/ClubCard";
import Layout from "../../layout/Layout";
import api from "../../../utils/api";
import { capitalize } from "../../../utils/utils";
import HeadTags from "../../helpers/HeadTags";

const Bookmarks = () => {
  const navigate = useNavigate();
  const currentType = useParams().type?.toLowerCase() || "books";
  const currentUser = useSelector((state) => state.user.currentUser);
  const [showCategories, setShowCategories] = useState(true);

  const renderCategories = () => {
    const types = ["books", "lists", "quests", "clubs"];

    const handleClick = (type) => {
      setShowCategories(false);
      navigate(`/library/bookmarks/${type}`);
    };

    return (
      <ul className="w-[250px] flex flex-col flex-none gap-1.5 justify-start text-left">
        {types.map((type) => (
          <li
            key={type}
            onClick={() => handleClick(type)}
            className={clsx(
              "flex gap-1.5 font-space font-bold hover:text-pink cursor-pointer truncate",
              currentType == type ? "text-offBlack" : "text-gray"
            )}
          >
            <img src={sparkle} alt="sparkle" className={currentType == type ? "visible" : "invisible"} />
            {capitalize(type)}
          </li>
        ))}
      </ul>
    );
  };

  const BookList = () => {
    const [books, setBooks] = useState([]);
    const [moreData, setMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const pageRef = useRef(1);

    const handleMoreData = async () => {
      setLoading(true);
      pageRef.current += 1;
      api
        .get(`/bookmarks?type=Book&page=${pageRef.current}`)
        .then((data) => {
          setBooks((prevBooks) => [...prevBooks, ...data]);
          if (data.length < 20) setMoreData(false);
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      if (books.length === 0) {
        api.get(`/bookmarks?type=Book&page=1`).then((data) => {
          setBooks(data);
          if (data.length < 20) setMoreData(false);
        });
      }
    }, []);

    return (
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        {books?.length === 0 ? (
          <div className="w-full mt-4 flex flex-col gap-3 bg-white rounded-xl p-6 items-center">
            <b>Nothing here yet!</b>
            <p>To Bookmark a book, click the yellow bookmark icon on the top right of any book card.</p>
          </div>
        ) : (
          <>
            <div className="w-full flex flex-col md:grid md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 mt-4 items-center justify-center">
              {books?.map((book) => (
                <BookCard book={book} key={book.id} />
              ))}
            </div>
            {moreData && (
              <button className="bg-transparent p-2" disabled={loading} onClick={handleMoreData}>
                {loading ? (
                  "Loading..."
                ) : (
                  <>
                    View More <ExpandMoreOutlinedIcon fontSize="small" />
                  </>
                )}
              </button>
            )}
          </>
        )}
      </div>
    );
  };

  const ListList = () => {
    const [lists, setLists] = useState([]);
    const [moreData, setMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const pageRef = useRef(1);

    const handleMoreData = async () => {
      setLoading(true);
      pageRef.current += 1;
      api
        .get(`/bookmarks?type=List&page=${pageRef.current}`)
        .then((data) => {
          setLists((prevLists) => [...prevLists, ...data]);
          if (data.length < 20) setMoreData(false);
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      if (lists.length === 0) {
        api.get(`/bookmarks?type=List&page=1`).then((data) => {
          setLists(data);
          if (data.length < 20) setMoreData(false);
        });
      }
    }, []);

    return (
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="w-full flex flex-col gap-4 mt-4 items-center justify-center">
          {lists?.length === 0 && (
            <div className="w-full flex flex-col gap-3 bg-white rounded-xl p-6 items-center">
              <b>Nothing here yet!</b>
              <p>To Bookmark a List, click the yellow bookmark icon on the top right of the List.</p>
            </div>
          )}
          {lists?.map((list) => (
            <ListCard listData={list} key={list.id} />
          ))}
        </div>
        {lists?.length > 0 && moreData && (
          <button className="bg-transparent p-2" disabled={loading} onClick={handleMoreData}>
            {loading ? (
              "Loading..."
            ) : (
              <>
                View More <ExpandMoreOutlinedIcon fontSize="small" />
              </>
            )}
          </button>
        )}
      </div>
    );
  };

  const QuestList = () => {
    const [quests, setQuests] = useState([]);
    const [moreData, setMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const pageRef = useRef(1);

    const handleMoreData = async () => {
      setLoading(true);
      pageRef.current += 1;
      api
        .get(`/bookmarks?type=Challenge&page=${pageRef.current}`)
        .then((data) => {
          setQuests((prevQuests) => [...prevQuests, ...data]);
          if (data.length < 10) setMoreData(false);
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      if (quests.length === 0) {
        api.get(`/bookmarks?type=Challenge&page=1`).then((res) => {
          setQuests(res);
          if (res.length < 10) setMoreData(false);
        });
      }
    }, []);

    return (
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="w-full flex flex-col gap-4 mt-4 items-center justify-center">
          {quests?.length === 0 && (
            <div className="w-full flex flex-col gap-3 bg-white rounded-xl p-6 items-center">
              <b>Nothing here yet!</b>
              <p>To Bookmark a Quest, click the yellow bookmark icon on the top right of the Quest card.</p>
            </div>
          )}
          {quests?.map((quest) => (
            <QuestCard quest={quest} key={quest.id} />
          ))}
        </div>
        {quests?.length > 0 && moreData && (
          <button className="bg-transparent p-2" disabled={loading} onClick={handleMoreData}>
            {loading ? (
              "Loading..."
            ) : (
              <>
                View More <ExpandMoreOutlinedIcon fontSize="small" />
              </>
            )}
          </button>
        )}
      </div>
    );
  };

  const ClubList = () => {
    const [clubs, setClubs] = useState([]);
    const [moreData, setMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const pageRef = useRef(1);

    const handleMoreData = async () => {
      setLoading(true);
      pageRef.current += 1;
      api
        .get(`/bookmarks?type=Club&page=${pageRef.current}`)
        .then((data) => {
          setClubs((prevClubs) => [...prevClubs, ...data]);
          if (data.length < 10) setMoreData(false);
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      if (clubs.length === 0) {
        api.get(`/bookmarks?type=Club&page=1`).then((res) => {
          setClubs(res);
          if (res.length < 10) setMoreData(false);
        });
      }
    }, []);

    return (
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="w-full flex flex-col gap-4 mt-4 items-center justify-center">
          {clubs?.length === 0 && (
            <div className="w-full flex flex-col gap-3 bg-white rounded-xl p-6 items-center">
              <b>Nothing here yet!</b>
              <p>To Bookmark a Club, click the yellow bookmark icon on the top right of the Club card.</p>
            </div>
          )}
          {clubs?.map((club) => (
            <ClubCard club={club} key={club.id} />
          ))}
        </div>
        {clubs?.length > 0 && moreData && (
          <button className="bg-transparent p-2" disabled={loading} onClick={handleMoreData}>
            {loading ? (
              "Loading..."
            ) : (
              <>
                View More <ExpandMoreOutlinedIcon fontSize="small" />
              </>
            )}
          </button>
        )}
      </div>
    );
  };

  const ctas = [
    { text: "Books", onClick: () => navigate("/library") },
    { text: "Plan", onClick: () => navigate("/library/plan") },
    { text: "Bookmarks", isActive: true, onClick: () => setShowCategories(true) },
  ];

  return (
    <Layout
      title="Library"
      ctas={ctas}
      description={`When you're not quite ready to commit, but you don't want to lose your place...\nfind your saved items here.`}
      showUnauth={!currentUser}
    >
      <HeadTags title="Bookmarks" description="When you're not quite ready to commit, but you don't want to lose your place... find your saved items here." />
      <div className="container max-w-[1280px] mx-auto flex lg:gap-12 justify-center box-border px-3 mb-[100px]">
        {/* Desktop */}
        <div className="hidden lg:inline-block w-fit flex-none">{renderCategories()}</div>
        <div className="hidden lg:inline-block flex flex-col lg:w-[670px] xl:w-[870px] gap-2 bg-gradient-to-b to-pastelOrange from-pastelGreen box-border p-6 md:p-8 rounded-default w-full items-start text-left">
          <h2>{capitalize(currentType)}</h2>
          {currentType === "books" && <BookList />}
          {currentType === "lists" && <ListList />}
          {currentType === "clubs" && <ClubList />}
          {currentType === "quests" && <QuestList />}
        </div>

        {/* Mobile */}
        <div className="lg:hidden w-full flex justify-center">
          {showCategories && (
            <div className="min-w-[300px] py-4 rounded-default border border-offBlack shadow-main bg-lightPurple flex m-auto justify-center items-center">
              {renderCategories()}
            </div>
          )}
          {!showCategories && (
            <div className="flex flex-col lg:w-[670px] xl:w-[870px] gap-2 bg-gradient-to-b to-pastelOrange from-pastelGreen box-border p-6 md:p-8 rounded-default w-full items-start text-left">
              <button className="flex items-center gap-1" onClick={() => setShowCategories(true)}>
                <h2>{capitalize(currentType)}</h2>
                <ExpandMoreOutlinedIcon fontSize="small" />
              </button>
              {currentType === "books" && <BookList />}
              {currentType === "lists" && <ListList />}
              {currentType === "clubs" && <ClubList />}
              {currentType === "quests" && <QuestList />}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Bookmarks;
