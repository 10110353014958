import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import BookCard from "../cards/BookCard";
import Loader from "../helpers/Loader";
import HeadTags from "../helpers/HeadTags";

const AuthorPage = () => {
  const { uuid } = useParams();
  const [author, setAuthor] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    api.get(`/authors/${uuid}`).then((data) => setAuthor(data));
  }, [uuid]);

  useEffect(() => {
    const checkOverflow = () => {
      const el = textRef.current;
      if (el) setIsOverflowing(el.scrollHeight > el.clientHeight);
    };

    checkOverflow();
  }, [author?.description]);

  if (!author) {
    return <Loader variant="fullPage" />;
  }

  return (
    <div className="container m-auto py-8 md:py-16 w-2/3 flex flex-col gap-4">
      <HeadTags title={author?.name} description={author?.description} />
      <h1>{author.name}</h1>
      <div className="text-left">
        <div ref={textRef} className={!expanded && "text-ellipsis line-clamp-3"}>
          {author.description}
        </div>
        {isOverflowing && (
          <button onClick={() => setExpanded(!expanded)} className="text-gray text-xs">
            {expanded ? "Read Less" : "Read More"}
          </button>
        )}
      </div>
      <div className="mt-8 flex flex-row gap-4 flex-wrap">
        {author.books.map((book) => (
          <BookCard key={book.id} book={book} />
        ))}
      </div>
    </div>
  );
};

export default AuthorPage;
