import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createShelf } from "../../../app/slices/shelfSlice";
import Button from "../../helpers/Button";
import Switch from "../../helpers/Switch";

const CreateTagForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shelfName, setShelfName] = useState("");
  const [shelfDescription, setShelfDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      setShelfName("");
      setShelfDescription("");
      setError(null);
    };
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    const cleanedShelfName = shelfName?.trim();
    const cleanedShelfDescription = shelfDescription?.trim();

    const shelfData = {
      name: cleanedShelfName,
      description: cleanedShelfDescription,
      private: isPrivate,
    };

    try {
      const resultAction = await dispatch(createShelf(shelfData));
      if (createShelf.fulfilled.match(resultAction)) {
        navigate(`/library/tags/${resultAction.payload.uuid}`);
      } else {
        throw new Error("Submit failed");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <form className="w-full min-w-[350px] lg:w-[652px] xl:w-[856px] flex flex-col flex-wrap gap-6 bg-seafoam box-border p-8 rounded-default h-fit items-start text-left border border-offBlack shadow-main">
      <h2>Add a Tag</h2>
      {error && <p>{error}</p>}
      <input
        type="text"
        className="w-full placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
        maxLength={50}
        placeholder="Tag name"
        value={shelfName}
        onChange={(e) => setShelfName(e.target.value)}
      />
      <textarea
        className="w-full resize-none placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
        type="text"
        maxLength={150}
        placeholder="Description (optional)"
        value={shelfDescription}
        onChange={(e) => setShelfDescription(e.target.value)}
      />
      <div className="flex flex-col gap-3">
        <label className="mr-4 font-bold" htmlFor="isPrivate">
          Do you want to make this tag private?
        </label>
        <div className="flex gap-1">
          <Switch id="isPrivate" isChecked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
        </div>
      </div>
      <div className="flex w-full justify-end">
        <Button text="Create" color="bg-neonBlue" onClick={handleSave} disabled={!shelfName || shelfName?.length < 2} />
      </div>
    </form>
  );
};

export default CreateTagForm;
