import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import { useDocumentTitle } from "../../utils/hooks";

const AllAuthors = () => {
  const [authors, setAuthors] = useState(null);
  useDocumentTitle("Authors");

  useEffect(() => {
    api.get(`/authors`).then((data) => setAuthors(data));
  }, []);

  if (!authors) {
    return <Loader variant="fullPage" />
  };

  return (
    <div className="container m-auto text-left my-8">
      <div className="flex flex-row gap-2 flex-wrap">
        {authors.map((author) => (
          <Link to={`/authors/${author.uuid}`}>{author.name}</Link>
        ))}
      </div>
    </div>
  );
};

export default AllAuthors;
