import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ClubMemberView from "./ClubMemberView";
import ClubModeratorView from "./ClubModeratorView";
import { fetchClub } from "../../../app/slices/clubSlice";
import Loader from "../../helpers/Loader";
import HeadTags from "../../helpers/HeadTags";

const ClubShow = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const { currentClub, loading } = useSelector((state) => state.club);

  useEffect(() => {
    if (currentClub?.uuid !== uuid) {
      dispatch(fetchClub(uuid));
    }
  }, [dispatch, uuid, currentClub]);

  if (loading) return <Loader variant="fullPage" />;
  if (!currentClub) return <div>Oops! No club found.</div>;

  return (
    <>
      <HeadTags title={currentClub?.name} description={currentClub?.description} />
      {currentClub.is_moderator ? <ClubModeratorView /> : <ClubMemberView />}
    </>
  );
};

export default ClubShow;
