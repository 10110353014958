import React from "react";
import { useNavigate } from "react-router-dom";
import OnboardingProgressBar from "./OnboardingProgressBar";
import PopularClubs from "../clubs/PopularClubs";

const OnboardingJoinClub = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-[100vh] h-max bg-gradient-to-b from-pastelOrange to-pastelPurple m-auto flex flex-col items-center">
      <header className="mt-[78px] md:mt-[120px] flex flex-col items-center">
        <OnboardingProgressBar step={2} />
        <h2>Join a Club</h2>
        <p className="md:w-[500px] mt-3 text-center">
          Like subreddits or book clubs, Clubs are the ideal way to find your reader community and get curated
          recommendations.
        </p>
      </header>

      <div className="container px-4 flex flex-col items-center">
        <div className="w-full max-w-[900px] flex flex-col items-center md:items-start gap-4 mt-8">
          <p className="font-bold text-left">
            We've added you to the Pagebound Club! You can join popular clubs here (or browse more later)
          </p>
          <PopularClubs isOnboarding />
        </div>
        <div className="w-full max-w-[900px] mt-[50px] pb-[70px] md:pb-[100px] flex justify-end relative">
          <div className="hidden md:flex absolute w-[40px] h-[40px] leading-tight -right-[10px] md:-right-[64px] -top-[40px] bg-yellow rounded-full border shadow-main justify-center items-center text-[13px] font-bold">
            +10
          </div>
          <button
            onClick={() => navigate("/onboarding/goodreads-import")}
            className="bg-neonBlue flex-none shadow-main w-fit h-fit text-sm md:px-6 md:py-2 whitespace-nowrap leading-none font-bold font-space px-4 py-1.5 rounded-full cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray disabled:cursor-auto"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingJoinClub;
