import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utils/api";
import SaveIcon from "../../assets/SaveIcon";
import BookCover from "../helpers/BookCover";

const CommunityCard = ({ type, item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const TEXT_COLOR_MAP = {
    club: "#61C7DC",
    list: "#F269BF",
    quest: "#F5B485",
  };

  const handleCardClick = () => {
    navigate(`/${type}s/${item.uuid}`);
  };

  const handleIconClick = (event) => {
    event.stopPropagation();

    if (!currentUser) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: type.charAt(0).toUpperCase() + type.slice(1), id: item.id };
      const action = isSaved ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setIsSaved(!isSaved);
      });
    }
  };

  return (
    <div
      onClick={handleCardClick}
      style={{ borderColor: TEXT_COLOR_MAP[type], boxShadow: `1.8px 1.8px 0 0 ${TEXT_COLOR_MAP[type]}` }}
      className={`relative w-fit h-fit flex flex-col bg-white border-2 rounded-default p-4 md:p-6 gap-3 cursor-pointer items-center`}
    >
      <button
        className="absolute top-2 right-2 md:top-[18px] md:right-[8px] p-1 rounded-full hover:bg-yellow"
        onClick={handleIconClick}
        disabled={isLoading}
      >
        <SaveIcon fillColor={isSaved ? "#F7DB90" : "transparent"} />
      </button>
      <p style={{ color: TEXT_COLOR_MAP[type] }} className={`font-bold`}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </p>

      {/* mobile style */}
      {/* <div className="flex md:hidden flex-col gap-3 w-full text-left">
        <p className="font-bold text-ellipsis line-clamp-1 max-w-[220px]">{item.name}</p>
      </div> */}

      <div className="grid grid-cols-2 gap-2 w-fit max-w-full min-h-[168px]">
        {item.book_images?.map((image, index) => (
          <BookCover key={index} book={{ image_url: image }} size="w-[54px] h-[80px]" clickable={false} />
        ))}
      </div>
      <div className="flex flex-col gap-2 flex-grow text-center max-w-full justify-between items-center">
        <div className="flex flex-col gap-1">
          <p className="font-bold text-sm leading-none md:leading-tight w-[120px] md:w-[150px] text-ellipsis line-clamp-1">
            {item.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
