import { useState } from "react";
import Button from "../helpers/Button";
import Switch from "../helpers/Switch";
import { useDispatch, useSelector } from "react-redux";
import { createPost } from "../../app/slices/forumSlice";
import { openModal } from "../../app/slices/globalUISlice";

const NewPostForm = ({ forum }) => {
  const [showForm, setShowForm] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const [isSpoiler, setIsSpoiler] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const { currentClub } = useSelector((state) => state.club);

  const handleCreatePost = (event) => {
    event.preventDefault();
    dispatch(
      createPost({
        forum_id: forum.id,
        title: postTitle,
        content: postContent,
        is_spoiler: isSpoiler,
      })
    )
      .unwrap()
      .then((data) => {
        if (data.forum?.length) {
          console.error("Error creating post");
        }
      })
      .finally(() => {
        setPostTitle("");
        setPostContent("");
        setShowForm(false);
        setIsSpoiler(false);
      });
  };

  const onClickCreate = () => {
    if (!currentUser) dispatch(openModal("loginPrompt"));
    else if (forum.resource_type === "Club" && currentClub.member_status !== "joined") {
      dispatch(
        openModal({
          name: "info",
          data: {
            title: "Only club members can post in the club forum!",
            body: "Join this club to participate in the discussion.",
          },
        })
      );
    } else {
      setShowForm(true);
    }
  };

  const handleToggle = () => {
    setIsSpoiler((prevState) => !prevState);
  };

  const handleChangeContent = (event) => {
    setPostContent(event.target.value);
  };

  const handleChangeTitle = (event) => {
    setPostTitle(event.target.value);
  };

  return (
    <>
      {!showForm ? (
        <button
          className="bg-white p-6 rounded-default border border-offBlack"
          onClick={onClickCreate}
        >
          <div className="font-hanken text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-[20px]">
            Create post
          </div>
        </button>
      ) : (
        <form className="p-6 border shadow-main bg-white rounded-default text-offBlack flex flex-col gap-3 font-hanken">
          <input
            onChange={handleChangeTitle}
            type="text"
            value={postTitle}
            placeholder="Post title"
            id="title"
            className="placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
          />
          <textarea
            placeholder="Body"
            value={postContent}
            onChange={handleChangeContent}
            className="h-[128px] resize-none placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
          />
          <div className="flex items-center">
            <label className="mr-4 font-bold" htmlFor="hasSpoilers">
              Does your post include spoilers?
            </label>
            <Switch id="hasSpoilers" isChecked={isSpoiler} onChange={handleToggle} />
          </div>

          <div className="text-right">
            <Button
              disabled={postTitle.length === 0 || postContent.length === 0}
              text="Post"
              color="bg-neonBlue"
              type="submit"
              onClick={handleCreatePost}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default NewPostForm;
