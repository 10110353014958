import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";

export const fetchShelves = createAsyncThunk("shelf/fetchShelves", () => api.get(`/shelves`));

export const createShelf = createAsyncThunk("shelf/createShelf", (shelfData) =>
  api.post(`/shelves`, { shelf: shelfData })
);

export const updateShelf = createAsyncThunk("shelf/updateShelf", ({ shelfId, shelfData }) =>
  api.put(`/shelves/${shelfId}`, { shelf: shelfData })
);

export const deleteShelf = createAsyncThunk("shelf/deleteShelf", (shelfId) =>
  api.destroy(`/shelves/${shelfId}`).then(() => shelfId)
);

export const addBookToShelf = createAsyncThunk("shelf/addBookToShelf", ({ shelfId, bookId }, { rejectWithValue }) =>
  api
    .post(`/shelves/${shelfId}/add_user_book`, { book_id: bookId })
    .then((data) => data)
    .catch((err) => rejectWithValue(err.response.data.error))
);

export const removeBookFromShelf = createAsyncThunk("shelf/removeBookFromShelf", ({ shelfId, userBookId }) =>
  api.post(`/shelves/${shelfId}/remove_user_book`, { user_book_id: userBookId })
);

// accepts an array of shelfIds for a userBook and updates the userBook's shelves
// backend figures out which shelves to add and remove
export const massUpdateShelfBook = createAsyncThunk("shelf/massUpdateShelfBook", ({ shelfIds, userBookId }) =>
  api.post(`/shelves/mass_update_shelf_book`, { user_book_id: userBookId, shelf_ids: shelfIds })
);

export const shelfSlice = createSlice({
  name: "shelf",
  initialState: {
    shelves: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShelves.fulfilled, (state, action) => {
      return { ...state, shelves: action.payload, loading: false };
    });
    builder.addCase(fetchShelves.pending, (state) => {
      return { ...state, loading: true, error: null };
    });
    builder.addCase(fetchShelves.rejected, (state, action) => {
      return { ...state, loading: false, error: action.payload.message };
    });
    builder.addCase(deleteShelf.pending, (state) => {
      return { ...state, loading: true, error: null };
    });
    builder.addCase(deleteShelf.fulfilled, (state, action) => {
      return {
        ...state,
        shelves: state.shelves.filter((shelf) => shelf.id !== action.payload),
        loading: false,
      };
    });
    builder.addCase(deleteShelf.rejected, (state, action) => {
      return { ...state, loading: false };
    });
    builder.addCase(createShelf.pending, (state) => {
      return { ...state, loading: true, error: null };
    });
    builder.addCase(createShelf.fulfilled, (state, action) => {
      return {
        ...state,
        shelves: [...state.shelves, action.payload],
        loading: false,
      };
    });
    builder.addCase(createShelf.rejected, (state, action) => {
      return { ...state, loading: false, error: action.payload.message };
    });
    builder.addCase(updateShelf.fulfilled, (state, action) => {
      return {
        ...state,
        shelves: state.shelves.map((shelf) => (shelf.id === action.payload.id ? action.payload : shelf)),
      };
    });
  },
});

export default shelfSlice.reducer;
