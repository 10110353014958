import React, { useState, useEffect, useRef } from "react";
import ListCard from "../cards/ListCard";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import Loader from "../helpers/Loader";
import Button from "../helpers/Button";
import Layout from "../layout/Layout";
import PopularLists from "./PopularLists";
import LoginPrompt from "../helpers/LoginPrompt";
import HeadTags from "../helpers/HeadTags";

const ListsHome = () => {
  const [createdLists, setCreatedLists] = useState([]);
  const [bookmarkedLists, setBookmarkedLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state) => state.user.currentUser);
  const createdPage = useRef(1);
  const bookmarkedPage = useRef(1);
  const timerRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    api
      .get("/lists/user_lists")
      .then((res) => {
        setCreatedLists(res);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get("/bookmarks?type=List")
      .then((res) => {
        setBookmarkedLists(res);
      })
      .catch((err) => {
        console.log(err);
      });

    timerRef.current = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const renderCreatedLists = () => {
    if (!loading && createdLists.length == 0) {
      return (
        <div className="w-full max-w-[900px] bg-pastelPink flex justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
          <div className="flex flex-col gap-3 text-left pr-5">
            <p className="font-bold">You haven't created any Lists yet!</p>
            <p>Are you ready to be a tastemaker?</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col gap-4">
          {createdLists.map((list) => (
            <ListCard key={list.id} listData={list} />
          ))}
        </div>
      );
    }
  };

  const renderBookmarkedLists = () => {
    if (bookmarkedLists.length == 0) {
      return (
        <div className="w-full max-w-[900px] bg-pastelPink flex flex-col md:flex-row justify-between items-center rounded-default p-6 border border-offBlack shadow-main">
          <div className="flex flex-col gap-3 text-left md:pr-5 pb-4 md:pb-0">
            <p className="font-bold">You haven't bookmarked any Lists yet!</p>
            <p>Browse around and bookmark any Lists you love. Once you do, you'll find them here.</p>
          </div>
          <Button text="Discover popular lists" type="link" to="/lists/browse" color="bg-dnf self-end" />
        </div>
      );
    } else {
      return (
        <div>
          {bookmarkedLists.map((list) => (
            <ListCard key={list.id} listData={list} />
          ))}
        </div>
      );
    }
  };

  if (loading) return <Loader variant="fullPage" />;

  return (
    <div className="relative h-max min-h-[100vh] mx-auto m-auto bg-cover bg-no-repeat bg-club-squiggle">
      <HeadTags title="Lists" description="Like a bookish Pinterest board, browse and create themed collections." />
      <Layout title="Lists" description="Like a bookish Pinterest board, browse and create themed collections.">
        <div className="relative flex flex-col gap-8 md:gap-16">
          <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <div className="flex gap-8 text-left w-full mb-6">
              <h2>Your Lists</h2>
              {!!currentUser && (
                <>
                  <div className="hidden md:flex flex-row gap-3">
                    <Button text="Create List" type="link" to="/lists/new" color="bg-neonBlue" />
                    <Button text="Bookmarked Lists" type="link" to="/library/bookmarks/lists" color="bg-fuschia" />
                  </div>
                  <div className="flex flex-row flex-none gap-3 md:hidden">
                    <Button text="Create" type="link" to="/lists/new" color="bg-neonBlue" />
                    <Button text="Bookmarked" type="link" to="/library/bookmarks/lists" color="bg-fuschia" />
                  </div>
                </>
              )}
            </div>
            {!!currentUser ? renderCreatedLists() : <LoginPrompt />}
          </div>

          {/* <div className="relative w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <div className="flex gap-8 text-left w-full mb-6">
              <h2>Bookmarked Lists</h2>
              <Button text="Browse All" type="link" to="/lists/browse" color="bg-fuschia md:hidden" size="small" />
              <Button text="Browse All" type="link" to="/lists/browse" color="bg-fuschia hidden md:flex" />
            </div>
            {renderBookmarkedLists()}
          </div> */}

          <div className="w-10/12 md:w-9/12 max-w-[900px] m-auto justify-center">
            <PopularLists />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ListsHome;
