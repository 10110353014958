import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../../utils/api";
import { openModal } from "../../app/slices/globalUISlice";
import Button from "../helpers/Button";
import EmojiPicker from "emoji-picker-react";
import Modal from "../modals/Modal";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const EditListForm = ({ list, afterListEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listName, setListName] = useState(list.name);
  const [listDescription, setListDescription] = useState(list.description || "");
  const [emojis, setEmojis] = useState(list.emojis || []);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSave = () => {
    const cleanedListName = listName?.trim();
    const cleanedListDescription = listDescription?.trim();

    const listData = {
      name: cleanedListName,
      description: cleanedListDescription,
      emojis: emojis,
    };

    api
      .put(`/lists/${list.uuid}`, listData)
      .then((res) => {
        afterListEdit(res);
      })
      .catch((err) => console.error(err));
  };

  const deleteList = () => {
    api.destroy(`/lists/${list.uuid}`);
    navigate("/lists");
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to delete your list?",
          confirmText: "Yes, delete",
          onConfirm: deleteList,
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-4 justify-between w-11/12 mb-6 text-left px-2 mt-[50px] md:mt-0">
      <div className="flex flex-col gap-2">
        <label className="mr-4 font-bold" htmlFor="listName">
          Name
        </label>
        <input
          type="text"
          className="w-full placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
          placeholder={list.name}
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <label className="mr-4 font-bold" htmlFor="listDescription">
          Description (optional)
        </label>
        <textarea
          type="text"
          rows="3"
          className="w-full placeholder:text-gray ml-0 text-left py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
          placeholder={list.description || "Add a description for this list"}
          value={listDescription}
          onChange={(e) => setListDescription(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-3 w-full items-start text-left">
        <p className="font-bold">Select 3 emojis to describe your List:</p>

        <div className="flex gap-4 items-start">
          {emojis?.length > 0 &&
            emojis.map((emoji, index) => (
              <div key={index} className="flex flex-col">
                <div className="text-[25px]">{emoji}</div>
                <button type="button" onClick={() => setEmojis(emojis.filter((_, i) => i !== index))}>
                  <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
                </button>
              </div>
            ))}
          {emojis?.length < 3 && (
            <button type="button" className="pt-2" onClick={() => setShowEmojiPicker(true)}>
              <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
            </button>
          )}

          <Modal isOpen={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}>
            <EmojiPicker
              skinTonesDisabled
              lazyLoadEmojis
              previewConfig={{ showPreview: false }}
              onEmojiClick={(emojiData, event) => {
                if (emojis.length < 3) setEmojis([...emojis, emojiData.emoji]);
                if (emojis.length == 2) setShowEmojiPicker(false);
              }}
            />
          </Modal>
        </div>
      </div>
      <div className="flex w-full justify-end gap-5 mt-2">
        <Button text="Save" color="bg-neonBlue" onClick={handleSave} disabled={!listName || listName?.length < 5} />
        <Button text="Delete List" color="bg-orange" onClick={handleDelete} />
      </div>
    </div>
  );
};

export default EditListForm;
