import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
import { Formik, Field, FieldArray, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "../modals/Modal";
import api from "../../utils/api";
import Button from "../helpers/Button";
import HeadTags from "../helpers/HeadTags";

const CreateList = () => {
  const navigate = useNavigate();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    api
      .post("/lists", values)
      .then((data) => {
        setSubmitting(false);
        navigate(`/lists/${data.uuid}`);
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus({
          error: error,
        });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const listSchema = Yup.object().shape({
    name: Yup.string().required("Required").max(50, "Too Long"),
    description: Yup.string().max(500, "Too Long"),
    emojis: Yup.array().required("Emojis are required").min(3, "Choose 3 emojis").max(3, "Maximum 3 emojis"),
  });

  return (
    <div className="container h-[100vh] mx-auto m-auto pt-8 md:pt-[4em]">
      <HeadTags title="Create List" description="Like a bookish Pinterest board, browse and create themed collections." />
      <header className="mb-8 md:mb-16 flex flex-col gap-4">
        <h1>Create a List</h1>
        <h3>
          Standout Lists are niche and original:
          <br />
          "Books not to read when home alone" 💯 vs. "Good Thrillers" 👎
        </h3>
      </header>
      <Formik
        validationSchema={listSchema}
        onSubmit={handleSubmit}
        initialValues={{
          name: "",
          description: "",
          emojis: [],
        }}
      >
        {({ errors, values, isSubmitting, isValid, status }) => (
          <Form
            onKeyDown={handleKeyDown}
            className="w-9/12 max-w-[900px] m-auto flex flex-col items-start p-8 gap-6 bg-seafoam border border-offBlack rounded-default shadow-main"
          >
            <div className="flex flex-col gap-3 w-full items-start">
              <div className="flex flex-col gap-1 w-full items-start text-left">
                <label htmlFor="name" className="font-bold">
                  Name:
                </label>
              </div>
              <Field
                id="name"
                name="name"
                placeholder="max 50 characters"
                className="w-full ml-0 mr-5 px-4 py-3 my-auto"
              />
              <ErrorMessage name="name" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="flex flex-col gap-3 w-full items-start">
              <label htmlFor="description" className="font-bold">
                Description:
              </label>
              <Field
                id="description"
                name="description"
                placeholder="max 500 characters"
                as="textarea"
                rows={3}
                className="w-full ml-0 mr-5 px-4 py-3 my-auto"
              />
              <ErrorMessage name="description" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="flex flex-col gap-3 w-full items-start text-left">
              <p className="font-bold">Select 3 emojis to describe your List:</p>
              <FieldArray
                name="emojis"
                render={(arrayHelpers) => (
                  <div className="flex gap-4 items-start">
                    {values.emojis?.length > 0 &&
                      values.emojis.map((emoji, index) => (
                        <div key={index} className="flex flex-col">
                          <div className="text-[25px]">{emoji}</div>
                          <button type="button" onClick={() => arrayHelpers.remove(index)}>
                            <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
                          </button>
                        </div>
                      ))}
                    {values.emojis?.length < 3 && (
                      <button type="button" className="pt-2" onClick={() => setShowEmojiPicker(true)}>
                        <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#333" }} />
                      </button>
                    )}

                    <Modal isOpen={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}>
                      <EmojiPicker
                        skinTonesDisabled
                        lazyLoadEmojis
                        previewConfig={{ showPreview: false }}
                        onEmojiClick={(emojiData, event) => {
                          if (values.emojis.length < 3) arrayHelpers.push(emojiData.emoji);
                          setShowEmojiPicker(false);
                        }}
                      />
                    </Modal>
                  </div>
                )}
              />
              <ErrorMessage name="emojis" render={(msg) => <div className="text-red">{msg}</div>} />
            </div>
            <div className="w-full flex justify-end items-center">
              {status && status.error && <div className="text-red mr-4">{status.error}</div>}
              <Button disabled={!isValid || isSubmitting} color="bg-neonBlue" text="Create" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateList;
