import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./home/Home";
import BookPage from "./book/BookPage";
import AllRecommendationsPage from "./book/AllRecommendationsPage";
import AuthorPage from "./author/AuthorPage";
import LibraryBooks from "./user/library/LibraryBooks";
import Bookmarks from "./user/bookmarks/Bookmarks";
import Plan from "./user/schedule/Plan";
import CurrentUserProfile from "./user/profile/CurrentUserProfile";
import OtherUserProfile from "./user/profile/OtherUserProfile";
import QuestsHome from "./quests/QuestsHome";
import Discover from "./discover/Discover";
import QuestPage from "./quests/QuestPage";
import ClubHome from "./clubs/ClubHome";
import ClubShow from "./clubs/show/ClubShow";
import CreateClub from "./clubs/CreateClub";
import SearchResults from "./results/SearchResults";
import YearChallengePage from "./yearChallenge/YearChallengePage";
import OnboardingProfile from "./onboarding/OnboardingProfile";
import OnboardingJoinClub from "./onboarding/OnboardingJoinClub";
import OnboardingGoodreadsImport from "./onboarding/OnboardingGoodreadsImport";
import OnboardingReferral from "./onboarding/OnboardingReferral";
import PostPage from "./forum/PostPage";
import NotFound from "./NotFound";
import NotificationsHome from "./notifications/NotificationsHome";
import GoodreadsImport from "./user/profile/GoodreadsImport";
import ActivityItemPage from "./home/ActivityItemPage";
import AllAuthors from "./author/AllAuthors";
import AllClubs from "./clubs/AllClubs";
import AllQuests from "./quests/AllQuests";
import ListsHome from "./lists/ListsHome";
import ListPage from "./lists/ListPage";
import CreateList from "./lists/CreateList";
import AllLists from "./lists/AllLists";

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (!currentUser && location.pathname.startsWith("/onboarding")) {
      navigate("/");
    }

    if (currentUser && !currentUser.onboarding_completed && !location.pathname.startsWith("/onboarding")) {
      navigate("/onboarding/profile");
    }
  }, [location, currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/notifications" element={<NotificationsHome />} />

      <Route path="/onboarding/profile" element={<OnboardingProfile />} />
      <Route path="/onboarding/join-clubs" element={<OnboardingJoinClub />} />
      <Route path="/onboarding/goodreads-import" element={<OnboardingGoodreadsImport />} />
      <Route path="/onboarding/referral" element={<OnboardingReferral />} />

      <Route path="/books/:uuid" element={<BookPage />} />
      <Route path="/books/:uuid/recommendations" element={<AllRecommendationsPage view="recommendations" />} />
      <Route path="/books/:uuid/clubs" element={<AllRecommendationsPage view="clubs" />} />
      <Route path="/books/:uuid/quests" element={<AllRecommendationsPage view="quests" />} />
      <Route path="/books/:uuid/lists" element={<AllRecommendationsPage view="lists" />} />
      <Route path="/authors" element={<AllAuthors />} />
      <Route path="/authors/:uuid" element={<AuthorPage />} />

      <Route path="/library" element={<Navigate to="/library/current" replace />} />
      <Route path="/library/:status" element={<LibraryBooks />} />
      <Route path="/library/tags/:uuid" element={<LibraryBooks />} />
      <Route path="/library/plan/:view?" element={<Plan />} />
      <Route path="/library/bookmarks/:type?" element={<Bookmarks />} />
      <Route path="/reading-challenge" element={<YearChallengePage />} />

      <Route path="/discover/:view?" element={<Discover />} />
      <Route path="/results" element={<SearchResults />} />

      <Route path="/profile" element={<CurrentUserProfile />} />
      <Route path="/goodreads-import" element={<GoodreadsImport />} />
      <Route path="/users/:username" element={<OtherUserProfile />} />
      <Route path="/users/:username/library/:status?" element={<OtherUserProfile showLibrary />} />
      <Route path="/users/:username/library/tags/:uuid" element={<OtherUserProfile showLibrary />} />
      <Route path="/users/:username/reading-challenge" element={<YearChallengePage />} />

      <Route path="/clubs" element={<ClubHome />} />
      <Route path="/clubs/browse" element={<AllClubs />} />
      <Route path="/clubs/new" element={<CreateClub />} />
      <Route path="/clubs/:uuid" element={<ClubShow />} />

      <Route path="/quests" element={<QuestsHome />} />
      <Route path="/quests/browse" element={<AllQuests />} />
      <Route path="/quests/:uuid" element={<QuestPage />} />

      <Route path="/posts/:id" element={<PostPage />} />
      <Route path="/activity/:id" element={<ActivityItemPage />} />

      <Route path="/lists" element={<ListsHome />} />
      <Route path="/lists/new" element={<CreateList />} />
      <Route path="/lists/browse" element={<AllLists />} />
      <Route path="/lists/:uuid" element={<ListPage />} />

      {/* Must be listed last! */}
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};

export default AppRoutes;
